.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

.words {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.controls {
  top: 0px;
  position: sticky;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: flex-start;
  flex-grow: 0;
  padding: 4px;
  flex-shrink: 0;
  min-width: 220px;
  margin-right: 10px;
}

.control {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.filterCheckbox {
  transform: scale(0.7);
  float: right;
}

