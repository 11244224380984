.dateRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.dateLabel {
  flex-basis: 40px;
  max-width: 40px;
  font-size: 14px;
}

.dateInput {
  flex-basis: 209px;
  max-width: 209px;
}
