.root {
  display: flex;
  flex-direction: column;
}

.statistics {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin-bottom: 16px;
}

.baseLink {
  color: var(--mine-shaft) !important;
}

.baseLink:hover {
  text-decoration: none;
}

.totalCount {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.title {
  font-size: 24px;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -5px;
}

.baseLink:hover .title {
  text-decoration: underline;
}

.alertsLink {
  color: var(--oslo-gray) !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  letter-spacing: 0;
  line-height: 17px !important;
  margin-top: 8px;
}

.alertsLink:hover {
  color: var(--oslo-gray);
}

.showMoreLink {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 24px;
}

.showMoreLink:hover {
  color: var(--primary-color);
}

.profiles {
  display: flex;
  margin-top: 16px;
  height: 50px;
}

.profile {
  width: 25px;
}

.avatarBox {
  border: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--silver);
}

.avatar {
  display: flex;
  border: 1px solid var(--dusty-gray);
  box-sizing: content-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .root {
    flex-direction: row;
    justify-content: space-around;
  }

  .statistics {
    margin-bottom: 0px;
  }
}
