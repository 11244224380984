.root {
  position: fixed;
  z-index: 9000;
  background-color: #4B4B4B;
  transform: translateX(100%) translateY(75%);
}

.search {
  background-color: #4B4B4B;
  width: 383px;
  height: 70px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
}

.button {
  font-size: 18px;
  width: 93px;
  height: 50px;
  background-color: #00A0CE;
  color: #fff;
  padding: 0;
  min-width: auto;
  border-radius: 5px;
  font-weight: bold;
  border: none;
}

.button[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.searchСontainer {
  width: 240px;
  margin-right: 16px;
  background-color: #fff;
}

.icon {
  color: #fff;
  width: 45px;
  height: 50px;
  min-width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: none;
  border: none;
}

.icon:focus {
  outline: none;
  box-shadow: none;
}

.content {
  position: relative;
}

.content::before {
  content: '';
  position: absolute;
  top: -6px;
  left: calc(50% - 4px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 6px 4px;
  border-color: transparent transparent #4B4B4B transparent;
}


.isSearch {
  transform: translateX(-15%) translateY(75%);
}

.isSearch .content::before {
  left: calc(15% - 4px);
}
