.editor {
  border: solid 1px var(--iron);
  overflow-y: scroll;
  padding: 20px;
  line-height: 20px;
  max-height: 60vh;
  min-height: 200px;
  max-width: 90vw;
  min-width: 50vw;
}

.toolbar {
  display: block;
}

:global(.rdw-block-dropdown) {
  width: 178px;
  margin-left: 0;
}

:global(.grommet .rdw-editor-main li:not([class^="grommetux-"])) {
  margin-left: 24px;
}

:global(.rdw-editor-toolbar) {
  border-bottom: 0;
  margin-bottom: 0;
}

:global(.grommet .rdw-link-modal-buttonsection button:not(.grommetux-button):not(.grommetux-button--box)) {
  padding: 0;
  min-width: 0;
}

:global(.grommet .DraftEditor-editorContainer a:not(.grommetux-anchor):not(.grommetux-button)) {
  color: var(--primary-color);
  border-bottom: 1px solid currentColor;
}

:global(.grommet .DraftEditor-editorContainer a:not(.grommetux-anchor):not(.grommetux-button):hover) {
  border-bottom: 1px solid transparent;
  text-decoration: none;
}

:global(.grommet .DraftEditor-editorContainer .rdw-link-decorator-icon) {
  display: none;
}

:global(.rdw-option-wrapper) {
  border: none;
  padding: 0;
  background-color: transparent;
  margin: 0 3px;
}

:global(.rdw-editor-toolbar) {
  background-color: var(--wild-sand);
  padding: 0;
}

:global(.grommet .rdw-editor-toolbar a.rdw-dropdown-selectedtext) {
  color: var(--black);
  box-shadow: none;
}

:global(.rdw-block-wrapper) {
  margin-bottom: 0;
}

:global(.rdw-inline-wrapper) {
  margin-bottom: 0;
  border-right: 1px solid var(--alto);
  padding-right: 15px;
}

:global(.rdw-text-align-wrapper) {
  margin-bottom: 0;
  border-right: 1px solid var(--alto);
  padding: 0 15px;
}

:global(.rdw-list-wrapper) {
  margin-bottom: 0;
  padding-left: 15px;
}

:global(.rdw-link-wrapper) {
  margin-bottom: 0;
}

.editor :global(.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1) {
  list-style-type: none;
  margin-left: 40px;
}

.editor :global(.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1:before) {
  content: '-';
  position: absolute;
}

.editor :global(.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 .public-DraftStyleDefault-block) {
  margin-left: 18px;
}

.editor :global(.public-DraftStyleDefault-block) {
  margin: 0;
}

.editor :global(div[data-block="true"]) {
  margin-bottom: 7px;
}

:global(.rdw-link-wrapper) {
  height: 32px;
}

:global(.grommet .rdw-editor-main li:not([class^="grommetux-"]).public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth2) {
  margin-left: 64px;
}

:global(.grommet .rdw-editor-main li:not([class^="grommetux-"]).public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth3) {
  margin-left: 83px;
}

:global(.grommet .rdw-editor-main li:not([class^="grommetux-"]).public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth4) {
  margin-left: 102px;
}

:global(.grommet) .editor :global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1) {
  margin-left: 30px;
}

:global(.grommet) .editor :global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2) {
  margin-left: 45px;
}

:global(.grommet) .editor :global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3) {
  margin-left: 60px;
}

:global(.grommet) .editor :global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4) {
  margin-left: 75px;
}

.editor :global(.rdw-center-aligned-block .public-DraftStyleDefault-ltr) {
  text-align: center;
}

.editor :global(.rdw-right-aligned-block .public-DraftStyleDefault-ltr) {
  text-align: right;
}

.editor :global(.rdw-justify-aligned-block .public-DraftStyleDefault-ltr) {
  text-align: justify;
}

:global(.public-DraftStyleDefault-orderedListItem .public-DraftStyleDefault-block) {
  margin: 0;
}

:global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-reset) {
  margin-top: 18px;
}

.editor:global(.rdw-editor-main) :global(li:not([class^="grommetux-"])) {
  margin-left: 0;
}

.editor :global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before) {
  left: 0;
  width: auto;
  position: relative;
  float: left;
}

:global(.grommet) .editor :global(h1:not(.grommetux-heading) .public-DraftStyleDefault-block),
:global(.grommet) .editor :global(h2:not(.grommetux-heading) .public-DraftStyleDefault-block),
:global(.grommet) .editor :global(h3:not(.grommetux-heading) .public-DraftStyleDefault-block),
:global(.grommet) .editor :global(h4:not(.grommetux-heading) .public-DraftStyleDefault-block),
:global(.grommet) .editor :global(h5:not(.grommetux-heading) .public-DraftStyleDefault-block),
:global(.grommet) .editor :global(h6:not(.grommetux-heading) .public-DraftStyleDefault-block),
:global(.grommet) .editor :global(h7:not(.grommetux-heading) .public-DraftStyleDefault-block) {
  margin: 0;
}


.editor :global(.public-DraftStyleDefault-ul) {
  margin-top: 23px;
  margin-bottom: 27px;
}

.editor:global(.rdw-editor-main .public-DraftStyleDefault-ul li:not([class^="grommetux-"])) {
  margin-left: 30px;
}

.editor :global(.public-DraftStyleDefault-unorderedListItem .public-DraftStyleDefault-block) {
  margin: 0;
}


.editor :global(.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-reset.public-DraftStyleDefault-depth1),
.editor :global(.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-reset.public-DraftStyleDefault-depth2),
.editor :global(.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-reset.public-DraftStyleDefault-depth3),
.editor :global(.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-reset.public-DraftStyleDefault-depth4) {
  padding-top: 13px;
}

.editor:global(.rdw-editor-main blockquote) {
  border-left: 0;
  padding-left: 0;
}

:global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before) {
  content: counter(ol0) "." counters(ol1, '.') " ";
  counter-increment: ol1;
}

:global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before) {
  content: counter(ol0)"." counter(ol1) "." counters(ol2, '.') " ";
  counter-increment: ol2;
}

:global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before) {
  content: counter(ol0)"." counter(ol1) "." counter(ol2) "." counters(ol3, '.') " ";
  counter-increment: ol3;
}

:global(.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before) {
  content: counter(ol0)"." counter(ol1) "." counter(ol2) "." counter(ol3) "." counters(ol4, '.') " ";
  counter-increment: ol4;
}

.editor :global(.rdw-center-aligned-block>div),
.editor :global(.rdw-justify-aligned-block>div),
.editor :global(.rdw-left-aligned-block>div),
.editor :global(.rdw-right-aligned-block>div) {
  vertical-align: text-top;
}


:global(#root .public-DraftEditor-content) {
  height: initial;
}
