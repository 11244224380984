.root {
  margin-bottom: 70px;
}

.header {
  margin-bottom: 25px;
  padding-top: 30px;
  border-top: 1px solid var(--gallery);
}

.title {
  color: var(--mine-shaft);
  font-size: 24px;
  line-height: 30px;
  margin-right: 20px;
}

.value {
  color: var(--tundora);
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
}
