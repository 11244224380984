.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--iron);
  margin-bottom: 10px;
  max-width: 100%;
  text-decoration: none;
  padding: 16px 24px;
  position: relative;
}

.root:hover {
  box-shadow: 0 2px 8px 1px rgba(231,231,231,0.68);
  text-decoration: none !important;
}

.root:last-child {
  margin-right: 0px;
}

.title {
  color: var(--orange);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info {
  opacity: 0.8;
  color: var(--dusty-gray);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.sample {
  position: absolute;
  top: 50%;
  left: 50%;
  text-transform: uppercase;
  transform: translate(-50%, -50%) rotate(-45deg);
  color: var(--primary-color);
  z-index: 0;
  opacity: 0.5;
  font-size: 36px;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .root {
    max-width: calc(33.3% - 16px);
    margin-bottom: 0px;
    margin-right: 24px;
  }
}
