.root {
  width: 900px;
  min-height: 300px;
}

.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 20px;
}

.count {
  display: flex;
  align-items: center;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 9px 7px;
  border-color: transparent transparent #00ce34 transparent;
  margin-left: 5px;
}

.negative {
  border-color: transparent transparent #fa652c transparent;
  transform: rotate(180deg);
}
