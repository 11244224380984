.login {
  height: 100vh;
  max-height: 100%;
}

.login :global(form div) {
  align-items: flex-start;
}

.login :global(form p) {
  font-size: 14px;
  color: var(--dove-gray);
}

.login .ssoButton {
  width: 100%;
  box-sizing: content-box;
}
