.sampleProfile {
  display: inline-block;
  flex-grow: 1;
  width: 47%;
  margin: 12px;
  padding: 24px;
  box-shadow: none !important;
  border-radius: 0 !important;
  overflow: initial !important;
}

.avatar {
  width: 192px;
  min-width: 192px !important;
  height: 192px;
  min-height: 192px !important;
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.profile {
  display: flex;
  color: var(--iron);
}

.info {
  min-width: 0;
  flex: 1 0 288px;
}

.fullName {
  margin-bottom: 18px;
  color: var(--tundora);
}

.organization {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--tundora);
}

.date {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--mine-shaft);
}

