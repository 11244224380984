.editableHeader {
  display: flex;
  max-width: 100%;
}

.field {
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  min-width: 200px;
  background-color: transparent;
  border: 1px solid var(--iron);
  border-radius: 4px;
  font-size: 37px;
  font-family: 'Source Sans Pro', sans-serif;
}

.field:focus {
  border-width: 1px;
}

.error {
  font-size: 12px;
  color: var(--salmon);
  line-height: 16px;
}

.errors {
  border: 1px solid var(--salmon);
}

.icon {
  display: flex !important;
  margin-left: 20px !important;
  margin-top: 14px !important;
  max-height: 19px;
}

.icon svg {
  stroke: var(--primary-color);
  width: 19px;
  height: 19px;
}
