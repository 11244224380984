.sampleProfilesBlock {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-left: -12px;
}

.link {
  color: var(--primary-color);
  text-decoration: underline !important;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.link:hover {
  text-decoration: none !important;
}
