.root {
  position: relative;
  height: 0;
  padding-bottom: 50%;
}

.mapHeader {
  color: #444444;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 30px;
  margin-top: 0;
}

.title {
  color: #444444;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 20px;
}

.color {
  width: 40px;
  height: 20px;
  display: inline-block;
}

.colorRange {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.legend {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  margin-bottom: 70px;
  background-color: rgba(255, 255, 255, 0.07);
}

.range {
  color: #444444;
  font-size: 16px;
  line-height: 20px;
  margin-left: 16px;
}

.tooltip {
  position: fixed;
  pointer-events: none;
  background-color: white;
  border: 1px solid rgb(222, 222, 222);
  transform: translate(-50%, -150%);
  top: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 14px;
  border-radius: 5px;
}

.name {
  overflow: hidden;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 10px;
  white-space: nowrap;
}

.arrow {
  border-color: white transparent transparent;
  margin: 0px auto;
  display: block;
  width: 0px;
  height: 0px;
  position: absolute;
  border-style: solid;
  border-width: 9px 9px 0px;
  bottom: -7px;
  left: 0px;
  right: 0px;
}
