.root {
  width: 100%;
}

.tabs > * {
  width: 200px;
}

.content {
  margin-right: 48px;
}

.tabsContainer ul {
  margin: 0;
  padding: 0;
}
