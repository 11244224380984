.infiniteScroll {
  max-height: 350px;
  overflow: hidden;
  overflow-y: scroll;
}

.header {
  margin-bottom: 19.5px;
}

.loaderBox {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: var(--dusty-gray);
}

.errorButton {
  margin-top: 6px;
  display: flex;
  align-items: center;
  color: var(--dusty-gray);
}

.errorButton svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
