.row {
  display: flex;
  margin-bottom: 12px;
}

.row > *:not(:last-child) {
  margin-right: 48px;
}

.alertBox {
  border: 1px solid var(--salmon);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  margin-top: 66px;
  padding: 0 20px;
}

.alertTitle {
  align-self: center;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
