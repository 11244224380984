.fields {
  margin-top: 36px;
  width: 400px;
}

.fields > *:not(:last-child) {
  margin-bottom: 12px;
}

.textarea {
  height: 300px;
  max-height: 300px;
}
