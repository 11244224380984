.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.title {
  padding: 0 15px;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  margin: 20px 0 10px 0;
}

.counter {
  color: var(--boulder);
}

.container {
  display: flex;
  width: 100%;
}

.main {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 15px;
  overflow: hidden;
}

.totalAvailableProfilesBlock {
  display: flex;
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 0 15px;
}

.totalAvailableProfilesText {
  font-size: 16px;
}

.toolTipName {
  color: var(--primary-color);
  padding-left: 8px;
  cursor: pointer;
}

.tooltipAnchor {
  color: var(--athens-gray);
  text-decoration: underline;
}

.tooltipAnchor:hover {
  color: var(--athens-gray);
}

@media (min-width: 1024px) {
  .header {
    flex-direction: row;
  }

  .side {
    flex: 0 0 340px;
    width: 200px;
    display: block;
    border-right: 1px solid var(--gallery);
  }

  .main {
    padding-right: 0;
    padding-left: 32px;
    width: 100%;
    overflow: hidden;
  }

  .title {
    padding: 0;
    font-size: 37px;
    line-height: 44px;
  }

  .totalAvailableProfilesBlock {
    margin-top: 10px;
    padding: 0;
  }

  .totalAvailableProfilesText {
    font-size: 16px;
  }

  .boardexPowered {
    display: none;
  }
}
