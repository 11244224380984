.root {
  padding: 0 15px;
  overflow: hidden;
}

.root p p {
  overflow: hidden;
  text-overflow: ellipsis;
}

a {
  color: var(--primary-color);
}

h5 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 300;
}

@media (min-width: 1024px) {
  .root {
    border: 1px solid var(--alto);
    padding: 23px;
  }
}
