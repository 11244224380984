.root {
  margin-top: 12px;
  padding: 0px 15px;
}

.savedSearch {
  margin-bottom: 24px;
}

.empty {
  font-size: 14px;
  font-style: italic;
  line-height: 18px;
  color: var(--oslo-gray);
  padding-top: 24px;
  text-align: center;
}

.loaderBox {
  display: flex;
  justify-content: center;
}


@media (min-width: 1024px) {
  .root {
    padding: 0px 30px 0px 0px;
  }
}
