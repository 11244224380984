.root {
  position: absolute;
  bottom: -16px;
  right: 0;
  border: 2px solid var(--gallery);
  background-color: var(--wild-sand);
  width: 170px;
  transform: translateY(100%);
  z-index: 1;
}

.hidden {
  display: none;
}
