.root {
  margin-bottom: 30px;
}

.general {
  display: flex;
}

.main {
  flex-grow: 1;
  padding-right: 45px;
  padding-left: 8px;
}

.info {
  display: flex;
  margin-bottom: 14px;
  justify-content: space-between;
}

.counter {
  color: var(--primary-color);
}

.limits {
  line-height: 26px;
  margin-bottom: 6px;
}

.status {
  border-left: solid 3px var(--lemon);
  padding: 0px 19px 0px 9px;
}
