.default, .outlined {
  border-radius: 14px;
  box-sizing: border-box;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  height: 26px;
  padding: 4px 12px;
  text-align: center;
  line-height: 18px;
}

.default.primaryColor {
  background-color: var(--primary-color);
}

.outlined.primaryColor {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
