.card {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 8px 12px;
  border: 2px solid var(--iron);
  background-color: white;
  margin: 5px;
}


.card:hover {
  background-color: var(--gallery);
}
