.logo {
  position: relative;
}

.logo > * {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.image {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
