.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.logo, .logo svg {
  width: 189px;
  height: 59px;
}

.header {
  margin-top: 60px;
  color: var(--shark);
  font-size: 37px;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
}

.note {
  margin-top: 40px;
  color: var(--shark);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
}

.link, .link:hover, .link:visited {
  color: var(--primary-color);
  text-decoration: underline;
}

.spinner {
  margin-top: 100px;
}
