.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.split {
  height: calc(100% - 70px);;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: row;
}

.splitLeft {
  height: auto;
  z-index: 0;
  flex: 0 0 auto;
  overflow: auto;
}

.splitRight {
  height: auto;
  z-index: 1;
  flex: 1 1;
  overflow: auto;
}

.main {
  display: flex;
  height: auto;
  min-height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px;
}

@media (min-width: 1024px) {
  .main {
    padding: 24px;
  }
}
