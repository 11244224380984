.successStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  color: #EBEBEB;
}

.successStatus > *:not(:last-child) {
  margin-bottom: 12px;
}
