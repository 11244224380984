.header {
  border-bottom: 1px solid var(--mercury);
  margin-bottom: 20px;
}

.menuOpened {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: white;
  overflow: scroll;
  z-index: 12;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.profileMenuOpened .profile {
  color: var(--pacific-blue);
}

.menu {
  display: none;
  justify-content: flex-end;
}

.menuOpened .menu {
  display: block;
}

.logo {
  margin-bottom: 30px;
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.logo {
  padding: 0;
}

.menuSwitcher {
  padding: 0;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 34px;
  border: none;
  transition: none;
}

.menuHamburger {
  border: 1px solid #d1d1d1;
}

.switch {
  display: flex;
  justify-content: space-between;
  min-height: 64px;
  align-items: center;
  padding: 0 12px;
}

.dropdownMenu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.dropdownMenu > ul li {
  border-top: 1px solid var(--mercury);
  max-width: initial;
  padding: 0 24px;
}

.dropdownMenu > ul li a, .dropdownMenu > ul li span {
  min-height: 64px;
  padding: 0 24px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: inherit;
}

.menu > ul li {
  min-height: 64px;
  border-top: 1px solid var(--mercury);
  display: flex;
  align-items: center;
  max-width: initial;
}

.up {
  transform: rotate(-180deg);
}

.hidden {
  display: none;
}

.profile a {
  display: block;
  font-size: 16px;
  padding: 20px 12px 20px 12px;
  color: var(--primary-color);
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.hideOnMobile {
  display: none;
}

.notificationBadge {
  width: 6px;
  height: 6px;
  background-color: var(--persimmon);
  border-radius: 50%;
}

.icon {
  position: relative;
}

.icon .notificationBadge {
  position: absolute;
  right: 0px;
  top: 0px;
  outline: 2px solid #fff;
}

.item .notificationBadge {
  margin-left: 8px;
}

.crossIcon {
  stroke: #fff;
}

.hamburgerIcon {
  color: #888;
}

.menuButton {
  min-height: 64px;
  padding: 0 24px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: inherit;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

@media (min-width: 1024px) {

  .logo {
    margin-bottom: 0;
    margin-top: 0;
    min-height: auto;
    padding: 0;
  }

  .header {
    min-height: 70px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 0;
  }

  .logo {
    margin-right: 47px;
  }

  .menu ul li {
    min-height: auto;
    border-bottom: none;
    position: relative;
  }

  .profile {
    color: var(--boulder);
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 23px;
  }

  .profile a {
    padding: 0;
    font-size: 14px;
  }

  .profile:hover {
    color: var(--pacific-blue);
  }

  .profile::before {
    content: '';
    width: 1px;
    height: 14px;
    background-color: currentColor;
    margin-right: 26px;
    position: relative;
  }

  .profile::before {
    background-color: var(--boulder);
  }

  .icon {
    margin-right: 9px;
    height: 22px;
  }

  .dropdownMenu {
    width: 188px;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    padding-top: 10px;
  }

  .dropdownMenu ul li {
    margin-bottom: 0;
    font-size: 14px;
    color: var(--boulder);
    padding: 0;
    border: 1px solid var(--gallery);
    border-top: 1px solid transparent;
    display: flex;
  }

  .dropdownMenu ul li a, .dropdownMenu ul li span {
    padding: 0 10px;
    min-height: 40px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    border: 1px solid var(--gallery);
    border-top: 1px solid transparent;
    color: inherit;
  }

  .dropdownMenu ul li:hover {
    background-color: #fff;
    border-color: var(--pacific-blue);
    border: 1px solid var(--pacific-blue);
  }

  .dropdownMenu ul li:first-child {
    border-top: 1px solid var(--gallery);
  }

  .dropdownMenu ul li:first-child:hover {
    border: 1px solid var(--pacific-blue);
  }

  .item {
    background-color: var(--wild-sand);
  }

  .menuSwitcher {
    display: none;
  }

  .switch {
    min-height: auto;
    border-bottom: none;
    padding: 0;
    padding-right: 7px;
  }

  .arrowBox {
    display: flex;
  }

  .menu {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .menuOpened {
    position: initial;
    width: initial;
    height: initial;
    background: initial;
    left: initial;
    overflow: initial;
    z-index: initial;
  }

  .menuOpened .menu {
    display: flex;
  }

  .search {
    margin-right: auto;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .hideOnMobile {
    display: block;
  }

  .menuButton {
    padding: 0 10px;
    min-height: 40px;
    border: 1px solid #f0f0f0;
    border-top: 1px solid transparent;
  }
}
