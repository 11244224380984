.fileUploadContainer {
  padding: 40px 50px;
  display: flex;
}

.tabs {
  width: 100%;
}

.tabList {
  border: none;
}

.tabPanels {
  margin-top: 34px;
}

.tab {
  width: 205px;
  max-width: 205px;
  font-size: 14px;
  line-height: 8px;
  margin-bottom: 0;
}

.tab:hover:not(.activeTab) {
  border: none;
}

.tab:hover.activeTab {
  border: none;
}

.activeTab {
  color: white;
  border: none;
  background-color: var(--primary-color);
  border-radius: 4px;
  padding: 6px 16px;
}

.countDataItems {
  font-weight: 600;
  margin-left: 3px;
}

.tab:not(.activeTab) .countDataItems {
  color: var(--primary-color);
}
