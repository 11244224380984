.container {
  padding: 0 44px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.information {
  margin: 32px 0 24px;
  font-size: 16px;
  line-height: 20px;
  color: var(--shark);
}

.count {
  color: var(--primary-color);
}

.search {
  background: #fff;
  border: 1px solid var(--iron);
  display: flex;
  align-items: center;
}

.search svg {
  stroke-width: 2;
  margin: 0 12px;
}

.searchInput {
  border: none;
  display: flex;
  flex: 1;
  font-size: 14px;
  line-height: 18px;
  color: var(--dove-gray);
  padding: 11px 12px;
  font-family: 'Source Sans Pro', sans-serif;
}

.title {
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 23px;
  color: #444444;
  font-size: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  flex-shrink: 0;
}

.list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.loaderBox {
  margin: 20px auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh * 0.2);
}

.button {
  margin: 26px 0px;
  padding: 8px 78px;
  align-self: center;
}
