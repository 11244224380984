.root {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  height: 100%;
  overflow: auto;
}

.container {
  max-width: 1210px;
  margin: auto;
  padding: 0 30px;
  width: 100%;
}

.siteContent {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

.main {
  flex-grow: 1;
}
