.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid var(--mercury);
}

.profile {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.profile:hover {
  text-decoration: none !important;
}

.avatar {
  margin: 33px 24px 33px 0px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
  flex: 1;
}

.header {
  color: var(--pacific-blue);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.name {
  margin-right: 16px;
}

.profile:hover .name {
  text-decoration: underline;
}

.works {
  color: var(--shark);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.actions {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
}

.removeButton {
  padding: 8px 22px;
  margin-bottom: 16px !important;
}

.undoLink {
  color: var(--primary-color);
  margin-left: 4px;
}

.profileLabels > *:not(:first-child) {
  margin-left: 8px;
}

@media (min-width: 1024px) {
  .root {
    flex-direction: row;
  }

  .removeButton {
    margin-bottom: 0px !important;
  }
}
