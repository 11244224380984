.tableHead tr:last-child th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.tableHead th {
  padding: 10px 12px;
  text-align: left;
  font-size: 12px;
  color: var(--mine-shaft);
  text-transform: uppercase;
  font-weight: 300;
}

.tableHead th:first-child {
  padding-left: 24px;
}

.tableHead th:last-child {
  padding-right: 24px;
}
