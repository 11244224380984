.infoBlock {
  display: flex;
  margin-bottom: 4px;
  font-size: 10px;
}

.isNewLabel {
  color: var(--orange);
  margin-left: 5px;
}

.container {
  border: 1px solid var(--orange);
  padding: 8px;
}

.description {
  overflow: hidden;
  font-size: 12px;
  line-height: 15px;
  white-space: pre-wrap;
}

.collapsedDescription {
  max-height: 60px;
  position: relative;
}

.collapsedDescription::before {
  content: '';
  background: linear-gradient( 180deg, transparent 50%, #fff);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.contentAndActions {
  display: flex;
  justify-content: space-between;
}

.editActions {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.editActionButton {
  display: block;
  height: 20px;
  width: 20px;
}

.editActionButton:not(:first-child) {
  margin-top: 20px;
}

.editActions svg {
  stroke: var(--primary-color);
  width: 20px;
  height: 20px;
}

.collapseControl {
  bottom: 0;
  left: 0;
  width: 100%;
}


.collapseButton {
  width: 100%;
  border: none;
  padding: 0;
  font-size: 14px;
  color: var(--primary-color);
  background: none;
}

.collapseButton:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

