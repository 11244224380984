.filters {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
}

.actions {
  display: flex;
  align-items: center;
}

.searchArea {
  display: flex;
  align-items: center;
  background-color: var(--athens-gray);
  padding-right: 15px;
  flex-grow: 1;
  height: 40px;
  margin-bottom: 16px;
}

.searchArea svg {
  stroke-width: 2;
}

.configurationButton {
  margin-right: 16px !important;
  padding: 0px !important;
  height: 50px;
  min-height: 50px !important;
  min-width: 50px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-width: 1px !important;
}

.searchInput {
  width: 100%;
  border-radius: 0;
  background-color: var(--athens-gray);
  border: none;
  padding: 11px 12px;
  color: var(--mine-shaft);
  font-size: 14px;
  line-height: 18px;
  outline: none;
  font-family: 'Source Sans Pro', sans-serif;
}

.searchInput::placeholder {
  color: var(--mine-shaft);
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

.subscriptions {
  margin-top: 32px;
}

.info {
  background-color: var(--athens-gray);
  padding: 24px;
}

.noticeHeader {
  height: 32px;
  color: var(--tundora);
  font-size: 32px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 20px;
}

.notice {
  height: 24px;
  width: 732px;
  color: var(--gray);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.filterBySelect {
  flex: 1;
  min-width: 200px;
}

@media (min-width: 1024px) {
  .filters {
    flex-direction: row;
    align-items: center;
  }

  .actions {
    margin-left: 16px;
    flex-shrink: 1;
    justify-content: center;
  }

  .searchArea {
    margin-bottom: 0px;
  }

  .filterBySelect {
    flex: 0;
  }
}
