.stats {
  padding: 0 0 5px 0;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.listItem {
  flex-basis: calc(50% - 10px);
  display: flex;
  font-size: 11px;
}

.listItem:nth-child(2n+1) {
  margin-right: 20px;
}

.legend {
  display: flex;
  width: 5px;
  height: 5px;
  align-self: center;
}

.itemName {
  color: #838588;
  flex-grow: 1;
  margin-left: 5px;
}

.itemCount {
  font-weight: 600;
  color: #444;
  flex-grow: 0;
}

.text {
  font-size: 11px;
  color: #444444;
}

.number {
  font-weight: 600;
  font-size: 11px;
  color: #444444;
}

.total {
  display: flex;
  justify-content: space-between;
}

.chart {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
}

.chartItem {
  display: block;
  height: 6px;
}
