.root {
  display: flex;
  min-width: 380px;
}

.root p {
  font-size: 12px;
  margin-bottom: 7px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 24px;
}

.avatar svg {
  width: 100px;
  height: 100px;
}

.photo {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
}

.fullName {
  font-weight: 600;
  margin-bottom: 12px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
