.fields {
  margin-top: 36px;
}

.fields > *:not(:last-child) {
  margin-bottom: 12px;
}

.resetButton {
  margin-left: 10px;
  text-decoration: underline;
}
