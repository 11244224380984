.actionsItem {
  width: 100%;
  padding: 0;
  margin-right: 0;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 40px;
  position: relative;
  font-weight: 300 !important;
}

.actionsItem:hover {
  border-color: #04A0C5;
  background-color: #F4F5F7;
  text-decoration: none !important;
}

.actionsItem[disabled]  {
  opacity: 1;
  pointer-events: none;
}

.actionsItem[disabled]  > div {
  opacity: 0.3;
}

.actionsItem[disabled] :after {
  content: '';
  background-color: rgba(0, 0, 0, 0.04);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.01);
  border-radius: 5px;
}

.actionsItem[disabled]:hover {
  cursor: not-allowed;
  border-color: #E7E7E7;
  background-color: transparent;
}

.actionsItem:nth-child(even) {
  margin-right: 0;
}

.actionsTitle {
  color: #333333;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}

.actionsDescription{
  color: #828282;
  font-size: 12px;
  line-height: 17px;
  width: 100%;
}

.actionsIcon {
  height: 20px;
  margin-bottom: 18px;
  color: #04A0C5;
}

@media (min-width: 1024px) {
  .actionsItem {
    width: 170px;
    margin-right: 10px;
    min-height: 200px;
    padding-bottom: initial;
  }
}
