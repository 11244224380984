.informationIcon {
  display: flex;
  align-self: center;
  margin-left: 5px;
}

.information {
  display: flex;
  flex-direction: column;
  color: var(--orange);
  font-size: 11px;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 14px;
}

.title {
  color: var(--tundora);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 8px;
}

.select :global(.mailSelectBox__value-container) {
  padding: 0px 8px;
  margin-top: 0px;
}

.select :global(.mailSelectBox__option) {
  padding: 6px 12px;
}

.noItems {
  margin: 8px 0px 0px 8px;
  color: var(--boulder);
}

.disabledOption {
  cursor: not-allowed;
  color: var(--gray);
}

.shares {
  min-height: 100px;
}

.shares > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.share {
  display: flex;
  align-items: center;
  background: #E6E6E6;
  color: var(--tundora);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  border-radius: 2px;
  padding: 0px 8px;
  margin-bottom: 4px;
}

.removeShareButton {
  margin-left: 8px;
}

.removeShareButton > svg {
  width: 8px;
  height: 8px;
  stroke-width: 4px;
}
