.root {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px
}

.link {
  color: var(--pacific-blue);
}

.link:hover {
  color: var(--pacific-blue);
  text-decoration: none;
}
