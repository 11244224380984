.boardexBlock {
  display: flex;
  flex-direction: column;
}

.boardexLink {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 600;
}

.boardexLink:hover {
  text-decoration: underline;
}

.boardexPowered {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  font-size: 10px;
}

.boardexImage {
  display: block;
  width: 50px;
  height: fit-content;
}
