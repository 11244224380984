.root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.success {
  padding: 16px 19px;
  margin-bottom: 20px;
}

.success h4 {
  font-weight: bold;
  margin-top: 0px;
}

.error {
  color: var(--salmon);
  padding: 16px 19px;
  margin-bottom: 20px;
}

.error h4 {
  display:flex;
  align-items: center;
  justify-content: center;
  color: var(--salmon);
  font-weight: bold;
  margin-top: 0px;
}

.error h4 svg {
  stroke: var(--salmon);
  margin-right: 6px;
}
