.root {
  padding: 24px;
  width: 100%;
}

.secondaryText {
  margin-top: 12px;
  margin-bottom: 24px;
}

.field > label > * {
  margin-bottom: 0;
}

.label {
  font-size: 11px;
  text-transform: none;
  font-weight: 300;
  letter-spacing: 0;
  color: var(--tundora);
}

.field {
  border: 1px solid var(--silver);
  margin-bottom: -1px;
  padding: 6px 12px;
}

.field:last-child {
  margin-bottom: none;
}

.input {
  border: none;
  padding: 0;
}

.rememberMeBlock {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 24px;
}

.rememberMeLabel {
  margin-left: 12px;
}


.errors {
  margin-top: 24px;
}

.submit {
  width: 100%;
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  .root {
    width: 480px;
  }
}
