.root {
  margin-bottom: 16px;
  max-width: 100%;
}

.root:last-child {
  margin-bottom: 0px;
}

.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 0px;
}

.errors {
  font-size: 12px;
  color: var(--salmon);
}
