.fieldTitle {
  color: var(--tundora);
  font-size: 14px;
  line-height: 36px;
  self-align: start;
}

.fieldBox {
  display: flex;
  flex-direction: column;
}
