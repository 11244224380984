.root {
  padding: 0 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.title {
  font-weight: 700;
}

.subtitle {
  margin: 16px 0 32px;;
  font-weight: 400;
}
