.item {
  border: 1px solid red;
  border-radius: 4px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  font-size: 14px;
  color: #444;
  margin-bottom: 15px;
}

.name {
  margin-right: 4px;
}

.close {
  width: 8px !important;
  height: 8px !important;
}

.closeIcon {
  width: 8px !important;
  height: 8px !important;
  vertical-align: top;
}
