.root {
  margin-bottom: 20px;
}

.root:last-child {
  margin-bottom: 0;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.collapsed .text {
  height: 140px;
  overflow: hidden;
  position: relative;
}

.collapsed .text::before {
  content: '';
  background: linear-gradient(180deg,transparent 50%,#fff);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.title {
  border: 1px solid #E0E2E5;
  border-radius: 4px;
  border-bottom: none;
  display: inline-block;
  padding: 0 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.titleText {
  font-size: 12px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  border: 1px solid #E0E2E5;
  padding: 20px 10px 20px 25px;
}

.deleteTag {
  padding: 0;
  border: none;
  width: auto;
  min-width: auto;
  margin-left: 9px;
  background: transparent;
}

.deleteTag:focus,
.button:focus {
  outline: none;
  box-shadow: none;
}

.deleteTag[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.button {
  padding: 2px 16px;
  border-width: 1px;
  font-size: 14px;
  min-width: 100px;
  margin-top: 30px;
}

.collapsed .button {
  margin-top: 0;
}


