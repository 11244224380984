.message {
  font-size: 48px;
  color: var(--outer-space);
  text-align: center;
  font-weight: 600;
}

.actions {
  text-align: center;
  margin-top: 40px;
}
