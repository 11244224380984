.socialLink {
  color: var(--boulder) !important;
  margin-right: 10px !important;
  padding: 0 !important;
}

.socialLink:visited {
  color: var(--boulder) !important;
}

.socialLink:hover {
  color: var(--pacific-blue) !important;
}
