.info {
  display: flex;
  justify-content: space-between;
}

.item {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
  padding: 6px 6px;
  display: flex;
}

.item:last-child {
  margin-bottom: 0;
}

.header {
  width: 100%;
  overflow: hidden;
}

.name {
  font-size: 14px;
  color: #444444;
  margin-bottom: -1px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: 9px;
  color: #838588;
  line-height: 1.333333;
  padding-right: 10px;
}

.icon {
  flex-shrink: 0;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4px;
  margin-left: -6px;
}

.icon svg {
  stroke: #00A0CE;
  width: 19px;
  height: auto;
  max-height: 16px;
}

.actions {
  display: flex;
  flex-shrink: 1;
  width: 66px;
  align-items: center;
  flex-direction: column;
}

.actions svg {
  width: 13px;
  height: 13px;
  stroke: #00A0CE;
  display: block;
}

.general {
  flex-grow: 1;
  overflow: hidden;
}

.actionButton {
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-width: 33px;
  min-height: 33px;
}

@media (min-width: 1024px) {
  .actions {
    flex-direction: row;
    flex-shrink: 0;
  }
}
