.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

:global(.grommet) .content .number {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 17px;
}

:global(.grommet) .content .text {
  font-size: 48px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 42px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 15px;
  text-align: center;
}

:global(.grommet) .content .button {
  font-size: 18px;
  padding: 6px 45px;
}

.wrapper {
  height: 100%;
}

@media (min-width: 1024px) {
  .main {
    padding: 0 15px;
    text-align: left;
  }
}
