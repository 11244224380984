.statusWrapper {
  display: flex;
  align-items: center;
}

.statusName {
  margin-right: 5px;
}

.statusIcon {
  stroke: var(--orange) !important;
}

.linkToProfile {
  color: var(--primary-color);
}

.linkToProfile:visited {
  color: var(--primary-color);
}

