.main {
  display: flex;
}

.sidebarLegend {
  flex: 0 0 auto;
  padding-right: 22px;
  border-right: 1px solid #d9d9d9;
  margin: 24px 0;
}

.graph {
  flex-grow: 1;
}
