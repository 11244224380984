.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(20% - 16px);
  min-width: calc(20% - 16px);
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid var(--gallery);
}

.root:nth-child(5n+5) {
  margin-right: 0px;
}

.sample {
  border-color: var(--pacific-blue);
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--athens-gray);
  color: var(--dove-gray);
  font-size: 18px;
  min-height: 182px;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: var(--pacific-blue);
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.close {
  width: 24px;
  height: 24px;
}

.close svg {
  stroke: white;
}

.footer {
  display: flex;
  justify-content: center;
}

.footer > button {
  padding: 10px 21px;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 24px;
}
