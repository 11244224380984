.item {
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  margin-bottom: 17px;
  justify-content: space-between;
  max-width: 600px;
}

.item:last-child {
  margin-bottom: 0;
}

.user {
  display: flex;
  padding-bottom: 10px;
  max-width: 400px;
}

.user:hover {
  text-decoration: none;
}

.name {
  color: #04A0C5;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  margin-bottom: 5px;
}

.position,
.company {
  color: #444444;
  font-size: 14px;
  letter-spacing: -0.22px;
  line-height: 18px;
  margin-bottom: 5px;
  font-weight: 300;
}

.company {
  margin-bottom: 0;
}

.info {
  margin-left: 20px;
}

.claim {
  max-height: 40px;
  padding: 0px;
}
