.container {
  padding: 0 44px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.title {
  line-height: 1.3;
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 23px;
  color: #444444;
  font-size: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  flex-shrink: 0;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 31px;
}

.export {
  margin-left: auto;
  background-color: #F4F5F7;
}
