.side {
  background-color: #f4f5f7;
  flex-basis: 250px;
  margin-right: 20px;
  padding: 20px;
  border: 1px solid #dee0e3;
  flex-shrink: 0;
  position: relative;
}

.side:after {
  content: "";
  display: block;
  background-color: #f4f5f7;
  width: 248px;
  height: 24px;
  position: absolute;
  bottom: -24px;
  left: -1px;
  border-left: 1px solid #dee0e3;
  border-right: 1px solid #dee0e3;
}

.filter {
  margin-bottom: 20px;
}


.title {
  color: #333;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}

.select {
  background-color: #fff;
}

.select > div {
  min-height: 40px;
}

.label {
  color: #333;
  font-size: 14px;
  line-height: 18px;
}

.compare {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox label {
  width: 100%;
  justify-content: space-between;
  font-size: 14px !important;
}

.exportButton {
  width: 100%;
  margin-bottom: 15px;
}
