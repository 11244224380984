.chart {
  border: 1px solid #dee0e3;
  background-color: #fff;
  padding: 0 16px;
}

.chartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 3px 6px;
}

.chartTitle {
  color: #444;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

.totalCount {
  color: #444;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  display: flex;
  align-items: baseline;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 9px 7px;
  border-color: transparent transparent #00ce34 transparent;
  margin-right: 5px;
}

.negative {
  border-color: transparent transparent #fa652c transparent;
  transform: rotate(180deg);
}
