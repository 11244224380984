.alert {
  display: flex;
  padding: 6px 24px;
  min-height: 48px;
  background-color: rgba(238, 238, 238, 0.9);
  align-items: center;
}

.alert > *:not(:last-child) {
  margin-right: 24px;
}

.message {
  flex: 1;
}

.icon {
  width: 10px;
  height: 10px;
}

.critical {
  background-color: var(--salmon);
  transform: rotate(45deg);
}

.ok {
  background-color: var(--japanese-laurel);
  border-radius: 50%;
}
