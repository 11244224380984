.search {
  border: 1px solid var(--athens-gray) !important;
  background-color: var(--athens-gray) !important;
  font-weight: 300 !important;
}

.search:focus {
  box-shadow: none !important;
}

.search::after {
  content: '';
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2214%22%20height%3D%2214%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M-5-5h24v24H-5z%22/%3E%3Cpath%20d%3D%22M8.8%208.8L13%2013%208.8%208.8zM5.5%2010a4.5%204.5%200%201%200%200-9%204.5%204.5%200%200%200%200%209z%22%20stroke%3D%22%23666%22%20stroke-width%3D%222%22/%3E%3C/g%3E%3C/svg%3E");
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin-right: 16px;
}
