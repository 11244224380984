.root {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.tabs ul {
  margin: 0;
  padding: 0;
}

.tab {
  max-width: none;
}
