.sampleProfile {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--mercury);
  width: 100%;
  padding-bottom: 30px;
  padding-right: 10px;
}

.info {
  color: var(--shark);
  font-size: 15px;
  line-height: 20px;
}

.header {
  color: var(--pacific-blue);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.22px;
  line-height: 19px;
  margin-bottom: 18px;
  margin-top: 40px;
}

.small {
  color: var(--boulder);
  font-size: 12px;
  line-height: 19px;
}

.work {
  margin-bottom: 18px;
}

.work:last-child {
  margin-bottom: 0px;
}

.workInfo {
  margin-bottom: 10px;
}


@media (min-width: 1024px) {
  .sampleProfile {
    width: auto;
    max-width: 519px !important;
    flex-basis: 460px;
    flex-direction: row !important;
    text-align: left;
    align-items: flex-start;
    padding-top: 34px;
    padding-left: 17px;
    flex-shrink: 1;
    min-width: 460px !important;
    flex-grow: 1;
  }

  .sampleProfile:hover {
    box-shadow: 3px 2px 10px -3px rgba(0,0,0,0.2);
  }

  .header {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .avatar {
    margin-right: 40px;
    flex-shrink: 0;
  }

  .work {
    margin-bottom: 10px;
  }

  .workInfo {
    margin-bottom: 0;
  }
}
