.box {
  position: relative;
}

.list {
  margin-left: -20px;
}

.list ul {
  margin: 0;
}

.dragArea {
  top: 0;
  position: absolute;
  left: 0;
  margin-left: -22px;
  margin-top: 8px;
}
