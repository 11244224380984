.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 81px;
}

.error {
  margin-bottom: 24px;
  color: var(--salmon);
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
}

.loader .loaderImg {
  max-width: 200px;
}
