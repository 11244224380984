.sidebar {
  float: right;
  display: inline-block;
  position: relative;
  height: 100vh;
  border: 1px solid #d4d4d4;
  width: 700px;
  background-color: #F4F5F7;
}

.root {
  background-color: #F4F5F7;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.closeButton {
  padding: 0;
  border: none;
  min-width: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 23px;
  margin-left: 23px;
  background-color: transparent;
}

.closeButton:focus {
  box-shadow: none;
}

.header {
  position: relative;
  padding-top: 6px;
  margin-bottom: 39px;
}

@media (min-width: 1024px) {
  .sidebar {
    height: 100%;
  }
}
