.header {
  display: flex;
  margin-bottom: 19.5px;
}

.header a {
  color: var(--primary-color);
  text-decoration: underline;
}

.reviewTitle {
  font-size: 16px !important;
  margin-left: 12px;
  flex-shrink: 0;
  font-weight: normal !important;
}

.title {
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
}
