.root {
  max-width: 200px;
  padding: 16px;
  border: 1px solid #EBEBEB;
  background-color: #fff;
  pointer-events: auto;
}

.item, .total {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 4px;
}

.count {
  color: var(--primary-color);
  margin-left: 10px;
  font-weight: 600;
}

.divider {
  margin: 8px 0;
  border-color: #EBEBEB;
  opacity: 0.3;
}

.total {
  margin-bottom: 0;
  text-transform: uppercase;
}
