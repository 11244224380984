.root {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 24px;
  border: 1px solid var(--alto);
  justify-content: center;
}

.root img {
  width: 100%;
}

.relationshipsHeader {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  padding: 16px 32px;
  border-bottom: 1px solid var(--alto);
}

.relationshipsControls {
  display: flex;
  margin: 40px 0px;
  justify-content: center;
}

.promotionHeader {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  padding: 44px 24px 0px;
  text-align: center;
  width: 100%;
}

.promotionControls {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  margin: 40px 0px;
  justify-content: center;
  width: 100%;
}

