.root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.root > svg {
  width: auto;
  height: auto;
  max-height: 48px;
}
