.layout {
  max-width: 1340px;
}

.tabs div[role="tablist"] button span {
  font-size: 14px;
}

.tabs div[role="tablist"] button[role="tab"] div {
  border-width: 4px;
}

.tabs div[role="tablist"] button[aria-selected="false"] span {
  color: var(--mine-shaft);
}

.tabs div[role="tablist"] button[aria-selected="false"] div  {
  border-color: transparent;
}

.table table {
  table-layout: fixed;
}

.table tbody tr:nth-child(odd) {
  background-color: var(--athens-gray);
}

.table tbody td {
  font-size: 14px;
}

.selectContainer {
  max-width: 240px;
  width: auto;
  margin: 0px 10px 10px;
  flex: 1 1;
}

.selectContainer:last-child {
  margin-right: 0;
}

.selectContainer:first-child {
  margin-left: 0;
}
