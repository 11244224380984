.content {
  min-width: 1024px;
}

.content table tbody td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.tableWrapper {
  overflow-x: scroll;
}

@media (min-width: 1024px) {
  .tableWrapper {
    overflow-x: initial;
  }

  .header {
    padding: 0;
  }
}
