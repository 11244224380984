.form {
  display: flex;
}

.sidebar {
  width: 400px;
  padding-top: 36px;
  border-right: 1px solid var(--gallery)
}

.sidebar > * {
  padding-right: 30px;
  margin-bottom: 12px;
}

.actions {
  position: sticky;
  bottom: 0;
  padding: 12px 30px;
  background: #FFF;
  border-top: 1px solid var(--gallery);
  box-shadow: 0px -2px 8px 0 rgb(131 133 136 / 24%);
  margin: 0px 0px -24px -30px;
}

.saveButton {
  width: 100%;
}

.profileList {
  margin: 36px 0 0 30px;
  flex: 1;
}

.counter {
  color: var(--boulder);
}

.message {
  padding: 32px 0;
}
