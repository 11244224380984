.root {
  background-color: #f4f5f7;
  padding: 15px 19px;
}

.headLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  align-items: flex-start;
  flex-direction: column;
}

.headLine > * {
  width: 100%;
}

.title {
  font-size: 18px;
  color: #444444;
}

@media (min-width: 1024px) {
  .headLine {
    align-items: center;
    flex-direction: row;
  }

  .headLine > * {
    width: initial;
  }
}
