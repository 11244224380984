.default {
  color: inherit;
  display: flex;
}

.primaryColor {
  color: var(--primary-color);
}

.underscored {
  text-decoration: underline;
}

.noneDecoration {
  text-decoration: none;
}
