.information {
  padding: 36px 15px 0;
}

.header {
  margin-bottom: 36px;
  line-height: 38px;
}

.instruction {
  padding: 0;
}

.instruction > li {
  padding-left: 16px;
  text-indent: -1rem;
  font-size: 14px;
}

@media (min-width: 1024px) {
  .information {
    padding: 36px 0 0;
  }
}
