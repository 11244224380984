.card {
  display: block;
  position: relative;
  border: 1px solid var(--gallery);
  width: 100%;
}

.card:hover {
  text-decoration: none !important;
  box-shadow: 0 2px 8px 1px rgba(231,231,231,0.68);
}

.sample {
  position: absolute;
  top: 50%;
  left: 50%;
  text-transform: uppercase;
  transform: translate(-50%, -50%) rotate(-45deg);
  color: var(--primary-color);
  z-index: 0;
  opacity: 0.5;
  font-size: 50px;
  font-weight: 600;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gallery);
  height: 54px;
  padding: 0px 16px;
  max-width: 100%;
}

.actions {
  display: flex;
  position: relative;
}

.selectedFilters {
  margin: 16px;
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon svg {
  stroke: var(--primary-color);
  width: 20px;
  height: 20px;
}


.menu {
  z-index: 1;
}

@media (min-width: 1024px) {
  .card {
    flex: 0 362px;
  }
}
