.box {
  position: relative;
}

.list {
  padding-left: 24px;
}

.dragArea {
  top: 0;
  position: absolute;
  left: 0;
  margin-left: -22px;
  margin-top: 8px;
}
