.anchor > svg {
  margin-right: 4px;
}

.addToProject svg {
  fill: none;
}

.removeFromProject,
.addToProject {
  font-weight: 300 !important;
}

.removeFromProject svg {
  fill: var(--pacific-blue);
}

.editCell {
  padding: 10px 0;
}

.status {
  position: relative;
}

.status::after {
  content: "";
  position: absolute;
  top: 0;
  right: 25px;
  bottom: 0;
  margin: 4px 0px;
  background: red;
  width: 4px;
  height: auto;
}

.pending::after {
  background: var(--lemon);
}

.delivered::after {
  background: var(--malachite);
}

.notRequested::after {
  background: var(--orange);
}

.canceled::after {
  background: var(--aquamarine-blue);
}

.notIncluded::after {
  background: var(--biloba-flower);
}
