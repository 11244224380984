.tag {
  display: flex;
  align-items: center;
}

.label {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-color: red;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
}

.text a {
  font-size: 18px;
  color: #444444;
  line-height: 24px;
  font-weight: bold;
}

.tagsLine {
  width: 100%;
  height: 3px;
  display: flex;
  margin-bottom: 13px;
}

.tags {
  margin-top: 12px;
}

.tags table {
  margin-bottom: 8px;
}

.part {
  height: 100%;
}

.table {
  table-layout: fixed;
  width: 100%;
}

.table td {
  vertical-align: top;
}

.table ul {
  list-style-type: none;
  margin-left: 26px;
  padding: 0;
}

.table ul li a {
  font-size: 16px;
  font-weight: 300;
  color: #444444;
  line-height: 20px;
  margin-bottom: 18px;
  display: block;
}
