.tableBody tr:hover {
  outline: 1px solid var(--primary-color);
}

.tableBody tr:nth-child(odd) {
  background-color: var(--athens-gray);
}

.tableBody td {
  padding: 10px 12px;
  text-align: left;
  font-size: 14px;
}

.tableBody td:first-child {
  padding-left: 24px;
}

.tableBody td:last-child {
  padding-right: 24px;
}
