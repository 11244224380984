.root {
  margin: 21px 21px 123px;
}

h3.header {
  padding: 0 15px;
  font-size: 25px !important;
  font-weight: 300 !important;
  line-height: 44px;
  margin: 0px 0px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  order: 2;
}

.resetFilters {
  font-size: 17px;
  float: right;
  color: var(--boulder);
  margin-right: 24px !important;
}

.counter {
  color: var(--boulder);
}

.content {
  padding: 30px 15px
}

.container {
  display: flex;
  width: 100%;
}

.main {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 15px;
}

.search {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--shark);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 36px;
  text-transform: uppercase;
}

.partition {
  margin-bottom: 20px;
}

.partition :global(.react-select__value-container) {
  min-height: 33px;
}

.radioField {
  border: none;
  padding: 0;
}

.searchField {
  flex-grow: 1;
  margin-right: 12px;
  flex-basis: 570px;
  flex-shrink: 1;
}

.sortField {
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 200px;
  display: none;
}

.wrapper {
  padding: 30px 15px;
  width: 100%;
  overflow: visible;
}

.headline {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.mobileSort {
  display: block;
}

.options {
  display: none;
}

.searchField  :global(.react-select__option) {
  border: 1px solid var(--alto);
  border-bottom: 1px solid transparent;
  background-color: var(--wild-sand);
}

.searchField  :global(.react-select__option):first-child {
  border-top: 1px solid transparent;
}

.searchField  :global(.react-select__option.react-select__option--is-focused) {
  background-color: white;
  border: 1px solid var(--pacific-blue);
}

.searchFocused .header,
.searchFocused .filters,
.searchFocused .message,
.searchFocused .list {
  opacity: .1;
}

.list {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.checkBoxes :global(.grommetux-check-box__label) {
  min-height: 24px;
  height: auto;
}

.pagination {
  margin: 20px 0;
}

.currentPage {
  margin: 0 20px;
}

.tab {
  max-width: 100%;
  flex-grow: 1;
}

.actions {
  display: flex;
  align-items: center;
  margin: 70px 0 40px 0;
}

.action {
  display: flex;
  align-items: center;
}

.action:not(:first-child) {
  margin-left: 30px;
}

.checkboxLabel {
  margin-left: 12px;
}

.loader {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.numberProfiles {
  margin-left: 8px;
}

.tooltip {
  max-width: 370px;
  opacity: 1;
}

.tooltip :global(.rc-tooltip-inner) {
  background-color: white;
  color: #333333;
  line-height: 20px;
  padding: 16px;
}

.tooltip :global(.rc-tooltip-arrow) {
  display: none;
}

@media (min-width: 1024px) {
  .side,
  .sortField {
    flex-grow: 0;
    width: 200px;
    display: block;
  }

  .side {
    flex-shrink: 0;
    flex-basis: 336px;
    padding-right: 30px;
    border-right: 1px solid var(--gallery);
  }

  .search {
    justify-content: flex-end;
    flex-direction: initial;
  }

  .filters,
  .headline {
    display: none;
  }

  .mobileSort {
    display: none;
  }

  .overlay {
    display: block;
    position: relative;
    z-index: 0;
  }

  .wrapper {
    padding: 0;
    width: 100%;
    overflow: visible;
  }

  .options {
    padding-bottom: 11px;
    padding-top: 10px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
  }

  .main {
    padding-right: 0;
    padding-left: 32px;
    width: 100%;
  }

  .searchField {
    max-width: 570px;
  }

  h3.header {
    order: initial;
  }

  .list {
    text-align: start;
  }

  .searchFocused .header,
  .searchFocused .filters,
  .searchFocused .message,
  .searchFocused .list {
    opacity: 1;
  }

  .side :global(.react-select__value-container) {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .side :global(.react-select__indicators) {
    flex-shrink: 0;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 26px 0 21px 0;
  }
}

.infoAlert {
  display:flex;
  border: 1px solid var(--salmon);
  color: var(--salmon);
  flex-grow: 1;
  padding: 19px;
  height: 48px;
  margin: 22px 0;
}

.infoAlert h4 {
  display:flex;
  align-items: center;
  justify-content: center;
  color: var(--salmon);
  margin: 0px;
}

.infoAlert h4 svg {
  stroke: var(--salmon);
  margin-right: 6px;
}

.profilesList {
  margin-top: 22px;
  border-top: 1px solid var(--gallery);
}

.searchKinds {
  display: flex;
}

.searchKinds button {
  flex-grow: 1;
  border-radius: 0px;
}

.searchKinds button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.searchKinds button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
