.emailSuffixes {
  width: 100%;
}

.emailSuffixes > *:not(:last-child) {
  margin-bottom: 12px;
}

.addButton {
  text-decoration: underline;
}
