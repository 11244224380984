.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb {
  font-size: 18px !important;
  font-weight: 300 !important;
  color: #7A7A7A !important;
  letter-spacing: -0.5px;
  text-decoration: none;
  margin-right: 25px;
  position: relative;
}

.breadcrumb:hover {
  text-decoration: underline;
}

.breadcrumb::after {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  background-image: url(./images/doubleNext.svg);
  background-repeat: no-repeat;
  position: absolute;
  top: 10px;
  right: -18px;
  background-size: 100%;
}

.breadcrumb:last-child::after {
  display: none;
}
