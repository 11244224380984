.status {
  position: relative;
}

.status::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 4px 0px;
  background: red;
  width: 4px;
  height: auto;
}

.draft::before {
  background: var(--lemon);
}

.published::before {
  background: var(--malachite);
}
