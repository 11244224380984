.tabs div[role="tablist"] {
  border-bottom: 1px solid var(--iron);
}

.tabs button[role="tab"] {
  padding: 0px;
  flex: 1 1;
  text-align: center;
}

.tabs button[role="tab"] div {
  border-color: transparent;
  border-width: 4px;
  margin: 0;
}

.tabs button[role="tab"]:hover div {
  border-color: var(--iron);
}

.tabs button[role="tab"] span {
  color: var(--boulder);
  font-size: 16px;
}

.tabs button[aria-selected="true"] span {
  color: var(--mine-shaft);
}

.tabs button[aria-selected="true"] div {
  border-color: var(--primary-color);
}

@media (min-width: 1024px) {
  .tabs button[role="tab"] {
    min-width: auto;
  }

  .tabs div[role='tabpanel'] {
    padding: 0px 0px;
  }
}
