.horizontalForm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 95px;
  min-height: 63px;
  align-items: center;
  position: relative;
  padding-top: 10px;
}

:global(.fieldEditing) + tr {
  background-color: #F4F5F7;
}

:global(.fieldEditing) + tr .table:after {
  content: '';
  display: block;
  width: 100%;
  height: 5px;
  background-color: #00000012;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.12);
}

.table {
  width: 100% !important;
  margin-bottom: 0 !important;
  position: relative !important;
  padding-top: 15px !important;
}

.table td {
  padding: 0 !important;
}

.table th {
  border: none !important;
}

.controls button {
  margin-left: 15px !important;
  margin-right: 0px !important;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table td.input {
  padding-right: 15px !important;
}

.table td.input input {
  height: inherit;
  margin-bottom: 0 !important;
  background: #fff;
  font-size: 14px;
  font-weight: 300;
  padding: 6px 12px;
}

.controls {
  display: flex;
  justify-content: center;
}

.controls > div {
  width: 100%;
}

.danger {
  color: var(--salmon);
}
