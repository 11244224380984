.userpic {
  width: auto;
  max-width: 100%;
  display: block;
  margin: auto;
  height: auto;
  object-fit: cover;
  width: 192px;
  border-radius: 50%;
}

.userpic:focus {
  outline: none;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar:hover > .delete {
  display: flex;
}

.avatar {
  width: 192px;
  height: 192px;
  position: relative;
}

.delete {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: white;
  padding: 4px !important;
  border: 1px solid var(--dove-gray);
  border-radius: 4px;
  display: none;
  justify-content: center;
  padding: 0px;
  height: 27px;
  width: 27px;
}

.delete > svg {
  height: 18px;
  width: 18px;
}
