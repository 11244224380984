.root {
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-right: 10px;
  margin-bottom: 10px;
}

.item:last-child {
  margin-right: 0;
}
