.fields {
  margin-top: 36px;
  width: 400px;
}

.fields > *:not(:last-child) {
  margin-bottom: 12px;
}

.textarea {
  height: 280px;
  max-height: 280px;
}

.downloadMetaButton {
  width: 100%;
}
