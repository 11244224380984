.mercuryColor {
  color: var(--mercury);
}

.orangeColor {
  color: var(--orange);
}

.primaryColor {
  color: var(--primary-color);
}

.salmonColor {
  color: var(--salmon);
}

.mineShaftColor {
  color: var(--mine-shaft);
}

.malachiteColor {
  color: var(--malachite);
}

.doveGrayColor {
  color: var(--dove-gray);
}
