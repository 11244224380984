.tabs,
.root {
  display: flex;
  width: 100%;
}

.tabs div[role="tablist"] {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
}

.tabs div[role="tabpanel"] {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.tabs div[role="tablist"] button span {
  font-size: 14px;
}

.tabs div[role="tablist"] button[role="tab"] {
  padding-right: 24px;
}

.tabs div[role="tablist"] button[role="tab"] div {
  margin: 0;
  border-width: 4px;
}

.tabs div[role="tablist"] button[aria-selected="false"] span {
  color: var(--mine-shaft);
}

.tabs div[role="tablist"] button[aria-selected="false"] div  {
  border-color: transparent;
}
