.root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.success {
  padding: 16px 19px;
  margin-bottom: 20px;
}

.success h4 {
  font-weight: bold;
  margin-top: 0px;
}
