.root {
  display: flex;
  flex-wrap: wrap;
}

.criteria {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 4px 0;
  width: 50%;
}

.checkIcon {
  background-color: var(--japanese-laurel);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkIconDisabled {
  background-color: var(--silver-chalice);
}

.checkMark {
  stroke: #FFFFFF;
  width: 20px;
}
