.actions {
  margin-bottom: 40px;
}

.actionsList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.title {
  display: flex;
  color: #444444;
  font-size: 18px;
  line-height: 23px;
  margin: 0px 4px 13px 4px;
  justify-content: space-between;
}

.alertProjectsPageLink {
  color: inherit;
  text-decoration: underline;
}
