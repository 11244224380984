.common {
  padding: 0 15px;
}

.userName {
  color: var(--shark);
  font-size: 28px;
  letter-spacing: -0.38px;
  line-height: 34px;
  margin-bottom: 15px;
}

.list {
  display: flex;
  flex-direction: column;
}

.counter {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  margin-bottom: 20px;
}

.counter:last-child {
  margin-right: 0;
}

.message {
  color: var(--gray);
  font-size: 14px;
  line-height: 18px;
}

.number {
  color: var(--tundora);
  font-size: 20px;
  line-height: 25px;
}

.main {
  flex-direction: column;
  border-bottom: 1px solid var(--mercury);
  padding: 0 15px;
  overflow: hidden;
}

.tabTitle {
  height: 40px;
  width: 217px;
  color: var(--shark);
  font-family: "Source Sans Pro";
  font-size: 37px;
  letter-spacing: -0.5px;
  line-height: 44px;
  margin-bottom: 20px;
}

.tabsHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.content {
  padding-top: 20px;
}

.tabs ul {
  margin: 0;
  padding: 0;
}

@media (min-width: 1024px) {

  .common {
    display: flex;
    justify-content: space-between;
    padding: 37px 0 36px 0;
    border-bottom: 1px solid var(--gallery);
    margin-bottom: 20px;
  }

  .userName {
    font-size: 48px;
    letter-spacing: -0.5px;
    line-height: 44px;
    margin-bottom: 0;
  }

  .list {
    flex-direction: row;
  }

  .counter {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 0;
  }

  .main {
    display: flex;
    flex-direction: column;
    margin-bottom: 175px;
    border-bottom: none;
    padding: 0;
  }

  .tabTitle {
    margin-bottom: 9px;
  }

  .tabsHeader {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
