:root {
  --sideSize: 292px;
  --sideMargin: 24px;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

:global(.grommet) .content .text {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 42px;
}

:global(.grommet) .content .title {
  font-size: 48px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 42px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 15px;
  text-align: center;
}

:global(.grommet) .content .button {
  font-size: 18px;
  padding: 6px 45px;
}

.grantAccess {
  display: block;
}

.summary {
  flex-grow: 1;
}

.summary ul {
  list-style-type: circle;
  margin-left: 20px;
  padding: 0;
}

.summary ul li {
  max-width: none;
  margin-bottom: 28px;
  font-size: 16px;
  line-height: 22px;
}

.summary h3 {
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 600;
}

.summary h3:first-child {
  margin-top: 0;
}

.summary section {
  border-top: 1px solid var(--mercury);
  padding: 0;
}

.summary section:last-child {
  border-bottom: 1px solid var(--mercury);
}

.summary section:last-child,
.summary p:last-child {
  margin-bottom: 0;
}

.summary p {
  font-size: 16px;
  line-height: 22px;
}

.importantText {
  padding: 0 16px;
}

.sticky {
  display: none;
  position: sticky;
  max-height: 0px;
  overflow: visible;
  top: 0;
  z-index: 2;
}

.visible {
  display: block;
}

em:global(.keywordHighlights) {
  background-color: #FFFF00;
  font-style: normal;
}

:global(.changesHighlights) {
  background-color: rgba(121, 205, 229, 0.40);
  font-style: normal;
}

:global(.maskedText) {
  filter: blur(3px);
}

.hideChangesHighlights :global(.changesHighlights) {
  background-color: transparent;
  font-style: normal;
}

.guide {
  display: inline-block;
  max-width: 400px;
  white-space: break-spaces;
}

.guideCheckbox {
  margin-top: 24px;
}

.guideAnchor {
  color: var(--athens-gray);
  text-decoration: underline;
}

.guideAnchor:hover {
  color: var(--athens-gray);
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .main {
    padding: 0 15px;
    text-align: left;
  }

  .side {
    padding: 0px;
    margin-right: 24px;
    flex-shrink: 0;
    align-self: flex-start;
    box-sizing: border-box;
    width: var(--sideSize);
  }

  .summary {
    border: 1px solid var(--alto);
  }

  .summary section {
    margin-bottom: 32px;
  }

  .personInfo {
    display: flex;
    margin-bottom: 20px;
  }

  .summary section {
    border-top: none;
  }

  .summary section:last-child {
    border-bottom: none;
  }

  .importantText {
    padding: 0;
    margin-top: 0;
    text-align: right;
    font-size: 14px;
  }

  .sticky {
    display: none;
    margin-left: calc(var(--sideSize) + var(--sideMargin));
  }

  .visible {
    display: block;
  }

  .noSidebar .sticky {
    margin-left: 0;
  }
}
