.customLegend {
  justify-content: center;
  display: flex;
}

.legendItem {
  display: flex;
  margin: 5px;
}

.line {
  width: 30px;
  height: 5px;
  margin: 11px 5px 5px;
  border: none;
  border-top: 5px solid white;
}

.dashed {
  border-top-style: dashed;
}
