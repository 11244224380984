.sampleProfilesBlock {
  display: flex;
  align-items: center;
}

.container {
  width: 100%;
  padding: 0 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  padding: 0 15px;
  align-items: flex-start;
  margin-top: 32px;
  margin-bottom: 40px;
}

.buttonRequestFreeProfile {
  display: block;
  font-size: 1.4rem;
  background-color: #fb652d;
  border: none;
  border-radius: 6px;
  color: #ffe8e1;
  margin-top: 30px;
}

@media (min-width: 1024px) {
  .header {
    flex-direction: row;
    padding: 0;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-between;
  }

  .sampleProfilesBlock {
    align-items: flex-start;
  }
}
