.header {
  margin-bottom: 25px;
  padding-top: 30px;
  border-top: 1px solid var(--gallery);
}

.title {
  color: var(--mine-shaft);
  font-size: 24px;
  line-height: 30px;
  margin-right: 20px;
}

.value {
  color: var(--tundora);
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
}

.root table {
  border: 1px solid var(--iron);
}

.root th {
  color: var(--boulder);
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 6px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid var(--iron);
}

.root td {
  border-bottom: 1px solid var(--iron);
}

.root td span {
  font-size: 12px;
}
