.search {
  min-height: 59px;
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;
}

.suggestContainer {
  position: absolute;
  background-color: var(--wild-sand);
  z-index: 2000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  left: 0;
  right: 0;
}

.suggest a {
  color: var(--mine-shaft);
}

.suggest a:hover {
  cursor: pointer;
  text-decoration: none;
  color: var(--mine-shaft);
}

.results {
  min-height: 59px;
  background-color: var(--wild-sand);
  padding: 10px 21px;
  color: var(--pacific-blue);
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  border: 1px solid var(--alto);
  border-top: medium none;
  display: flex;
  align-items: center;
}

.search .input {
  width: 100%;
  border-radius: 0;
  border: none;
  outline: none;
  padding-right: 0;
  padding: 7px 12px;
  min-height: 57px;
  font-family: 'Source Sans Pro', sans-serif;
}

.search .input:focus {
  padding: 7px 12px;
  padding-right: 0;
}

.searchPlace {
  display: flex;
  border: 1px solid var(--alto);
  align-items: center;
  padding-right: 15px;
  min-height: 59px;
  border-left: none;
  border-right: none;
}

.search .input::placeholder {
  color: var(--boulder);
  font-size: 14px;
  line-height: 24px;
}

.linkToAllProfiles {
  white-space: initial;
}

.linkToAllProfiles a {
  color: var(--primary-color);
}

.linkToAllProfiles a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .search {
    max-width: 384px;
    margin-right: 25px;
    min-height: 40px;
  }

  .searchPlace {
    min-height: 40px;
    border: 1px solid var(--alto);
  }

  .search .input {
    width: 100%;
    border-radius: 0;
    border: none;
    padding-right: 0;
    padding: 7px 12px;
    min-height: 38px;
  }

  .search .input:focus {
    padding: 7px 12px;
    padding-right: 0;
  }

  .results {
    min-height: 41px;
    display: block;
  }

  .suggestContainer {
    overflow: visible;
  }

  .suggest {
    min-width: 280px;
    background-color: var(--wild-sand);
  }
}
