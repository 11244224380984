.modal {
  min-width: 500px;
  max-width: 500px;
}

.header {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: var(--tundora);
  padding: 24px 24px 11px 24px;
}

.content {
  padding: 0px 24px 24px 24px;
}

.inputLabel {
  font-size: 12px;
  line-height: 27px;
  font-weight: 600;
}

.actions {
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
}

.save {
  padding: 7px 45px 10px 45px;
}
