.root {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 32px 24px 0px 32px;
}

.avatar {
  margin-top: 4px;
}

.info {
  margin-left: 16px;
}

.header {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

.description {
  margin-top: 4px;
}
