.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

.title {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 40px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
}

.loader .loaderImg {
  max-width: 200px;
}
