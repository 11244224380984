.profilesInfo {
  margin-bottom: 30px;
}

.header {
  color: var(--mine-shaft);
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 17px;
}

.counters {
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-bottom: 40px;
  width: 100%;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.number {
  color: var(--dove-gray);
  font-size: 34px;
  font-weight: 600;
  letter-spacing: -1.8px;
  line-height: 42px;
  text-align: center;
}

.label {
  font-size: 18px;
}

@media (min-width: 1024px) {
  .profilesInfo {
    margin-bottom: 23px;
  }

  .item {
    margin-right: 88px;
    margin-bottom: 0;
    width: auto;
  }
}
