.root {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #DEE0E3;
}

.gridList > div {
  display: flex;
  flex-flow: row wrap;
}

.project {
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex: 0;
  flex-direction: column;
}

.savedSearch, .savedSearch:hover {
  border: none;
  box-shadow: none;
}

.spinner {
  display: flex;
  flex:  0 0 20%;
}
