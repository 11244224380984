.root {
  padding-top: 32px;
}

.info {
  padding-top: 32px;
}

.chart {
  padding-top: 25px;
}

.markedInfo {
  color: var(--primary-color);
  font-weight: 600;
}
