.form {
  display: flex;
}

.leftColumn {
  width: 400px;
  margin-right: 48px;
}

.rightColumn {
  flex: 1;
  margin-right: 48px;
}
