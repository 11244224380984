.tableHeadCell > .tableHeadCellButton {
  color: inherit;
  line-height: inherit;
  white-space: inherit;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
}

.tableHeadCellButton {
  display: flex;
  align-items: center;
  flex: 0;
}

.icon {
  margin-left: 4px;
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}
