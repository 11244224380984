.message {
  font-size: 18px;
}

.anotherFile {
  font-size: 18px;
  color: #00A0CE;
}

.success,
.error,
.validating {
  text-align: center;
  padding-top: 21px;
}
