.modal {
  min-width: 100%;
  padding: 30px 10px;
}

.title {
  color: var(--tundora);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}

.description {
  font-size: 16px;
  color: var(--tundora);
}

.error {
  color: var(--salmon);
  font-size: 11px;
}

.description {
  margin-bottom: 17px;
  text-align: center;
}

.description p {
  margin-top: 0;
}

.description p:last-child {
  margin-bottom: 0;
}

.action {
  margin-bottom: 16px;
}

.phoneNumber {
  white-space: nowrap;
}

.phoneNumber::before {
  content: " ";
}

.resendVerificationCodeLink {
  font-weight: bold;
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .modal {
    width: 820px;
    padding: 30px 100px;
  }
}
