.root {
  display: flex;
  height: 100%;
  margin-left: -24px;
  margin-top: 8px;
  flex-grow: 1;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 840px;
}

.selectContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.teamTitle {
  color: #333333;
  font-size: 14px;
  line-height: 18px;
  margin-right: 20px;
}

.teamSelect {
  flex-grow: 1;
}

.teamSelect > div {
  min-height: 40px;
}

.teamSelect :global(.filter__single-value) {
  padding-bottom: 1px;
  width: auto;
  max-width: 85%;
}

.chartsGrid {
  display: flex;
  flex-wrap: wrap;
}

.item {
  flex-basis: calc(50% - 10px);
  flex-shrink: 0;
  margin-right: 20px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
}

.item:nth-child(2n) {
  margin-right: 0;
}
