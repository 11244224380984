.user {
  background-color: var(--athens-gray);
  padding: 24px;
  margin-bottom: 16px;
}

.user:last-child {
  margin-bottom: 0;
}

.userContent {
  display: flex;
}

.userSelector {
  margin-right: 24px;
  align-items: center;
  display: flex;
}

.userInfo {
  width: 100%;
}

.userInfoItem {
  display: flex;
  padding: 12px 24px;
  width: 100%;
  align-items: center;
}

.userInfoItem:nth-child(even) {
  background-color: white;
}

.userInfoItem.error {
  background-color: #ffd8d8;
}

.userInfoItemLabel {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  width: 70px;
  padding-right: 12px;
}

.userInfoItemContent, .userInfoItemError {
  font-size: 12px;
  padding-left: 12px;
}

.userInfoItemError {
  color: red;
}
