.title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #212529;
  margin-bottom: 8px;
}

.listItem:last-child {
  border-bottom: 1px solid #e7e7e7;
}

.content {
  background: #f9fafb;
  padding: 15px;
  position: relative;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content span {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: #333333;
}
