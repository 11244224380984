.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:global(.grommet) .root .text {
  font-size: 36px;
  font-weight: bold;
  margin-top: 0;
  text-align: center;
}

.header {
  padding-left: 20px;
  padding-top: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 15px;
  text-align: center;
}

@media (min-width: 1024px) {
  .main {
    padding: 0 15px;
    text-align: left;
  }
}
