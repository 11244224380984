.root {
  display: flex;
  background-color: var(--athens-gray);
  width: calc(50% - 15px);
  margin: 0px 0px 30px 0px;
  align-items: flex-start;
  padding: 24px 29px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 34px;
  flex: 1 1 auto;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}

.title {
 color: var(--oslo-gray);
 font-size: 14px;
 line-height: 19px;
 flex-grow: 0;
 min-width: 70px;
}

.content {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  display: block;
  margin-left: 6px;
}

.founded {
  font-size: 14px;
  color: var(--pacific-blue);
  line-height: 19px;
  font-weight: 600;
  display: block;
}

.button {
  margin-top: 18px !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 5px !important;
  max-width: 100px !important;
  padding: 3px 16px !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #E7E7E7;
  margin-top: 8px;
}

.profilesStatistics {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.profilesCount {
  padding-top: 8px;
  margin-right: 8px;
}

.avatar {
  flex: 0 0 auto;
}
