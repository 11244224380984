.root {
  display: flex;
  align-items: center;
  min-height: 59px;
  border: 1px solid var(--gallery);
  border-top: 1px solid var(--wild-sand);
  padding-left: 20px;
  padding-right: 20px;
}

.root:hover {
  background: #fff;
  border: 1px solid var(--pacific-blue);
  text-decoration: none;
}

.userpic {
  width: 38px;
  height: auto;
  object-fit: cover;
  display: block;
}

.text {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  flex: 1 1 auto;
  min-width: 0;
  width: 100%;
}

.name {
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.work {
  color: var(--boulder);
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.image {
  margin-right: 12px;
  border-radius: 50%;
  width: 28px;
  height: auto;
  overflow: hidden;
  display: block;
  max-width: 28px;
  max-height: 28px;
  min-width: 28px;
}

.separator {
  display: none;
}
