.root {
  display: flex;
  flex-direction: column;
}

.projects {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.actions {
  display: flex;
  justify-content: center;
}

.button {
  font-size: 18px !important;
}

@media (min-width: 1024px) {
  .projects {
    display: flex;
    flex-direction: row;
  }
}
