.header {
  font-weight: 400;
}

.details {
  margin-top: 16px;
  font-weight: 400;
}

.fileColumnsInformation {
  margin-top: 16px;
  list-style-type: none;
}

.exampleImage {
  margin: 30px 0 96px;
}
