.title {
  font-size: 14px;
  color: var(--boulder);
  margin-bottom: 16px;
}

.container {
  display: flex;
  padding: 8px;
  border: 1px solid var(--iron);
  flex-wrap: wrap;
  justify-content: flex-start;
}

.criteria {
  max-width: 350px;
  padding: 8px;
  width: 33%;
}

.criteriaType {
  font-size: 12px;
  color: var(--oslo-gray);
}

.criteriaValue {
  font-size: 14px;
}
