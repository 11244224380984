.csvTitle {
  display: flex;
}

.checkIcon {
  margin-left: 20px;
}

.correctIcon {
  color: #00CE34;
  height: 12px;
  width: 12px;
}

.wrongIcon {
  color: #FA652C;
  height: 12px;
  width: 12px;
}
