.input {
  padding: 0px;
  box-sizing: border-box;
  min-width: 100px;
  max-width: 100%;
}

.input:focus {
  border-width: 1px;
}

.errors {
  border: 1px solid var(--salmon);
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  margin-left: 6px;
}

.icon svg {
  stroke: var(--primary-color);
  color: var(--primary-color);
}

.icon path {
  stroke-width: 1px;
}
