tr.loaderRow:hover {
  outline: none;
}

tr.loaderRow > td {
  padding: 24px;
}

.loader {
  height: 24px;
  width: 24px;
  margin: 0 auto;
}
