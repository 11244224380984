.field {
  display: flex;
  margin-top: 14px;
  align-items: center;
}

.fieldTitle {
  text-transform: none;
  font-size: 16px;
  margin-left: 11px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 24px 0;
}

.filterIcon {
  margin-left: 8px;
  margin-top: 0.2rem;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.executivesIcon {
  background-color: #00a2d2;
}

.directorsBoardIcon {
  background-color: #fa652c;
}

.badge {
  color: #fff;
  background-color: #C6A957;
  border: 1px solid #FFFFFF;
  border-radius: 2px;
  padding: 0 4px;
  font-size: 8px;
  margin-left: 8px;
  margin-top: 0.2rem;
  line-height: 14px;
}
