.board {
  display: flex;
  min-width: 100%;
  flex-direction: row;
  height: 600px;
  overflow: hidden;
}

.board > div:first-child {
  margin-left: 0;
}

.board > div:last-child {
  margin-right: 0;
}
