.sections {
  display: flex;
  margin-bottom: 20px;
}

.sections > *:not(:last-child) {
  margin-right: 48px;
}

.sections > * {
  width: 400px;
}

.inputTotalSuffix {
  white-space: nowrap;
  color: var(--oslo-gray);
}

.actions > *:not(:last-child) {
  margin-right: 16px;
}
