.root {
  cursor: pointer;
  position: relative;
  z-index: 10;
  word-break: break-word;
}

.root a {
  color: var(--primary-color);
  text-decoration: underline;
}

.root a:hover {
  text-decoration: none;
}

.root:hover {
  border: solid 1px var(--tundora);
}

.root:hover .panel {
  display: flex;
}

.panel {
  background: var(--primary-color);
  color: white;
  position: absolute;
  top: -1px;
  width: 40px;
  left: 100%;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  display: none;
}

.action {
  margin-bottom: 10px;
}

.action:last-child {
  margin-bottom: 0;
}

.text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--tundora);
}

.text {
  margin: 0 0 10px;
  max-width: 100%;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.text p:last-child {
  margin: 0;
}

.text ul {
  margin-left: 20px;
  margin-top: 18px;
  padding: 0;
}

.text pre {
  white-space: pre-wrap;
}

.footerText {
  opacity: 0.6;
  color: var(--mine-shaft);
  font-size: 8px;
  letter-spacing: 1.14px;
  line-height: 10px;
  padding: 0 21px;
  background-color: var(--iron);
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.headline {
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
}

.text ins {
  border-bottom: none;
  text-decoration: underline;
}

.reviewTitle {
  font-size: 16px;
  margin-left: 12px;
  flex-shrink: 0;
  font-weight: normal;
}

.warningFrame {
  border-color: var(--salmon);
  padding-top: 10px;
  background: rgba(255, 133, 107, 0.2);
}

.warningFrame:hover {
  border-color: var(--salmon);
}

.warningBlock {
  font-size: 10px;
  color: white;
  letter-spacing: 1.14px;
  line-height: 10px;
  padding: 5px 21px;
  background-color: var(--salmon);
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
}
