/* Source: https://github.com/grommet/grommet/blob/v1.11.0/src/scss/grommet-core/_objects.headline.scss */

.grommetux-headline {
  font-weight: 300;
  margin-bottom: 24px;
  max-width: 100%;
}

.grommetux-headline--margin-none {
  margin-top: 0;
  margin-bottom: 0;
}

.grommetux-headline--margin-small {
  margin-top: 12px;
  margin-bottom: 12px;
}

.grommetux-headline--margin-medium {
  margin-top: 24px;
  margin-bottom: 24px;
}

.grommetux-headline--margin-large {
  margin-top: 48px;
  margin-bottom: 48px;
}

.grommetux-headline--align-start {
  text-align: left;
}
.grommetux-headline--align-start html.rtl & {
  text-align: right;
}

.grommetux-headline--align-center {
  text-align: center;
}

.grommetux-headline--align-end {
  text-align: right;
}
.grommetux-headline--align-end html.rtl & {
  text-align: left;
}

.grommetux-headline--small {
  font-size: 24px;
}

.grommetux-headline--strong {
  font-weight: 700;
}
