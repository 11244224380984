.container {
  width: 100%;
  position: relative;
}

.chartContainer {
  overflow-x: auto;
  overflow-y: hidden;
}

.container :global(.recharts-surface) {
  overflow: initial;
}

.bar:hover {
  cursor: pointer;
}
