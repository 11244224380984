.root {
  padding: 20px;
  cursor: pointer;
}

.title {
  color: #444;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 20px;
}

.main {
  display: flex;
}

.chart {
  width: 165px;
}

.info {
  margin-left: 20px;
}

.counter {
  color: #444;
}

.header {
  font-size: 14px;
  letter-spacing: -0.22px;
  line-height: 18px;
}

.count {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.container {
  border: 1px solid #dee0e3;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 9px 7px;
  border-color: transparent transparent #00ce34 transparent;
  margin-left: 5px;
}

.negative {
  border-color: transparent transparent #fa652c transparent;
  transform: rotate(180deg);
}
