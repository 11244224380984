.root {
  padding: 45px 39px 28px 39px;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: baseline;
}

.button {
  margin-right: 19px;
  border: 1px solid #00A0CE;
  border-radius: 5px;
  padding: 0;
  min-width: auto;
  padding: 3px 16px;
}

.button .counter {
  color: #01A1CD;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  padding: 26px 0 21px 0;
}

.match {
  display: flex;
  align-items: baseline;
}

.notFound {
  text-align: center;
  margin-left: 29px;
  line-height: 17px;
}

.select :global(.select__value-container) {
  line-height: 1.3;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
}

.loader .loaderImg {
  max-width: 200px;
}

.viewDetails {
  justify-content: center;
  display: flex;
  padding-bottom: 17px;
}
