.footer {
  background-color: var(--outer-space);
  color: #fff;
}

.footerContent {
  padding-top: 20px;
  padding-bottom: 35px;
}

.socialIcons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.socialIcon {
  color: #fff !important;
  margin-right: 17px;
  padding: 0 !important;
  height: 20px;
}

.socialIcon:visited {
  color: #fff !important;
}

.socialIcon:hover {
  color: #fff !important;
}

.nav {
  font-size: 13px;
  color: #fff;
  margin-bottom: 12px;
  text-align: center;
  margin-bottom: 25px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.copyright {
  font-size: 10px;
  color: var(--silver-chalice);
  letter-spacing: 0.32px;
  text-align: center;
  line-height: 16px;
}

@media (min-width: 1024px) {
  .footer {
    min-height: 110px;
    display: flex;
  }

  .footerContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0;
  }

  .nav {
    text-align: left;
    font-size: 10px;
    margin-bottom: 12px;
  }

  .socialIcons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  .copyright {
    font-size: 10px;
    letter-spacing: 0.32px;
    text-align: right;
  }
}
