.root {
  width: 100%;
  padding: 0px 15px 32px;
}

.button,
.getAlertsButton,
.removeAlertsButton {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  width: 100%;
}

.getAlertsButton {
  color: #fff;
  font-weight: 600;
}

.removeAlertsButton {
  color: var(--tundora);
}

@media (min-width: 1024px) {
  .root {
    width: 292px;
    padding: 0px 0px 32px;
  }
}
