.cancel {
  border-color: var(--burnt-sienna) !important;
}

.cancel:hover {
  box-shadow: 0px 0px 0px 2px var(--burnt-sienna) !important;
}

.deliver {
  border-color: var(--mantis) !important;
}

.deliver:hover {
  box-shadow: 0px 0px 0px 2px var(--mantis) !important;
}

.label {
  font-size: 14px;
  color: var(--oslo-gray);
}

.column {
  flex-grow: 1;
  flex-basis: 50%;
}

.item {
  min-height: 45px;
}

.cancelationReason {
  display: block;
}

a.cancelationConfirmationLink, a.cancelationConfirmationLink:visited {
  margin-left: 6px;
  text-decoration: none;
  box-shadow: none;
}

.cancelationConfirmation {
  max-width: 400px;
}

.error {
  color: var(--salmon);
}
