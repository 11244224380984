.root {
  color: var(--pacific-blue);
}

.root > svg {
  stroke: var(--pacific-blue);
}

.root:hover {
  color: var(--pacific-blue);
}
