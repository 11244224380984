.root {
  display: flex;
  flex-direction: column;
}

.link, .link:hover {
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 8px 0px;
}

.link:hover {
  text-decoration: underline;
  text-decoration-color: white;
}
