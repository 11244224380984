.headLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add {
  color: var(--primary-color) !important;
  font-size: 18px !important;
  letter-spacing: 0.37px !important;
}

.editorNote:not(:first-child) {
  margin-top: 32px;
}

.spinner {
  margin: 40px auto;
}

.scrollContainer {
  max-height: 310px;
  overflow-y: auto;
}

.filledScrollContainer {
  margin-top: 32px;
}

@media (min-width: 1024px) {
  .add {
    font-size: 14px !important;
  }
}
