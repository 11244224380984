.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info {
  display: flex;
  width: 100%;
}

.title {
  font-weight: bold;
  line-height: 23px;
}

.missing {
  font-size: 16px;
  line-height: 20px;
  color: var(--silver-chalice);
}

.parent {
  display: flex;
  width: 100%;
}

.parentLink {
  flex: 1 1 100%;
  color: var(--primary-color);
  font-size: 16px !important;
  font-weight: 300 !important;
}

.action {
  width: 14px;
  height: 14px;
  margin-left: 15px !important;
}

.action svg {
  width: 14px;
  height: 14px;
}
