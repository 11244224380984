.fileStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ebebeb;
  min-width: 350px;
  min-height: 160px;
}

.fileStatus h3 {
  font-weight: 400;
}

.invalid {
  border-color: var(--orange);
}
