.root {
  min-width: 366px;
  min-height: 324px;
  text-align: center;
}

.root :global(h3) {
  font-size: 24px;
  color: #444;
  margin: 0;
  text-align: center;
  padding-top: 34px;
  margin-bottom: 24px;
}

.legend {
  margin-right: 6px;
  width: 3px;
}

.actions {
  text-align: center;
  margin-bottom: 24px;
}

.item {
  font-size: 14px;
  margin-right: 28px;
  text-decoration: none;
  border: none;
  box-shadow: none;
}

.item:last-child {
  margin-right: 0;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
  max-width: 366px;
  justify-content: space-between;
}

.checkboxes label {
  width: 50%;
  margin-bottom: 20px;
}

.checkboxLabel {
  display: flex;
}
