.root {
  display: flex;
}

.item {
  display: flex;
  margin-left: 16px;
  align-items: center;
}

.item:first-child {
  margin-left: 0px;
}

.colorMark {
  height: 15px;
  width: 15px;
}

.text {
  margin-left: 8px;
}
