.projects {
  display: flex;
  flex-direction: column;
}

.projects > a {
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .projects {
    display: flex;
    flex-direction: row;
  }

  .projects > a {
    margin-bottom: 0px;
  }
}
