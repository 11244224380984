.input {
  display: flex;
  flex: 1;
  align-items: center;
  line-height: 1;
}

.input > input {
  width: 100%;
  padding: 0;
  line-height: inherit;
  border-radius: 0;
  border: none;
  outline: none;
  color: var(--mine-shaft);
  font-family: 'Source Sans Pro', sans-serif;
}

.input > input[type=number]::-webkit-outer-spin-button,
.input > input[type=number]::-webkit-inner-spin-button {
  appearance: none;
}

.outlined {
  border: 1px solid var(--mercury);
  padding: 0 8px;
  background: #fff;
}

.outlined > input {
  line-height: 38px;
}

.default > input {
  font-size: 14px;
}
