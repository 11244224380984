.actions {
  margin: 60px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions > *:not(:last-child) {
  margin-right: 16px;
}
