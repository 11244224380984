@media (min-width: 1024px) {
  .contentWrapper {
    max-width: 1210px;
    margin: auto;
    padding: 0 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
