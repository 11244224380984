.root {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background: var(--gallery);
  padding: 16px 24px;
  margin-bottom: 8px;
}

.title {
  display: flex;
  color: var(--tundora);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
}

.userName {
  color: var(--pacific-blue);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.closeButton {
  width: 18px;
  height: 18px;
}

.closeButton svg {
  width: 18px;
  height: 18px;
}

.projectLink {
  color: #838588;
}
