.nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.nav li {
  list-style-type: none;
  white-space: nowrap;
  border-bottom: 1px solid var(--mercury);
  max-width: initial;
  margin-bottom: 0;
  white-space: normal;
}

.nav li a {
  min-height: 64px;
  align-items: center;
  display: flex;
  margin-bottom: 0;
  white-space: normal;
  padding: 0 12px;
  font-weight: 300;
  color: var(--mine-shaft);
  text-decoration: none;
}

.nav li a:hover {
  text-decoration: underline;
}

.notificationBadge {
  margin: -11px -3px 0px 0;
  width: 6px;
  height: 6px;
  background-color: var(--persimmon);
  border-radius: 50%;
}

@media (min-width: 1024px) {
  .nav > ul {
    flex-direction: row;
  }

  .nav > ul > li {
    margin-right: 23px;
    font-size: 14px;
  }

  .nav ul li:last-child {
    margin-right: 0;
  }

  .nav a:visited {
    font-size: 14px;
    color: var(--mine-shaft);
    letter-spacing: -0.2px;
  }

  .nav li a {
    min-height: 0;
    padding: 0;
  }

  .nav li {
    padding: 0;
  }
}
