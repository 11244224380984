.root {
  margin: 0px 40px;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: baseline;
}

.match {
  display: flex;
  align-items: baseline;
}

.button {
  margin-right: 19px !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 5px !important;
  padding: 0 !important;
  min-width: auto !important;
  padding: 3px 16px !important;
}

.button.primary {
  background: #01A1CD;
  color: #fff;
}

.button .counter {
  color: #01A1CD;
}

.button.primary .counter {
  color: #FFFFFF;
  font-weight: bold;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  padding: 26px 0 21px 0;
}

.filters {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.filter {
  display: flex;
  align-items: center;
}

.filter:not(:first-child) {
  margin-left: 30px;
}

.checkboxLabel {
  margin-left: 12px;
}

.tooltip {
  max-width: 370px;
  opacity: 1;
}

.tooltip :global(.rc-tooltip-inner) {
  background-color: white;
  color: #333333;
  line-height: 20px;
  padding: 16px;
}

.tooltip :global(.rc-tooltip-arrow) {
  display: none;
}
