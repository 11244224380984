.root {
  background-color: #fff;
  padding: 6px 15px 28px 28px;
  margin-bottom: 20px;
}

.userPhotos {
  position: relative;
  min-height: 50px;
  margin-bottom: 4px;
}

.userPhotos img, .userPhotos svg {
  border: 3px solid #00CE34;
}

.line {
  width: 100%;
  height: 3px;
  background-color: green;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.item {
  position: absolute;
  width: 0;
  background-color: #fff;
  height: 100%;
  display: flex;
  min-width: 43px;
}

.item:first-child {
  left: 0;
  width: 0;
  justify-content: flex-end;
}

.item:first-child img{
  transform: translateX(2px);
  z-index: 1;
}

.item:last-child {
  right: 0;
  width: 0;
  justify-content: flex-start;
}

.item:last-child img {
  transform: translateX(-2px);
  z-index: 1;
}

.names {
  display: flex;
  align-items: flex-start;
  margin-bottom: 21px;
}

.name {
  color: #444444;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  flex-basis: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.jobTitle {
  font-weight: 400;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.jobTitle:last-child {
  margin-bottom: 0px;
}

.name:last-child {
  text-align: right;
  align-items: flex-end;
}

.profileLabels > div:not(:first-child) {
  margin-top: 8px;
}
