.item {
  display: flex;
  flex: 1 1 40%;
  flex-direction: column;
  max-width: calc(50% - 12px);
  border: 1px solid var(--iron);
  position: relative;
  margin: 0px 24px 24px 0px;
  box-sizing: border-box;
  text-decoration: none;
  min-width: 100%;
}

.item:hover {
  box-shadow: 0 2px 8px 1px rgba(231,231,231,0.68);
  text-decoration: none !important;
}

.item:nth-child(even) {
  margin-right: 0px;
}

.sample {
  position: absolute;
  top: 50%;
  left: 50%;
  text-transform: uppercase;
  transform: translate(-50%, -50%) rotate(-45deg);
  color: var(--primary-color);
  z-index: 0;
  opacity: 0.5;
  font-size: 88px;
  font-weight: 600;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  margin: 0px 24px;
  border-bottom: 1px solid var(--iron);
  letter-spacing: 0;
  font-weight: 600;
}

.info {
  max-width: 100%;
  margin-right: 16px;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
}

.name {
  font-size: 18px;
  line-height: 24px;
  color: var(--orange);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item:hover .name {
  text-decoration: underline;
}

.details {
  font-size: 14px;
  line-height: 20px;
  color: var(--silver);
}

.details > span {
  white-space: nowrap;
}

.actions {
  display: flex;
  height: 24px;
  position: relative;
}

.menu {
  min-width: 170px;
  margin-right: -12px;
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  border: none;
}

.icon svg {
  stroke: var(--primary-color);
  width: 20px;
  height: 20px;
}

.statistics {
  margin: 32px 24px;
  height: 100%;
}

.noStatistics {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--silver);
}

.notOwner {
  color: var(--pacific-blue);
}

@media (min-width: 1024px) {
  .item {
    height: 335px;
    min-width: 460px;
  }

  .header {
    flex: 1 0 auto;
  }

  .statistics {
    flex: 100 0 auto;
    height: initial;
  }
}
