.idleStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.idleStatus > *:not(:last-child) {
  margin-bottom: 12px;
}
