.form {
  display: flex;
}

.field {
  flex-grow: 1;
  padding-right: 12px;
}

.button {
  max-width: 80px;
}
