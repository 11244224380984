.heading {
  font-size: 37px;
  line-height: 34px;
  margin-left: 15px;
}

.tabs div[role="tablist"] {
  margin: 12px 0;
}

.tabs div[role="tablist"] > div {
  flex-wrap: wrap;
}

.tabs div[role='tabpanel'] {
  padding: 0px 30px;
}

@media (min-width: 1024px) {
  .root {
    padding-top: 37px;
  }

  .heading {
    margin-left: 0px;
  }
}
