.item {
  border-bottom: 1px solid var(--mercury);
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  padding-top: 24px;
  text-align: center;
}

.maskedItem {
  background-color: white;
  opacity: .3;
}

.action {
  display: none;
}

.userpic {
  margin-bottom: 30px;
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  display: block;
}

.userpic img {
  width: 100px;
  height: 100px;
}

.userpic svg {
  width: 100px;
  height: 100px;
}

.header {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

a.username, a.username:visited, a.username:active, a.username:hover {
  color: var(--pacific-blue);
  line-height: initial;
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
}

a.usernameMasked, a.usernameMasked:visited, a.usernameMasked:active, a.usernameMasked:hover {
  opacity: .3;
}

.highlights {
  margin: 6px 0;
  border-top: 1px solid var(--gallery);
}

.highlights em {
  background-color: var(--yellow);
  font-style: normal;
}

.highlightsMasked {
  opacity: .3;
}

.highlightsMasked em {
  opacity: .3;
}

.info {
  overflow: hidden;
  width: 100%;
}

.tooltipAnchor:hover {
  color: var(--athens-gray);
}

.labels {
  display: flex;
  align-items: center;
}

.profileLabels > div:not(:first-child) {
  margin-top: 8px;
}

.markButton {
  padding-left: 8px;
}

.boardexLink {
  text-decoration: none;
  color: var(--mine-shaft);
  font-weight: 600;
  display: inline-block;
}

.boardexImage {
  display: block;
  width: 50px;
  height: fit-content;
}

@media (min-width: 1024px) {
  .item {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 31px;
    text-align: left;
  }

  .action {
    display: block;
    width: 25px;
    margin-left: 20px;
    margin-right: 40px;
  }

  .userpic {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .userpicMasked {
    opacity: .3;
  }

  .header {
    flex-direction: row;
    align-items: flex-start;
  }
}
