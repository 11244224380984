.tableContainer {
  max-height: 400px;
  overflow: scroll;
}

.header {
  display: flex;
  margin-bottom: 19.5px;
}

.link {
  color: var(--mine-shaft) !important;
  font-weight: 700 !important;
}
