.root {
  border: 1px solid #E0E2E5;
  border-radius: 4px;
  color: #666666;
  font-size: 12px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.root button {
  border: none;
  padding: 0;
  min-width: auto;
  margin-left: 10px;
  color: #666;
  background: transparent;
}

.root button:hover {
  cursor: pointer;
}

.root button:focus {
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
