.infoAlert {
  border: 1px solid var(--mercury);
  font-size: 15px;
  color: var(--primary-color);
  padding: 16px 19px 28px 22px;
  margin-top: 21px;
  margin-bottom: 35px;
}

@media (min-width: 1024px) {
  .infoAlert {
    font-size: 14px;
    padding: 13px 23px 17px 23px;
    margin-top: 39px;
    margin-bottom: 33px;
  }

  .infoAlert a {
    font-weight: 600;
    color: var(--primary-color);
  }

  .infoAlert a:hover {
    color: var(--primary-color);
  }
}
