.root {
  display: flex;
  overflow: hidden;
  max-width: 100%;
}

.name {
  font-size: 14px;
  line-height: 24px;
  color: var(--orange);
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  white-space: nowrap;
}
