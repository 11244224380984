.shareLink {
  color: var(--pacific-blue);
  font-size: 16px !important;
  font-weight: 300 !important;
}

.shareLink:hover {
  color: var(--pacific-blue);
  text-decoration: none;
}
