.root {
  overflow: hidden;
  position: relative;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: var(--tundora);
  line-height: 20px;
  margin-bottom: 12px;
}

.infoRow {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  margin-bottom: 10px;
}

.infoRow:last-child {
  margin-bottom: 0;
}

.infoKey {
  color: var(--oslo-gray);
  font-size: 12px;
  flex-shrink: 0;
}

.infoVal {
	color: var(--tundora);
	font-size: 12px;
}

.number {
  color: var(--oslo-gray);
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  margin-right: 10px;
  height: 12px;
  height: auto;
  display: flex;
}

.header {
  display: flex;
  align-items: center;
}

.badges {
  margin-left: auto;
  display: flex;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.badge {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  border-radius: 2px;
  color: #fff;
  padding: 0 8px;
}

.badge:not(:last-child) {
  margin-right: 5px;
}

.profileHiddenBadge {
  background-color: var(--orange);
}

.profileF500Badge {
  background-color: var(--mantis);
}
