.self {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
}

.media {
  width: 100%;
}


.video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
  margin-bottom: 20px;
}

.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header {
  margin-bottom: 20px;
}

.notification {
  line-height: 1.6;
}

.form {
  width: 100%;
  padding-bottom: 40px;
}

@media (min-width: 1024px) {

  .self {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 62px 0 229px 0;
  }

  .media {
    order: 2;
    width: auto;
    padding-left: 110px;
    flex-grow: 1;
  }

  .form {
    order: 1;
    padding-right: 150px;
    border-right: 1px solid var(--alto);
    max-width: 456px;
    flex-shrink: 0;
  }

  .overlay {
    display: none;
  }

  .header {
    margin-bottom: 32px;
    line-height: 1;
  }

  .text {
    margin-bottom: 65px;
  }

  .text p > *:first-child {
    margin-top: 0;
  }

  .video {
    margin-bottom: 0;
  }
}

