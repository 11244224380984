.header {
  margin: 38px 0 17px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.title {
  margin: 0 0 30px 0;
  font-size: 16px;
  color: #444;
  line-height: 20px;
}

.chart {
  margin-top: 20px;
  margin-bottom: 61px;
  width: 100%;
}

.chart:last-child {
  margin-bottom: 0;
}

.noData {
  width: 834px;
  height: 300px;
  border: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
}
