.profile {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  flex: 1 48%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  align-self: stretch;
  max-width: 48%;
}

.profile:hover {
  text-decoration: none !important;
}

.header {
  display: flex;
  margin-bottom: 5px;
}

.name {
  color: #04A0C5;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-right: 16px;
}

.work:last-child {
  margin-bottom: 0px;
}

.work {
  margin-bottom: 15px;
}

.position,
.company {
  color: #444444;
  font-size: 15px;
  letter-spacing: -0.22px;
  margin-bottom: 5px;
  word-break: break-word;
}

.company {
  margin-bottom: 0;
}

.info {
  margin-left: 20px;
}

.profileLabels > div:not(:first-child) {
  margin-top: 8px;
}
