.formField {
  margin-bottom: 0px;
}

.organization {
  margin-top: -12px;
  line-height: 16px;
  display: flex;
  font-size: 11px;
  padding: 5px 8px 5px 8px;
  margin-bottom: 12px;
  border: 1px solid var(--iron);
  border-top: none;
}

.organizationPlaceholder {
  color: var(--oslo-gray);
  margin-right: 4px;
}
