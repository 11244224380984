.message {
  color: var(--pacific-blue);
  font-size: 14px;
  line-height: 18px;
  padding: 32px 0;
  margin: 0;
}

.message a {
  color: inherit;
  text-decoration: underline;
  font-weight: 600;
}

.message a:hover {
  text-decoration: none;
}

.message {
  color: var(--pacific-blue);
  font-size: 14px;
  line-height: 18px;
  padding: 32px 0;
  margin: 0;
}

.message a {
  color: inherit;
  text-decoration: underline;
  font-weight: 600;
}

.message a:hover {
  text-decoration: none;
}

.loader {
  margin-top: 30px;
}

.tabs div[role="tablist"] {
  margin-top: 12px;
}
