:root {
  --sideSize: 350px;
  --sideMargin: 24px;
}


.userpic {
  width: 97px;
  height: auto;
}

.userpicContainer {
  max-width: 97px;
  max-height: 97px;
  width: auto;
  display: block;
  overflow: hidden;
  border-radius: 50%;
}

.userpicContainer svg {
  max-width: 97px;
  height: auto;
  max-height: 97px;
  width: auto;
  display: flex;
  overflow: hidden;
}

.left {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  flex-shrink: 0;
  border: 1px solid transparent;
}

.center {
  text-align: center;
  padding: 0 15px;
}

.center h1 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 300;
}

.work {
  margin-bottom: 16px;
  font-size: 16px;
}

.work:last-child {
  margin-bottom: 27px;
}

.info {
  font-size: 14px;
  display: none;
}

.date {
  font-size: 12px;
  line-height: 19px;
  color: var(--boulder);
  margin-bottom: 18px;
}

.link {
  color: var(--pacific-blue);
  font-size: 18px;
  margin-bottom: 7px;
}

.right {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  margin-bottom: 31px;
}

.item {
  display: flex;
  margin-bottom: 11px;
}

.value {
  color: var(--shark);
  font-size: 14px;
  letter-spacing: 0.37px;
  line-height: 19px;
}

.summary {
  flex-grow: 1;
}

.summary ul {
  list-style-type: circle;
  margin-left: 20px;
  padding: 0;
}

.summary ul li {
  max-width: none;
  margin-bottom: 28px;
  font-size: 16px;
  line-height: 22px;
}

.sideHeader {
  font-size: 18px;
  margin-bottom: 25px;
  font-weight: 600;
}

.summary h3 {
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 600;
}

.summary h3:first-child {
  margin-top: 0;
}

.summary section {
  border-top: 1px solid var(--mercury);
  padding: 0;
}

.summary section:last-child {
  border-bottom: 1px solid var(--mercury);
}

.summary section:last-child,
.summary p:last-child {
  margin-bottom: 0;
}

.summary p {
  font-size: 16px;
  line-height: 22px;
}

.summary .wysiwyg {
  margin-bottom: 24px;
}

.summary .wysiwyg p {
  margin-top: 0;
  margin-bottom: 12px;
}

.list {
  line-height: 22px;
  margin: 0;
  list-style-type: none;
  margin-bottom: 22px;
}

.list li {
  font-size: 14px;
}

.socialLink {
  color: var(--boulder);
  margin-right: 10px;
}

.socialLink:visited {
  color: var(--boulder);
}

.socialLink:hover {
  color: var(--pacific-blue);
}

.side iframe {
  height: 950px;
}

.infoAlert {
  border: 1px solid var(--mercury);
  font-size: 15px;
  color: var(--primary-color);
  padding: 16px 19px 28px 22px;
  margin-top: 21px;
  margin-bottom: 35px;
}

.importantText {
  padding: 0 16px;
}

.sticky {
  display: none;
  position: sticky;
  height: 60px;
  border: 1px solid var(--alto);
  top: 0;
  background: #fff;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 22px;
  align-items: center;
  height: 100%;
}

.visible {
  display: block;
  z-index: 10;
}

.avatar {
  margin-right: 15px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: none;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  width: 40px;
  height: auto;
  max-width: 100%;
}

.avatar svg {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.userInfo {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  padding-right: 10px;
}

.commonInfo {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: column;
}

.stickyWork {
  color: var(--mine-shaft);
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  max-width: 100%;
}

.stickyName {
  color: var(--tundora);
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  white-space: nowrap;
  flex-shrink: 0;
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  max-width: 100%;
}

.scroll {
  background-color: var(--iron);
  height: 60px;
  width: 60px;
  border-radius: 0;
}

.scrollContent {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.text {
  display: none;
}

.arrow {
  width: 20px;
  height: 21px;
}

.strong {
  font-weight: bold;
  margin-bottom: 24px;
}

em:global(.keywordHighlights) {
  background-color: #FFFF00;
  font-style: normal;
}

em:global(.maskedText) {
  color: transparent;
  text-shadow: 0 0 7px rgba(0,0,0,0.5);
}

:global(.footerPadding) {
  padding-bottom: 35px;
}

@media (min-width: 1024px) {
  .summary {
    border: 1px solid var(--alto);
  }

  .summary section {
    margin-bottom: 32px;
  }

  .personInfo {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
  }

  .side {
    margin-right: 24px;
    flex-shrink: 0;
    box-sizing: border-box;
    width: var(--sideSize);
  }

  .summary section {
    border-top: none;
  }

  .summary section:last-child {
    border-bottom: none;
  }

  .common {
    display: flex;
    margin-top: 37px;
  }

  .right {
    align-items: flex-end;
    padding: 0;
    margin-bottom: 0;
  }

  .right::after {
    display: none;
  }

  .left {
    width: var(--sideSize);
    margin-right: 24px;
    margin-bottom: 0;
    align-items: flex-start;
    min-height: 244px;
  }

  .info {
    display: block;
  }

  .center {
    flex-grow: 1;
    padding: 0;
    padding-right: 20px;
    text-align: left;
  }

  .userpicContainer {
    max-width: 244px;
    max-height: 244px;
    width: auto;
    display: block;
    overflow: hidden;
    border-radius: 50%;
  }

  .userpicContainer svg {
    max-width: 244px;
    max-height: 244px;
    width: auto;
    display: flex;
    overflow: hidden;
  }

  .center h1 {
    font-size: 37px;
  }

  .infoAlert {
    font-size: 14px;
    padding: 13px 23px 17px 23px;
    margin-top: 39px;
    margin-bottom: 33px;
  }

  .infoAlert a {
    font-weight: 600;
    color: var(--primary-color);
  }

  .infoAlert a:hover {
    color: var(--primary-color);
  }

  .importantText {
    padding: 0;
    margin-top: 0;
    text-align: right;
    font-size: 14px;
  }

  .sticky {
    display: block;
    visibility: hidden;
    margin-left: calc(var(--sideSize) + var(--sideMargin));
  }

  .withoutSidebar .sticky {
    margin-left: 0;
  }

  .avatar {
    display: block;
  }

  .stickyName {
    font-size: 18px;
    line-height: 24px;
  }

  .stickyWork {
    color: var(--tundora);
    margin-left: 30px;
  }

  .commonInfo {
    align-items: center;
    flex-direction: row;
  }

  .scroll {
    color: var(--oslo-gray);
    font-size: 14px;
    line-height: 18px;
    text-indent: initial;
    width: auto;
    height: auto;
    background-color: transparent;
    display: inline-block;
  }

  .scrollContent {
    display: inline-block;
  }

  .container {
    padding: 0 22px;
  }

  .text {
    display: inline;
  }

  .arrow svg {
    width: 7px;
    height: 7px;
  }

  .wrapper {
    padding: 23px;
  }

  .userpic {
    width: 244px;
    height: auto;
  }

  .noSidebar .sticky {
    margin-left: 0;
  }

  :global(.footerPadding) {
    padding-bottom: 0;
  }
}

.visible {
  visibility: visible;
}

.maskedText {
  filter: blur(3px);
}
