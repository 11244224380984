.fields {
  margin-top: 36px;
  width: 500px;
}

.fields > *:not(:last-child) {
  margin-bottom: 12px;
}

.subscriptionDates {
  display: flex;
}

.subscriptionDates > *:not(:last-child) {
  margin-right: 12px;
}

.datePicker {
  height: 28px;
}
