.datepickerLabel {
  display: flex;
  align-items: center;
}

.datepicker {
  display: flex;
  position: static;
  height: 12px;
  margin-left: 8px;
  border: none;
  width: 100px;
}

.datepicker:hover {
  text-decoration: underline;
}

.datepicker input {
  line-height: 0;
  font-size: 10px;
}
