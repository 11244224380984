.root {
  height: 100%;
  position: relative;
}

.root p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.socialLinks {
  margin-left: -12px;
  margin-top: -12px;
  margin-bottom: -12px;
}

.email {
  color: var(--primary-color);
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.email:hover {
  text-decoration: none;
}

.headline {
  display: flex;
  align-items: baseline;
}

.headline > a {
  flex-shrink: 0;
  font-size: 16px;
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: normal;
}

.headline > a:hover {
  text-decoration: none;
}

.warningFrame {
  border-color: var(--salmon);
  padding-bottom: 10px
}

.warningFrame:hover {
  border-color: var(--salmon);
}

.warningBlock {
  font-size: 10px;
  color: white;
  letter-spacing: 1.14px;
  line-height: 10px;
  padding: 5px 21px;
  background-color: var(--salmon);
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
}
