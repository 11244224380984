.VerticalFormField .CustomFormField {
  border: 1px solid var(--iron);
  background: #fff;
}

.VerticalFormField:focus-within .CustomFormField {
  border-color: var(--primary-color);
}

.VerticalFormFieldError .CustomFormField {
  border-color: var(--salmon);
}

.VerticalFormField span {
  font-size: 11px;
}

.VerticalFormField input,
.VerticalFormField input:focus {
  border: none;
  outline: none;
  box-shadow: none;
  height: 27px;
  padding: 6px 12px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

.VerticalFormField label {
  color: var(--oslo-gray);
  font-size: 11px;
  line-height: 1.5;
  padding: 6px 12px 0 12px;
  margin: 0;
  width: 100%;
}

.VerticalFormField label + div {
  border: none;
}

.VerticalFormField textarea {
  border: none;
  color: #333;
  outline: none;
  overflow: hidden;
  resize: none;
  padding: 6px 12px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.VerticalFormField select  {
  font-size: 14px;
  height: 27px;
}

.VerticalFormField input[type=password] {
  width: 100%;
  overflow: visible;
}

.VerticalFormField button {
  height: 25px;
  min-width: 25px;
  max-width: 25px;
  border: 0;
  display: flex;
  justify-content: center;
}

/* checkbox toggle */
.VerticalFormField .CustomFormField.checkbox > div {
  border: none;
}

.VerticalFormField .CustomFormField.checkbox label {
  padding: 0;
  font-size: 14px;
}

.VerticalFormField .CustomFormField.checkbox input {
  height: 0;
}

.VerticalFormField .errors {
  color: var(--salmon);
  font-size: 11px;
  line-height: 1.5;
} 
