tbody tr.totalRow:nth-child(n) {
  position: sticky;
  bottom: 0;
  background-color: var(--mine-shaft);
  color: #FFF;
}

.loader > svg {
  height: 21px;
}
