.datePicker {
  border: none;
  background-color: #f0f0f0;
}

.datePicker :global(.react-datepicker__header) {
  border-bottom: none;
  padding: 0;
}

:global(.react-datepicker-wrapper) {
  width: 100%;
}

.datePicker :global(.react-datepicker__navigation) {
  height: 40px;
  width: 10px;
  min-width: 10px;
  border: none;
  outline: none;
  top: 0px;
  color: #FFF;
}

.datePicker :global(.react-datepicker__navigation):focus {
  outline: none;
  box-shadow: none;
}

.datePicker :global(.react-datepicker__navigation-icon) {
  color: #FFF;
}

.datePicker :global(.react-datepicker__current-month) {
  background-color: #5d0cfb;
  color: #FFF;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.datePicker :global(.react-datepicker__day-names) {
  margin: 12px 0 0;
}

.datePicker :global(.react-datepicker__day-name) {
  margin: 6px;
}

.datePicker :global(.react-datepicker__day) {
  margin: 6px;
}

.datePicker :global(.react-datepicker__day--keyboard-selected) {
  border-radius: 0;
  background-color: #00a0ce;
  font-weight: 700;
}

.datePicker :global(.react-datepicker__day):hover {
  background-color: hsla(0,0%,86.7%,0.5);
  border-radius: 0;
  color: #000;
}

.datePicker :global(.react-datepicker-year-header) {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datePicker :global(.react-datepicker__monthPicker) {
  margin: 0;
}
