.profiles {
  margin-bottom: 53px;
}

.filters {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}

.selects {
  display: flex;
}

.searchArea {
  display: flex;
  align-items: center;
  background-color: #f4f5f7;
  padding-right: 12px;
  margin-right: 0;
  flex-grow: 1;
  margin-bottom: 8px;
}

.searchArea svg {
  stroke-width: 2;
}

.searchInput {
  width: 100%;
  border-radius: 0;
  background-color: #F4F5F7;
  border: none;
  padding: 11px 12px;
  color: #333;
  font-size: 14px;
  line-height: 18px;
  outline: none;
  font-family: 'Source Sans Pro', sans-serif;
}

.searchInput:global(::-webkit-input-placeholder) {
  color: #333;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

.searchInput:global(::-moz-placeholder) {
  color: #333;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

.searchInput:global(:-ms-input-placeholder) {
  color: #333;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

.searchInput:global(:-moz-placeholder) {
  color: #333;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

.select {
  width: 100%;
  min-width: 200px;
  margin-left: 0;
}

.select:not(:first-child) {
  margin-left: 8px;
}

.header {
  display: flex;
}

.title {
  flex-grow: 1;
  margin-bottom: 12px;
}

.export {
  margin-left: 14px;
  align-items: center;
  display: flex;
}

.share {
  color: var(--pacific-blue);
  font-weight: 300 !important;

}

.share:hover {
  color: var(--pacific-blue);
  text-decoration: none;
}

.guide {
  display: flex;
  flex-direction: column;
}

.guideCheckbox {
  margin-top: 24px;
}

@media (min-width: 1024px) {
  .filters {
    flex-direction: row;
  }
  .searchArea {
    padding-right: 12px;
    margin-right: 8px;
    margin-bottom: 0;
  }

  .select {
    min-width: 200px;
    margin-left: 8px;
    width: initial;
  }
}
