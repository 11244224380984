.tab {
  box-sizing: border-box;
  list-style-type: none;
  cursor: pointer;
  margin-bottom: 0;
}

.disabled {
  cursor: default;
  opacity: 0.3;
}

.tab.buttonsGroup {
  font-size: 14px;
  font-weight: 400;
  color: var(--mine-shaft);
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  padding: 0 16px;
}

.tab.buttonsGroup.activeTab {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: #FFF;
}

.tab.default {
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4px 10px 12px;
  margin-bottom: 4px;
  color: var(--mine-shaft);
  line-height: 16px;
}

.tab.default:hover:not(.activeTab) {
  border-bottom: 4px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 0;
}

.tab.default.activeTab {
  border-bottom: 4px solid var(--primary-color);
  margin-bottom: 0;
}
