:global(.ReactModalPortal) .textarea,
.textarea {
  width: 100%;
  padding: 0;
  border-radius: 0;
  border: none;
  outline: none;
  overflow: auto;
  max-width: 100%;
}

:global(.ReactModalPortal) .outlined,
.outlined {
  border: 1px solid var(--mercury);
  padding: 6px 8px 0;
}

:global(.ReactModalPortal) .default,
.default {
  font-size: 14px;
}
