.root {
  font-size: 14px;
  color: #444;
  font-weight: 600;
  letter-spacing: -0.1px;
}

.connectionStrength {
  display: inline-block;
}

.graduation {
  display: flex;
  margin: 16px 0;
}

.arrow {
  padding-left: 5px;
}

.arrow > svg {
  height: 100%;
  color: #aaaaaa;
  width: 10px;
}

.cols {
  width: 100%;
}

.colItem {
  background: #aaaaaa;
  width: 20px;
  height: 1px;
  margin-bottom: 7px;
  width: 100%;
}

.colItem:last-child {
  margin-bottom: 0;
}

.colItem:nth-child(1) {
  height: 6px;
  background: #00D400;
}
.colItem:nth-child(2) {
  height: 5px;
}
.colItem:nth-child(3) {
  height: 4px;
}
.colItem:nth-child(4) {
  height: 3px;
}
.colItem:nth-child(5) {
  height: 2px;
}
.colItem:nth-child(6) {
  height: 1px;
}
