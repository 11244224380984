.loaderBox {
  margin: 50px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allRelationshipsButtonContainer {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.container {
  padding: 0 44px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.title {
  line-height: 1.3;
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 23px;
  color: #444444;
  font-size: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  flex-shrink: 0;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 31px;
}

.profiles {
  font-size: 16px;
  line-height: 20px;
  color: #212529;
  display: flex;
  align-self: flex-start;
}

.profiles span {
  color: #00A0CE;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.export {
  margin-left: auto;
  background-color: #F4F5F7;
}
