.root {
  display: flex;
  height: 100%;
  margin-left: -24px;
  margin-top: 8px;
  flex-grow: 1;
}

.side {
  background-color: #F4F5F7;
  flex-basis: 300px;
  margin-right: 20px;
  padding: 20px;
  border: 1px solid #DEE0E3;
  position: relative;
}

.side:after {
  box-sizing: border-box;
  content: '';
  display: block;
  background-color: #F4F5F7;
  width: 300px;
  height: 24px;
  position: absolute;
  bottom: -24px;
  left: -1px;
  border-left: 1px solid #DEE0E3;
  border-right: 1px solid #DEE0E3;
}

.filter {
  margin-bottom: 20px;
}

.checkbox label {
  width: 100%;
  justify-content: space-between;
  font-size: 14px !important;
}

.title {
  color: #333;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 11px;
}

.select {
  background-color: #fff;
}

.select > div {
  min-height: 40px;
}

.main {
  display: flex;
}

.item {
  width: 401px;
  margin-right: 20px;
}

.item:last-child {
  margin-right: 0;
}
