.executiveInformation {
  position: relative;
  border-right: 1px solid #eaebee;
  padding-right: 24px;
}

.executiveInformation:after {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%) rotate(45deg);
  background-color: #FFF;
  border: 1px solid #eaebee;
  border-color: #eaebee #eaebee transparent transparent;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.table {
  border-bottom: 1px solid #eaebee;
}

.table td {
  font-size: 12px;
}

.table tr td:first-child {
  text-transform: uppercase;
  text-align: left;
}

.table tr td:last-child {
  color: var(--boulder);
  overflow: hidden;
  text-overflow: ellipsis;
}
