.small {
  width: 370px;
}

.medium {
  width: 470px;
}

.large {
  width: 100%;
}

.modal {
  border-radius: 10px;
  border-bottom: none;
  overflow-Y: auto;
  max-height: calc(100vh - 48px) !important;
}

.close {
  opacity: 1;
  cursor: pointer;
}

.close svg {
  width: 16px;
  height: 16px;
  fill: var(--dove-gray);
  stroke: var(--dove-gray);
}

.close:hover {
  fill: var(--dove-gray);
  stroke: var(--dove-gray);
}

.overflow {
  overflow: visible;
}

.modal a,
.modal a:visited  {
  color: var(--primary-color);
  /* box-shadow: inset 0 -.075em #fff,inset 0 -.1em currentColor,inset 0 -.15em #fff,inset 0 -.175em #fff; */
  text-decoration: underline;
}

.modal a:hover {
  color: var(--primary-color);
  text-decoration: none;
  /* box-shadow: none; */
}

.collapseContentMargins .container {
  display: block;
}

.collapseContentMargins .container u {
  text-decoration: underline;
}

.collapseContentMargins .container a {
  color: var(--primary-color)
}

.headerBox {
  display: flex;
  align-items: baseline;
}

.inlineSubheader {
  opacity: 0.5;
  color: #333333;
  font-size: 24px;
  line-height: 30px;
  flex-shrink: 0;
  margin-left: 12px;
  margin-right: 24px;
}
