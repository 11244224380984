.root {
  padding: 0 39px 28px 39px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
}

.loader img {
  max-width: 200px;
}

.viewDetails {
  justify-content: center;
  display: flex;
  margin: 20px 0 30px;
}

.alert {
  border: 1px solid var(--orange);
  padding: 16px 20px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.alertMessage {
  margin-left: 6px;
  font-weight: 400;
}
