table.table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
}

table.table td,
table.table th {
  padding: 11px 12px;
  text-align: left;
}

table.table th {
  font-size: 12px;
  color: var(--mine-shaft);
  letter-spacing: 1.71px;
  text-transform: uppercase;
}

.table tbody tr:nth-child(even) {
  background-color: var(--athens-gray);
}

.table tbody tr:hover {
  outline: 1px solid var(--primary-color);
}

.table tbody td {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table tbody td:first-child {
  overflow: visible;
}
