.container {
  position: relative;
  background-color: #F5F5F5;
}

.close {
  opacity: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0px;
  margin-top: 19px;
  margin-right: 19px;
}

.close svg {
  width: 24px;
  height: 24px;
  fill: var(--dove-gray);
  stroke: var(--dove-gray);
}

.close svg path {
  stroke-width: 1;
}

.close:hover svg {
  fill: var(--dove-gray);
  stroke: var(--dove-gray);
}

@media (min-width: 1024px) {
  .container {
    min-width: 752px;
  }
}
