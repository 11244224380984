.root {
  display: flex !important;
  margin-bottom: 22px !important;
}

.root:last-child {
  margin-bottom: 0px !important;
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--silver);
  min-width: 80px;
  max-width: 80px;
  margin-right: 24px;
}

.profiles {
  display: none;
}

.profile {
  width: 25px;
}

.avatarBox {
  border: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--silver);
}

.avatar {
  display: flex;
  border: 1px solid var(--dusty-gray);
  box-sizing: content-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.count {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.root:hover .count, .count:hover {
  text-decoration: underline;
}

.root:hover .count,
.count:hover,
.count:visited {
  color: var(--primary-color);
}

.date {
  color: var(--tundora);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
}

@media (min-width: 1024px) {
  .profiles {
    display: flex;
    flex-direction: row;
    margin-right: 41px;
  }
}
