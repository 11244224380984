.creditsRemaining {
  font-size: 16px;
}

.creditsRemainingNumber {
  color: var(--primary-color);
  font-weight: 600;
}

.creditsRemaining,
.formMessage {
  margin: 0 15px 20px;
}

.addRequestButton {
  display: flex;
  margin: 20px 25px 0 auto;
}

.actions {
  display: flex;
  flex-direction: reverse-row;
  justify-content: space-between;
  align-items: center;
  margin: 40px 15px;;
}

@media (min-width: 1024px) {
  .creditsRemaining,
  .formMessage {
    margin: 0 0 20px;
  }

  .addRequestButton {
     margin-left: 0;
   }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
  }
}
