.content {
  padding: 40px 50px;
}

.alert {
  border: 1px solid var(--orange);
  padding: 16px 20px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.alertMessage {
  margin-left: 6px;
  font-weight: 400;
}

.details {
  display: flex;
}

.dropzone {
  margin: 100px 0 0 24px;
  width: 400px;
  display: flex;
  justify-content: center;
}
