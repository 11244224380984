.actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 48px;
}

.labelExportButton {
  font-size: 14px;
  color: #000;
}

.search {
  width: 100%;
}

@media (min-width: 1024px) {
  .actions {
    flex-direction: row;
  }

  .search {
    width: 384px;
  }
}
