.raw,
:global(.ReactModalPortal) .raw,
.icon,
:global(.ReactModalPortal) .icon,
:global(#__react-alert__) .icon,
.outlinedIcon,
:global(.ReactModalPortal) .outlinedIcon,
:global(#__react-alert__) .outlinedIcon,
.link,
:global(.ReactModalPortal) .link,
.default,
:global(.ReactModalPortal) .default,
.outlined,
:global(.ReactModalPortal) .outlined {
  display: inline-block;
  padding: 0;
  border: unset;
  min-width: unset;
  max-width: unset;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  vertical-align: top;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  background: transparent;
  -webkit-font-smoothing: antialiased;
}

.raw:disabled,
:global(.ReactModalPortal) .raw:disabled,
.link:disabled,
:global(.ReactModalPortal) .link:disabled,
.icon:disabled,
:global(.ReactModalPortal) .icon:disabled,
.outlinedIcon:disabled,
:global(.ReactModalPortal) .outlinedIcon:disabled,
.default:disabled,
:global(.ReactModalPortal) .default:disabled,
.outlined:disabled,
:global(.ReactModalPortal) .outlined:disabled {
  opacity: 0.3;
  cursor: default;
}

.icon,
:global(#__react-alert__) .icon,
:global(.ReactModalPortal) .icon,
.outlinedIcon,
:global(#__react-alert__) .outlinedIcon,
:global(.ReactModalPortal) .outlinedIcon {
  background-color: transparent;
  line-height: 0;
}

.icon:hover,
:global(#__react-alert__) .icon:hover,
:global(.ReactModalPortal) .icon:hover,
.outlinedIcon:hover,
:global(#__react-alert__) .outlinedIcon:hover,
:global(.ReactModalPortal) .outlinedIcon:hover {
  color: #000;
}

.default,
:global(.ReactModalPortal) .default {
  padding: 6px 22px;
  border-radius: 4px;
  line-height: 24px;
  min-width: 120px;
}

.default.primaryColor,
:global(.ReactModalPortal) .default.primaryColor {
  background-color: var(--primary-color);
  color: #FFF;
  border: 2px solid var(--primary-color);
}

.outlined,
:global(.ReactModalPortal) .outlined {
  padding: 6px 22px;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  line-height: 24px;
  min-width: 120px;
}

.outlinedIcon,
:global(.ReactModalPortal) .outlinedIcon {
  padding: 6px;
  border: 2px solid var(--primary-color);
}

.link,
:global(.ReactModalPortal) .link {
  color: var(--primary-color);
}

.icon.secondaryColor,
:global(#__react-alert__) .icon.secondaryColor,
:global(.ReactModalPortal) .icon.secondaryColor {
  color: rgba(51,51,51,.6);
}

.default.secondaryColor,
:global(.ReactModalPortal) .default.secondaryColor {
  border-color: rgba(51,51,51,.6);
  background-color: rgba(51,51,51,.6);
}

.outlined.secondaryColor,
:global(.ReactModalPortal) .outlined.secondaryColor {
  border-color: rgba(51,51,51,.6);
}

.icon.orangeColor,
:global(#__react-alert__) .icon.orangeColor,
:global(.ReactModalPortal) .icon.orangeColor {
  color: var(--orange);
}

.default.orangeColor,
:global(.ReactModalPortal) .default.orangeColor {
  border-color: var(--orange);
  background-color: var(--orange);
}

.outlined.orangeColor,
:global(.ReactModalPortal) .outlined.orangeColor {
  border-color: var(--orange);
}
