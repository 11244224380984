.root {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .root {
    flex-direction: row;
  }
}
