.header {
  color: #444444;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 0;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #444444;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-size: 18px;
  line-height: 23px;
  flex-shrink: 0;
}

.number {
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  flex-shrink: 0;
}
