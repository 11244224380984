.information {
  background-color: var(--athens-gray);
  padding: 25px 10px 25px 30px;
  display: flex;
}

.details {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profileFullName {
  text-decoration: none;
  margin-bottom: 4px;
}

.profileFullName:hover {
  text-decoration: underline;
}

.work:not(:last-child) {
  margin-bottom: 10px;
}

.info {
  overflow: hidden;
  text-overflow: ellipsis;
}
