.dropWrapper {
  border: 2px dashed #EBEBEB;
  width: 350px;
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drop {
  font-size: 18px;
  margin-bottom: 10px;
}

.or {
  font-size: 14px;
  color: #000000;
  margin-bottom: 7px;
}

.file {
  font-size: 18px;
  color: #00A0CE;
  cursor: pointer;
}

.noFile {
  padding-top: 38px;
  text-align: center;
}

.dropWrapperError {
  border-color: #FA652C;
}
