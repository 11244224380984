.root {
  background-color: var(--athens-gray);
  font-size: 12px;
  line-height: 16px;
  color: var(--oslo-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 16px;
  box-shadow: 0 5px 8px 0 rgba(131,133,136,0.24);
  position: sticky;
  top: 0;
  z-index: 2;
}

.header {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.name {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--orange);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
}

.filtersCount {
  color: var(--oslo-gray);
  margin-right: 16px;
}

.resetFilters {
  color: var(--primary-color);
  font-size: 14px;
  line-height: 16px;
  flex: 0 0 auto;
  margin-left: 16px;
}

.filtersList {
  margin-top: 14px;
  max-width: 500px;
}

.filtersList div:first-child {
  color: var(--oslo-gray);
}

.filtersList div:last-child {
  color: white;
}

.tooltip :global(.rc-tooltip-arrow) {
  left: 36px;
}
