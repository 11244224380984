.root {
  border: 4px solid var(--primary-color);
  padding: 24px 15px 24px 24px;
  font-size: 14px;
}

.stateName {
  color: var(--dusty-gray);
}

.root > a,
.root > a:visited {
  color: var(--primary-color);
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.root > a:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.root > a svg {
  margin-left: 10px;
}

.cancel {
  border-color: var(--burnt-sienna) !important;
}

.cancel:hover {
  box-shadow: 0px 0px 0px 2px var(--burnt-sienna) !important;
}

.deliver {
  border-color: var(--mantis) !important;
}

.deliver:hover {
  box-shadow: 0px 0px 0px 2px var(--mantis) !important;
}

.label {
  font-size: 14px;
  color: var(--oslo-gray);
  margin-bottom: 8px;
}

.header {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.stateName {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headline {
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
