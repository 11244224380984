.root {
  display: flex;
}

.root p {
  font-size: 12px;
  margin-bottom: 7px;
}

.avatar {
  width: 100px;
  height: 100px;
  margin-right: 24px;
}


.avatar svg {
  width: 100px;
  height: 100px;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.organizationName {
  font-weight: 600;
  margin-bottom: 12px;
}
