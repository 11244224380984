.root {
  height: 100%;
}

.profile {
  display: flex;
  color: var(--iron);
}

.userpic {
  width: 192px;
  height: 192px;
  margin-right: 40px;
  display: block;
  border-radius: 50%;
}

.fullName {
  margin-bottom: 18px;
  color: var(--tundora);
  display: flex;
  align-items: baseline;
}

.fullName > a {
  flex-shrink: 0;
  font-size: 16px;
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: normal;
}

.fullName > a:hover {
  text-decoration: none;
}

.organization {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px !important;
  color: var(--tundora);
}

.date {
  font-size: 14px !important;
  margin-bottom: 10px !important;
  color: var(--mine-shaft);
}

.editorialDate {
  font-size: 14px !important;
  margin-bottom: 10px !important;
  color: var(--orange);
}

.functions {
  font-size: 14px !important;
  color: var(--dusty-gray);
  margin-bottom: 13px !important;
}

.personalInfo {
  overflow: hidden;
}
