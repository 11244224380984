.button {
  display: flex;
  align-items: flex-start;
}

.title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 15px;
}

.th {
  line-height: 15px;
}

.icon svg {
  min-width: 12px;
  max-width: 12px;
}

.checkbox {
  width: 67px;
}

.actionRow {
  background-color: var(--athens-gray);
  height: 47px;
}

.actionCol {
  width: 125px;
}

.actionCol :global(.grommet_legacy-control-icon-trash) {
  width: 11px;
  height: 11px;
  stroke: var(--orange);
  vertical-align: baseline;
}

.actionCol :global(.grommet_legacy-button-trash) {
  border: 0;
  color: var(--orange);
  font-size: 14px;
}

.actionCol :global(.grommet_legacy-control-icon) {
  width: 11px;
  height: 11px;
  vertical-align: baseline;
}

.actionCol :global(.grommet_legacy-button) {
  border: 0;
  font-size: 14px;
  margin-left: 24px;
}

.checkBox {
  width: 65px;
}

.totalValue {
  color: var(--oslo-gray);
  font-size: 14px;
  line-height: 18px;
}
