.item {
  display: flex;
  border: 1px solid var(--primary-color);
  padding: 9px 16px 8px 10px;
  align-items: center;
  cursor: pointer;
}

.text {
  flex-grow: 1;
  padding: 0px 12px;
  overflow: hidden;
}
