.root {
  display: flex;
  align-items: center;
}

.button {
  font-size: 14px !important;
  line-height: 16px;
  color: var(--pacific-blue) !important;
  margin-right: 8px !important;
}

.questionMark {
  font-size: 8px;
  line-height: 8px;
  color: var(--primary-color);
  width: 12px;
  height: 12px;
  border: 1px solid var(--primary-color);
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
}
