.strong {
  font-weight: bold;
  margin-bottom: 24px;
}

.wysiwyg {
  margin-bottom: 24px;
}

.wysiwyg p {
  margin-top: 0;
  margin-bottom: 12px;
}
