.root {
  cursor: pointer;
  position: relative;
  border: solid 1px var(--iron);
  width: 100%;
}

.root:hover {
  border: solid 1px var(--tundora);
}

.root:hover .panel {
  display: flex;
}

.collapsed .text {
  height: 100px;
  overflow: hidden;
}

:global(body) .text p {
  line-height: 22px;
}

.content {
  padding: 24px;
  position: relative;
}

:global(body) .content {
  background-color: white;
}

.collapsed .content {
  padding: 24px 24px 0 24px;
}

.collapsed .content:before {
  content: '';
  background: linear-gradient(180deg,transparent 50%,#fff);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.panel {
  background: var(--primary-color);
  color: white;
  position: absolute;
  top: -1px;
  width: 40px;
  left: 100%;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  display: none;
}

.visibilityLabel {
  background: var(--wild-sand);
  position: absolute;
  top: -1px;
  width: 40px;
  left: calc(100% + 1px);
  z-index: 5;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  display: flex;
}

.visibilityLabel > svg {
  fill: currentColor;
  color: var(--silver);
}

.action {
  margin-bottom: 10px;
}

.action:last-child {
  margin-bottom: 0;
}

.title {
  margin-bottom: 7px;
  display: flex;
}

.titleText {
  flex: 1 1 auto;
}

.pinIcon {
  margin-right: 4px;
}

.pinIcon svg path {
  fill: var(--silver);
}

.text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--tundora);
}

.text p {
  margin: 0 0 10px;
}

.text p:last-child {
  margin: 0;
}

.text ul {
  padding: 13px 0 0;
  margin: 0;
  list-style: none;
}

.text ul li {
  margin: 0 0 10px;
  position: relative;
  padding-left: 30px;
}

.text ul li:before {
  content: '•';
  position: absolute;
  left: 10px;
  top: 0;
}

.text ul li ul li:before {
  content: '‑';
}

.text pre {
  white-space: pre-wrap;
}

:global(body) .collapseControl,
.collapseControl {
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: solid 1px var(--iron);
  z-index: 10;
  position: relative;
}

:global(body) .collapseButton,
.collapseButton {
  width: 100%;
  max-width: none;
  background-color: white;
  border: none;
  padding: 6px 22px;
  font-size: 14px;
  line-height: 24px;
  color: var(--mine-shaft);
}

:global(body) .collapseButton:focus,
.collapseButton:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.tags {
  padding: 10px 0 15px 0;
}

.hidden {
  opacity: .5;
}

.btnAction {
  display: flex;
  border: none;
  padding: 0;
  min-width: auto;
  color: white;
  fill: currentColor;
}

.btnAction:focus {
  outline: none;
  box-shadow: none;
}
