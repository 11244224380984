
.header {
  padding: 19px 100px 19px;
  border-bottom: 1px solid var(--mercury);
}

.title {
  color: var(--tundora);
  font-size: 24px;
  line-height: 26px;
  text-align: center;
}

.description {
  color: var(--tundora);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  max-width: 457px;
  max-width: 457px;
  margin: 24px auto auto;
}

.error {
  color: var(--salmon);
  line-height: 24px;
  font-size: 16px;
  text-align: center;
}

@media (min-width: 1024px) {
  .title {
    line-height: 20px;
  }
}
