.root {
  color: #333333;
  font-size: 14px;
  letter-spacing: -0.4px;
  line-height: 18px;
  min-height: 48px;
  padding: 15px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}

.root:nth-child(2):nth-last-child(1):before {
  content: '';
  background-color: #f0f1f3;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  font-weight: 600;
  margin-bottom: 8px;
}

.description {
  margin-bottom: 4px;
}
