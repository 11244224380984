.root {
  font-size: 14px;
}

.text {
  flex: 1 1 auto;
}

.pinIcon {
  margin-right: 4px;
  flex: 0 1 auto;
}

.pinIcon svg path {
  fill: var(--silver);
}

.updatedAt {
  font-size: 12px;
  color: var(--gray);
  flex: 1 1 auto;
}
