.customTooltip {
  background-color: #fff;
  border: 1px solid #dedede;
  padding: 10px;
}

.label {
  display: flex;
}

.legendItem {
  display: flex;
  margin: 5px;
  flex-direction: column;
}

.legendItemLabel {
  display: flex;
  justify-content: space-between;
}

.line {
  width: 30px;
  height: 5px;
  margin: 11px 5px 5px;
  border: none;
  border-top: 5px solid white;
}

.dashed {
  border-top-style: dashed;
}
