.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1150px;
}

.fullName {
  margin-bottom: 18px;
  color: var(--tundora);
}

.organization {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--tundora);
}

.fullWidth {
  max-width: 100%;
}
