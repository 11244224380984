.root tbody td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.root {
  padding-top: 50px;
}

.content {
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  border: 1px solid var(--iron);
}

.header {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

.header > *:not(:last-child) {
  margin-bottom: 12px;
}

.title {
  color: var(--mine-shaft);
  font-size: 24px;
}

.root :global(th) {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

section {
  padding-bottom: 0;
}

.search {
  display: flex;
  width: 384px;
  max-width: 100%;
  max-height: 100%;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .content {
    overflow-x: initial;
  }

  .header {
    flex-direction: row;
    align-items: center;
  }

  .header > *:not(:last-child) {
    margin-bottom: 0px;
  }
}
