.fields {
  margin-top: 36px;
}

.fields > *:not(:last-child) {
  margin-bottom: 12px;
}

.inputTotalSuffix {
  white-space: nowrap;
  color: var(--oslo-gray);
}
