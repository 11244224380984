.root {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
}

.logo {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 120px;
  height: 120px;
  position: relative;
}

.avatar:hover > .delete {
  display: flex;
}

.delete {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: white;
  padding: 4px !important;
  border: 1px solid var(--dove-gray);
  border-radius: 4px;
  display: none;
  justify-content: center;
  padding: 0px;
  height: 27px;
  width: 27px;
}

.delete > svg {
  height: 18px;
  width: 18px;
}
