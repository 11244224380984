.inputGroup > div:last-child {
  margin-bottom: 0;
}

.updateRicCode,
.updateRicCode:visited {
  font-size: 10px !important;
  color: var(--primary-color);
  box-shadow: inset 0 -.075em #fff,inset 0 -.1em currentColor,inset 0 -.15em #fff,inset 0 -.175em #fff;
  display: inline-block;
  align-self: flex-start;
  margin-top: 8px;
}

.updateRicCode:hover {
  color: var(--primary-color);
  text-decoration: none;
  box-shadow: none;
}

.field {
  margin-bottom: 12px;
}

.submitButton {
  margin-top: 24px;
}

.subField {
  display: block;
  width: 100%;
}
