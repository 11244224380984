.linkAction {
  font-weight: 600;
}

.info {
  border: 1px solid var(--mercury);
  font-size: 15px;
  padding: 16px 19px 28px 22px;
  margin-top: 21px;
  margin-bottom: 35px;
}

.infoAlert {
  color: var(--primary-color);
}

.errorAlert {
  color: var(--persimmon);
}

@media (min-width: 1024px) {
  .info {
    font-size: 14px;
    padding: 13px 23px 17px 23px;
    margin-top: 39px;
    margin-bottom: 33px;
  }

  .infoAlert a {
    color: var(--primary-color);
  }

  .infoAlert a:hover {
    color: var(--primary-color);
  }

  .errorAlert a {
    color: var(--persimmon);
  }

  .errorAlert a:hover {
    color: var(--persimmon);
  }
}
