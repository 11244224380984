.list {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.loaderBox {
  margin: 50px auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
