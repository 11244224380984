.root {
  word-wrap: break-word;
  text-align: center;
  padding: 0 5px;
  max-height: 5.5rem;
  overflow: hidden;
  font-size: 14px;
  color: var(--tundora);
  font-weight: 600;
}
