.root {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #DEE0E3;
}

.gridList > div {
  display: flex;
  flex-flow: row wrap;
}

.project {
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex: 0;
  height: 335px;
  min-width: 460px;
  margin: 0px 24px 24px 0px;
}

.spinner {
  display: flex;
  flex:  0 0 20%;
}

.search {
  margin-bottom: 24px;
  width: 400px;
}

.search > div {
  width: 100%;
}
