.root {
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
}

.header {
  color: var(--shark);
  font-size: 37px;
  letter-spacing: -0.5px;
  line-height: 44px;
  padding: 48px 0px 20px;
  border-bottom: 1px solid var(--mercury);
  margin-bottom: 16px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  color: var(--tundora);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.searchActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  flex-direction: row-reverse;
}

.searchActions > button {
  margin-left: 32px;
}

.searchButton {
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  padding: 8px 20px;
  flex: 1 0 auto;
}

.details {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding-bottom: 33px;
  border-bottom: 1px solid var(--mercury);
}

.details:last-child {
  border-bottom: none;
}

.detailsTitle {
  color: var(--shark);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  text-transform: uppercase;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.detailsTitle > button {
  margin-left: 15px;
}

@media (min-width: 1024px) {
  .root {
    margin: 0px;
  }

  .info {
    flex-direction: row;
    justify-content: space-between;
  }

  .searchActions {
    margin-top: 0px;
    width: auto;
    flex-direction: row;
  }

  .searchActions > button {
    margin-right: 32px;
    margin-left: 0px;
  }
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button {
  padding: 8px 28px;
  color: var(--tundora);
  font-size: 18px !important;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
}
