.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1150px;
}

.header {
  margin-bottom: 19.5px;
}
