.root {
  display: flex;
  justify-content: flex-start;
}

.field {
  max-width: 230px;
  width: 100%;
  margin-right: 24px;
}

.fieldSelect {
  height: 34px;
}

.btn {
  display: flex;
  align-items: flex-end;
}
