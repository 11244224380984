.selector {
  max-width: 742px;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 32px;
}

.fieldSelect {
  height: 40px;
}
