.row {
  display: flex;
  margin-bottom: 12px;
}

.row > *:not(:last-child) {
  margin-right: 48px;
}

.alertTitle {
  align-self: center;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
