.root {
  margin-bottom: 20px;
}

.link {
  text-decoration: none;
}

.link:hover .name,
.link:hover .work {
  text-decoration: underline;
  text-decoration-color: black;
}

.name {
  color: #04a0c5;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-right: 16px;
}

.work {
  color: #212529;
  font-size: 12px;
  line-height: 19px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 20px;
}

.header {
  display: flex;
  margin-bottom: 4px;
}

.profileLabels {
  margin-top: 8px;
}

.profileLabels > *:not(:first-child) {
  margin-left: 8px;
}
