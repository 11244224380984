.gridList > div {
  display: flex;
  flex-wrap: wrap;
}

.headLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 1px solid #E7E7E7;
  margin-bottom: 24px;
  margin-top: 43px;
  letter-spacing: -0.9px;
}

.title {
  font-size: 37px;
  text-overflow: hidden;
  white-space: nowrap;
  display: flex;
}

.counter {
  color: #777777;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 20px;
}

.action {
  flex-shrink: 0;
}

.button {
  font-size: 18px !important;
  padding: 7px 18px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 26px 0 21px 0;
}

.spinner {
  display: flex;
  flex:  1 1 100%;
  align-items: center;
  margin-bottom: 40px;
}

.filters {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.searchInput {
  margin-right: auto;
}

.searchInput input {
  min-height: 30px;
  min-width: 330px;
  font-size: 14px;
}

.select {
  justify-self: flex-end;
  align-self: flex-end;
  margin-left: 24px;
  min-width: 200px;
}
