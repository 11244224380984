.title {
  color: var(--mine-shaft);
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-left: 10px;
}

.line {
  height: 2px;
  width: 100%;
}
