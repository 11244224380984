.executiveMatch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.executiveMatch > *:first-child,
.executiveMatch > *:last-child {
  flex: 1 1 30%;
}

.controls {
  display: flex;
  flex: 1 1 20%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.controls > *:not(:last-child) {
  margin-bottom: 12px
}
