.header {
  color: #444444;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 0;
}

.label {
  right: 0px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #444444;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  line-height: 23px;
  flex-shrink: 0;
  min-width: 180px;
}

.number {
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  flex-shrink: 0;
  min-width: 90px;
  text-align: right;
}

.subheader {
  position: relative;
  overflow: visible;
}

.subheader span {
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  top: -100%;
  color: var(--boulder)
}
