.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:global(.grommet) .root .number {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 17px;
}

:global(.grommet) .root .text {
  font-size: 36px;
  font-weight: bold;
  margin-top: 0;
  text-align: center;
}

.recomendation {
  font-size: 24px;
  margin-bottom: 42px;
}

.header {
  padding-left: 20px;
  padding-top: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 15px;
  text-align: center;
}

:global(.grommet) .root .button {
  font-size: 18px;
  padding: 6px 45px;
}

@media (min-width: 1024px) {
  .main {
    padding: 0 15px;
    text-align: left;
  }
}
