.root {
  width: 100%;
}

.header {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabs ul {
  margin: 0;
  padding: 0;
}
