.fileUploadContainer {
  padding: 40px 50px;
  display: flex;
  justify-content: space-between;
}

.title {
  color: var(--tundora);
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 8px;
}

.list {
  line-height: 43px;
  font-size: 16px;
  margin-bottom: 20px;
}

.info {
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 30px;
}

.description {
  line-height: 22px;
  margin-bottom: 8px;
}
