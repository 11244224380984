.tabs,
.root {
  width: 100%;
}

.tabs ul {
  margin: 0;
  padding: 0;
}

.component {
  padding-top: 24px;
}
