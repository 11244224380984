.root {
  cursor: pointer;
  position: relative;
  display: block;
}

.root:hover {
  border: solid 1px var(--tundora);
}

.root:hover .panel {
  display: flex;
}

.panel {
  background: var(--primary-color);
  color: white;
  position: absolute;
  top: -1px;
  width: 40px;
  left: 100%;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  display: none;
}

.action {
  margin-bottom: 10px;
}

.action:last-child {
  margin-bottom: 0;
}

.title {
  margin-bottom: 7px;
}

.text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--tundora);
}

.text p {
  margin: 0 0 10px;
}

.text p:last-child {
  margin: 0;
}

.text ul {
  padding: 13px 0 0;
  margin: 0;
  list-style: none;
}

.text ul li {
  margin: 0 0 10px;
  position: relative;
  padding-left: 25px;
}

.text ul li:before {
  content: '•';
  position: absolute;
  left: 10px;
  top: 0;
}

.text ul li ul li:before {
  content: '‑';
}

.footerText {
  opacity: 0.6;
  color: var(--mine-shaft);
  font-size: 8px;
  letter-spacing: 1.14px;
  line-height: 10px;
  padding: 0 21px;
  background-color: var(--iron);
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
