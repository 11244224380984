.container {
  position: relative;
  background: var(--athens-gray);
  border: 1px solid var(--iron);
  width: 410px;
  padding: 24px 32px;
  margin-right: 32px;
  margin-top: 88px;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 10px;
  height: 10px;
  line-height: 10px;
}

.close svg {
  width: 10px;
  height: 10px;
}
