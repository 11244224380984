.root {
  margin-bottom: 30px;
  padding: 0 12px;
}

.general {
  display: flex;
}

.main {
  flex-grow: 1;
  padding-right: 45px;
  padding-left: 8px;
}

.title {
  color: #444444;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 13px;
}

.navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 53px;
}

.navigation button {
  margin-right: 12px;
}

@media (min-width: 1024px) {
  .root {
    padding: initial;
  }
}
