.root {
  position: sticky;
  top: 0;
  max-height: 0;
  overflow: visible;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.notifications {
  max-width: 330px;
}
