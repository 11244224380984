.root {
  position: absolute;
  width: 1px;
  height: 1px;
}

.tooltip {
  position: absolute;
  background-color: #494949;
  z-index: 999;
  min-width: 140px;
  padding: 8px 16px;
  border-radius: 3px;
  color: #FFFFFF;
  bottom: 10px;
	left: 0;
	transform:translateX(-50%);
  font-size: 14px;
}

.countInfo {
  display: flex;
  justify-content: space-between;
}

.totalCount {
  margin-left: 16px;
}

.companyName {
  font-size: 12px;
  opacity: 0.64;
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  bottom: -8px;
  margin-left: -8px;
  border-width: 8px 8px 0;
  border-top-color: #494949;
  left: 50%;
  color: red;
}
