.menuItem {
  padding: 0 10px;
  min-height: 38px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--gallery);
  font-size: 14px;
  color: var(--boulder);
  white-space: nowrap;
  flex: 1;
}

.menuItem > * {
  width: 100%;
}

.menuItem:last-child {
  border-bottom: 1px solid transparent;
}

.menuItem:hover {
  background-color: #FFF;
  border: 1px solid var(--primary-color);
}
