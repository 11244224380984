.boardItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
}

.ftse100 {
  font-size: 10px;
  font-weight: 600;
  padding: 2px 8px;
  background-color: var(--mantis);
  color: var(--wild-sand);
  margin-left: 16px;
  line-height: 16px;
}

.relevantIcon {
  stroke: var(--primary-color) !important;
  width: 16px !important;
  height: 16px !important;
  margin-left: 16px;
}
