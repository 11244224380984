.lists {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.list {
  flex-grow: 1;
  max-width: 33.333333%;
  padding-right: 30px;
  padding-left: 11px;
}

.list:first-child {
  padding-left: 0;
}

.list:last-child {
  padding-right: 0px;
}

.item {
  margin-bottom: 15px;
  padding-right: 10px;
}

.item a {
  color: #444444;
  color: #444444;
  font-size: 16px;
  font-weight: 300 !important;
  line-height: 20px;
}

.title {
  color: #444444;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 15px;
}
