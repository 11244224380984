.header {
  margin-bottom: 19.5px;
}

.danger {
  border-color: var(--orange) !important;
  background-color: var(--orange) !important;
}

.danger:hover {
  box-shadow: 0px 0px 0px 2px var(--orange) !important;
}
