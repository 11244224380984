.root {
  font-size: 0;
  position: relative;
  width: 24px;
  height: 24px;
}

.input {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
}

.checkbox {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid var(--iron);
  position: relative;
}

.input:checked + .checkbox {
  border: 2px solid var(--primary-color);
}

.checkbox:hover,
.input:checked + .checkbox:hover {
  border: 2px solid #000;
}

.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.input:checked + .checkbox > .checkmark {
  stroke: var(--primary-color);
  stroke-width: 4;
}

.input:disabled + .checkbox,
.input:disabled + .checkbox:hover {
  border: 2px solid var(--iron);
  opacity: .5;
}

.input:disabled.input:checked + .checkbox > .checkmark {
  stroke: var(--iron);
  opacity: .5;
}
