.root {
  display: flex;
  align-items: center;
  min-height: 59px;
  border: 1px solid var(--gallery);
  border-top: 1px solid var(--wild-sand);
  padding-left: 20px;
  padding-right: 20px;
}

.root:hover {
  background: #fff;
  border: 1px solid var(--pacific-blue);
  text-decoration: none;
}

.info {
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  align-items: flex-start;
}

.container {
  margin-left: 12px;
  flex: 1 1 auto;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
}

.header {
  display: flex;
  margin-bottom: 4px;
  max-width: 100%;
  flex-wrap: wrap;
}

.name {
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
}

.work {
  width: 100%;
  color: var(--boulder);
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}

.work:last-child {
  margin-bottom: 0px;
}

.separator {
  display: none;
}
