.header {
  margin-bottom: 19.5px;
}

.links {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 300px;
}

.links a {
  margin: 8px 0px;
  font-size: 16px;
  line-height: 24px;
  color: var(--mine-shaft);
  font-weight: 300;
}
