.manager {
  display: flex;
  align-items: center;
}

.manager > *:not(:last-child) {
  margin-right: 8px;
}

.fullName {
  font-weight: 600;
}
