.suggestion {
  display: block;
  min-height: 60px;
  max-width: 100%;
  text-decoration: none;
  position: relative;
}

.suggestion:hover {
  background: #fff;
}

.content {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  white-space: nowrap;
  border: 1px solid var(--gallery);
  border-top: 1px solid var(--wild-sand);
  min-height: inherit;
}

.information {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-wrap: nowrap;
  margin-left: 12px;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.work {
  margin-bottom: 4px;
  margin-right: 16px;
}

.work:last-child {
  margin-bottom: 0;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.cover {
  background-color: #FFF;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: .7;
}

.cover:hover {
  opacity: .5;
}
