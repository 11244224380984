.sidebar {
  width: 60px;
  background-color: var(--athens-gray);
  padding: 0 !important;
}

.anchor span {
  height: auto;
  padding: 15px;
}

.nav {
  margin-top: 19px;
}
