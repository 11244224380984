.root {
  padding-top: 25px;
  padding-bottom: 50px;
}

.wizard {
  border: 1px solid #E7E7E7;
  position: relative;
  min-height: 261px;
}

.breadcrumbs {
  margin-bottom: 30px;
}

.close {
  position: absolute;
  top: 19px;
  right: 21px;
}
