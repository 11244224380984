.switch {
  margin: 5px 0;
}

.checkedHandle,
.uncheckedHandle {
  position: absolute;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #FFF;
  top: 50%;
  transform: translateY(-50%);
}

.checkedHandle {
  box-shadow: 0 0 0 2px var(--primary-color);
}

.uncheckedHandle {
  box-shadow: 0 0 0 2px #515151;
}

.switch:hover .checkedHandle,
.switch:hover .uncheckedHandle {
  box-shadow: 0 0 0 2px #000;
}
