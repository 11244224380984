.item {
  color: #444444;
  font-size: 24px;
  position: relative;
  min-height: 30px;
  margin-right: 40px;
}

.title {
  font-weight: bold;
  line-height: 24px;
  font-size: 18px;
  text-transform: capitalize;
}

.value {
  line-height: 20px;
  margin-left: 10px;
  font-size: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.data {
  display: flex;
  align-items: center;
}

.chart {
  display: flex;
  flex-direction: column;
}

.empty {
  min-height: 30px;
  background-color: #dee0e3;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.wrapper:last-child {
  margin-bottom: 0;
}

.item[data-value='0'] {
  display: none;
}
