.modal {
  position: relative;
  padding: 30px 100px;
  max-width: 700px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

:global(.ReactModalPortal) .modal .header {
  text-align: center;
  margin-bottom: 20px;
}

:global(.ReactModalPortal) .modal .description {
  text-align: center;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 22px;
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
}

:global(.ReactModalPortal) .modal .rejectHelpButton {
  width: 300px;
  margin-top: 16px;
  font-size: 14px;
  line-height: 18px;
}
