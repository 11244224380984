.subtitle {
  margin-top: 16px;
  font-weight: 400;
}

.description {
  margin: 24px 0 32px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
  margin: 60px;
}

.loader > img {
  max-width: 200px;
}

.actions {
  margin: 60px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions > *:not(:last-child) {
  margin-right: 16px;
}
