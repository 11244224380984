.info {
  font-size: 14px;
  align-items: flex-start;
  overflow: hidden;
}

.info > p {
  font-size: inherit;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 11px;
}

.info a {
  color: var(--primary-color);
  text-decoration: underline;
}

.info a:hover {
  text-decoration: none;
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
