.button {
  width: 24px;
  height: 24px;
  color: var(--primary-color) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 16px !important;
  background-color: #FFFFFF !important;
  border-radius: 50%;
  border: 1px solid var(--primary-color) !important;
  text-align: center !important;
}

.button:hover {
  border: 2px solid var(--primary-color) !important;
}
