.children {
  display: block;
  z-index: 10;
  position: relative;
}

.overlay {
  position: absolute;
  z-index: -1;
}

.canvas {
  position: relative;
}

.container {
  position: relative;
}
