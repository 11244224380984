
.modal {
  min-width: 100%;
  max-width: 100%;
  padding: 24px 32px 32px;
}

.header {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 24px 0px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .modal {
    min-width: 360px;
    max-width: 360px;
  }
}
