.root {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.filterName {
  min-width: 120px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: var(--oslo-gray);
}

.filterValue {
  flex: 1 1 200px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: var(--tundora);
  word-break: break-word;
}
