.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  padding-left: 24px;
}

.notificationTitle {
  font-size: 24px;
  font-weight: bold;
  margin: 0px 0px 8px 0px;
}

