.root {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container {
  width: 500px;
  margin: auto;
}

.info {
  font-size: 14px;
  color: var(--dove-gray);
  margin-bottom: 24px;
}

.form {
  margin-bottom: 32px;
}

.action {
  display: flex;
}

.message {
  color: var(--shark);
  font-size: 37px;
  letter-spacing: -0.5px;
  line-height: 44px;
}

.danger {
  color: var(--salmon);
}

.mobilePasswordValidator {
  margin-bottom: 20px;
}

.passwordComplexity {
  margin-bottom: 16px;
}

.passwordWeak {
  color: var(--orange);
}

.passwordStrong {
  color: var(--japanese-laurel);
}

.credentialsBlock {
  display: flex;
}

.fields {
  flex-grow: 2;
}

.desktopPasswordValidatorBlock {
  padding-left: 26px;
  display: none;
}

.passwordStrengthRating {
  margin-bottom: 16px;
}

@media (min-width: 1024px) {
  .mobilePasswordValidator {
    display: none;
  }
  .container {
    width: 700px;
    margin: auto;
  }

  .desktopPasswordValidatorBlock {
    display: block;
  }

  .passwordValidator {
    flex-direction: column;
  }

  .passwordValidator > div {
    width: auto;
  }
}
