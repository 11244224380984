.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.column {
  display: flex;
  flex: 0 0 46%;
}
