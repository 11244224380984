.executivesMatches {
  margin-top: 32px;
}

.executivesMatches > *:not(:last-child) {
  margin-bottom: 24px;
}

.pagination {
  display: flex;
  flex-direction: row-reverse;
}
