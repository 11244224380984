.title {
  font-weight: bold;
  line-height: 23px;
}

.missing {
  font-size: 16px;
  line-height: 20px;
  color: var(--silver-chalice);
}

.children {
  color: var(--primary-color);
  font-size: 16px !important;
  font-weight: 300 !important;
}
