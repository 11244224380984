.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  border-bottom: 1px solid var(--mercury);
  padding: 0 30px 0 15px;
}

.separator {
  display: block;
  width: 1px;
  height: 13px;
  background-color: var(--boulder);
  margin: 0 8px;
}

.nav ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  align-items: flex-end;
}

.nav ul li {
  margin-right: 20px;
  margin-bottom: 0;
  line-height: 1;
}

.nav ul li a {
  font-size: 14px;
  color: var(--boulder);
}

.nav ul li a strong {
  font-weight: 600;
}
