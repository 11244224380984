.info {
  font-size: 14px;
  display: none;
}

.item {
  display: flex;
  margin-bottom: 11px;
}

.value {
  color: var(--shark);
  font-size: 14px;
  letter-spacing: 0.37px;
  line-height: 19px;
}

.fullName {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;
}

.fullName h1 {
  margin: 0;
}

.questionMark {
  margin-left: 1rem;
}

@media (min-width: 1024px) {
  .info {
    display: block;
  }

  .fullName {
    justify-content: left;
  }
}
