.fieldContainer {
  flex: 1;
}

.field {
  display: flex;
  flex-direction: column;
  position: relative;
}

.outlined:focus-within {
  border-color: var(--primary-color);
}

.title {
  margin-bottom: 8px;
}

.field > .title:empty {
  margin-bottom: 0;
}

.default > .title {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.outlined {
  border: 1px solid var(--mercury);
  padding: 5px 12px;
  background: #FFF;
  min-height: inherit;
  box-sizing: border-box;
}

.outlined.error {
  border: 1px solid var(--salmon);
}

.outlined > .title {
  color: var(--oslo-gray);
  font-size: 11px;
  margin-bottom: 4px;
  margin-top: 0;
  line-height: 16px;
}

.outlined > .input {
  line-height: 1;
  display: flex;
  align-items: center;
  flex: 1;
}

.errorMessage {
  color: var(--salmon);
  font-size: 12px;
  margin-top: 8px;
}

.switchField {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.outlined.switchField > .input {
  flex: 0;
}

.switchField.default > .title {
  color: var(--tundora);
  margin-right: 12px;
}

.switchField.outlined > .title {
  color: var(--tundora);
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 12px;
}

.checkboxField {
  display: flex;
  align-items: center;
}

.checkboxField.default {
  flex-direction: row-reverse;
}

.checkboxField.outlined {
  flex-direction: row;
}

.checkboxField.outlined > .input {
  flex: 0;
}

.checkboxField.default > .title {
  color: var(--tundora);
  margin-left: 12px;
}

.checkboxField.outlined {
  justify-content: space-between;
}

.checkboxField.outlined > .title {
  color: var(--tundora);
  font-size: 14px;
  margin-bottom: 0px;
  margin-right: 12px;
  flex: 1;
}
