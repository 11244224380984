.pagination {
  margin: 20px 0;
}

@media (min-width: 1024px) {
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 26px 0 21px 0;
  }
}
