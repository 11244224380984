.counters {
  min-height: 260px;
}

.row {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.column {
  width: 25%;
}

.top {
  display: flex;
}

.transcripts {
  margin-left: 100px;
  width: 400px;
}
