.row {
  display: flex;
  margin-top: 24px;
}

.row:first-child {
  padding-top: 6px;
}

.row > * {
  flex: 1 50%;
  max-width: 50%;
}

.row > *:not(:last-child) {
  margin-right: 40px;
}

.helpRequestMessage {
  min-height: 50px;
}
