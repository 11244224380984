.fortune500Boards {
  margin-bottom: 27px;
}

.fortune500Title {
  font-size: 16px;
  font-weight: 600;
}

.fortune500BoardsList {
  margin-left: 20px;
  list-style: none;
}

.fortune500BoardsList li::before {
  content: "\2022";
  color: var(--sundance);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
