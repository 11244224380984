.root {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container {
  width: 500px;
  margin: auto;
}

.info {
  font-size: 14px;
  color: var(--dove-gray);
  margin-bottom: 24px;
}

.form {
  margin-bottom: 32px;
}

.action {
  display: flex;
}

.message {
  color: var(--shark);
  font-size: 37px;
  letter-spacing: -0.5px;
  line-height: 44px;
}
