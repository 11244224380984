.label {
  font-size: 14px;
  color: var(--oslo-gray);
}

.search {
  flex-basis: 270px;
  margin-right: 12px;
}

.editSubjectIcon {
  margin-left: 6px;
  text-decoration: none;
  box-shadow: none;
  padding: 0 !important;
}

a.editSubjectIcon,
a.editSubjectIcon:visited {
  text-decoration: none;
  box-shadow: none;
}

a.action {
  margin-left: 6px;
  margin-right: 6px;
  text-decoration: none;
  box-shadow: none;
  color: var(--mine-shaft) !important;
  font-size: 14px;
  font-weight: 300;
}

.subjectOption {
  display: flex;
  flex-direction: column;

  background-color: transparent;
  color: #000;
  cursor: default;
  display: block;
  font-size: 14px;
  padding: 8px 12px;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
}

.subjectOption:hover {
  background-color: #f4f5f7
}

.subjectOption > .request {
  font-size: 12px;
}

.subjectOptionDisabled {
  cursor: not-allowed;
}

.requestLink::before, .requestLink::after {
  content: ' ';
}
