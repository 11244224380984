.dl {
  display: flex;
  align-items: baseline;
  margin: 0;
  font-size: 14px;
  margin-bottom: 16px;
}

.dt {
  font-weight: bold;
}

.dt::after {
  content: '\00a0';
}

.dd {
  margin-bottom: 0;
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organizationID {
  white-space: nowrap;
}
