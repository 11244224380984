.main {
  border-bottom: 1px solid var(--mercury);
}

.common {
  padding: 0 15px;
  margin-bottom: 20px;
}

.counter {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
}

.counter:last-child {
  margin-right: 0;
}

.message {
  color: var(--silver);
  font-size: 14px;
  line-height: 18px;
}

.userName {
  color: var(--shark);
  font-size: 28px;
  letter-spacing: -0.38px;
  line-height: 34px;
  margin-bottom: 15px;
}

.number {
  color: var(--primary-color);
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
}


.list {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .main {
    margin-bottom: 175px;
    border-bottom: none;
  }

  .common {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--mercury);
    padding: 37px 0 18px 0;
  }

  .counter {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 0;
  }

  .userName {
    font-size: 37px;
    letter-spacing: -0.5px;
    line-height: 44px;
    margin-bottom: 0;
  }

  .list {
    flex-direction: row;
  }

  .limitedAccess {
    padding-right: 162px;
    margin-top: 34px;
  }
}
