.filtersCount {
  color: var(--oslo-gray);
}

.resetFilters {
  color: var(--primary-color);
  font-size: 14px;
  line-height: 16px;
}

.saveFilters {
  color: var(--primary-color);
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

.root {
  background-color: var(--athens-gray);
  font-size: 12px;
  line-height: 16px;
  color: var(--oslo-gray);
  display: flex;
  justify-content: space-between;
  padding: 17px 16px;
  box-shadow: 0 5px 8px 0 rgba(131,133,136,0.24);
  position: sticky;
  top: 0;
  z-index: 2;
}

.tooltip :global(.rc-tooltip-arrow) {
  left: 36px;
}

.filtersList {
  margin-top: 14px;
  max-width: 500px;
}

.filtersList div:first-child {
  color: var(--oslo-gray);
}

.filtersList div:last-child {
  color: white;
}
