.checkBoxes {
  max-width: 457px;
  margin: auto;
  padding-left: 80px;
  margin-bottom: 50px;
}

.checkBoxes ul {
  list-style-type: none;
}

.checkBoxes > ul > li {
  margin-bottom: 18px;
}

.subTitle {
  color: #444444;
  font-size: 14px;
  line-height: 36px;
  self-align: start;
}

.selectBox {
  max-width: 500px;
  display: flex;
  flex-direction: column;
}
