:global(.grommet) .main .text {
  font-size: 36px;
  font-weight: bold;
  margin-top: 0;
  text-align: center;
}

.recomendation {
  font-size: 24px;
  margin-bottom: 42px;
}

.header {
  padding-left: 20px;
  padding-top: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 15px;
  text-align: center;
}
