.profileSuggestions {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(calc(100% + 5px));
  background-color: var(--wild-sand);
  outline: 1px solid var(--pacific-blue);
  max-width: 400px;
  z-index: 1;
}

.profileSuggestionsTitle {
  font-size: 16px;
  padding: 10px 10px;
  border-bottom: 1px solid var(--alto);
  display: flex;
}
