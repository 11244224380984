.editFormRow td {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgb(0 0 0 / 12%);
}

.editFormRow td:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(0deg, transparent 0%, rgb(0 0 0 / 12%) 100%);
}

.actions {
  display: flex;
}

.actions > *:not(:last-child) {
  margin-right: 8px;
}

.actions > .button {
  min-height: 34px;
  padding: 0px;
  min-width: 70px;
}
