.horizontalField {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.horizontalForm {
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  align-items: flex-start;
}

.controls {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-shrink: 0;
  justify-content: flex-end;
}

.inputs {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  width: 100%;
}

.button {
  margin-right: 5px;
}

.danger {
  color: var(--salmon);
}

.label {
  color: var(--shark);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 27px;
}

.error {
  min-height: 16px;
  font-size: 11px;
  color: var(--salmon);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.formField {
  padding-top: 13px;
}

.select {
  margin-top: 9px;
}

@media (min-width: 1024px) {
  .horizontalForm {
    flex-direction: row;
    align-items: flex-end;
  }

  .horizontalField {
    margin: 0 10px 0 0;
    width: 20%;
  }

  .inputs {
    flex-direction: row;
    width: auto;
  }
}
