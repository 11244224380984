.column {
  flex: 1;
  height: 100%;
  max-width: 20%;
  min-width: 15%;
  margin: 0px 10px 10px;
  background-color: var(--athens-gray);
}

.droppableBox {
  height: 90%;
  overflow-y: scroll;
  margin: 5px;
}

.availableDroppableBox {
  border: 2px dashed var(--primary-color);
  border-radius: 5px;
  margin: 3px;
  background-color: rgba(0, 160, 207, .1);
}
