.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.highlight {
  position: absolute;
  box-sizing: border-box;
  outline: 9999px solid rgba(0, 0, 0, 0.6);
}

.guide {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  transform: translateY(-100%);
  padding-bottom: 30px;
}

.description {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  color: white;
}

.arrows {
  position: absolute;
  right: 0;
  bottom: 0;
  fill: #fff;
}

.button {
  margin-top: 32px !important;
  background-color: white !important;
  height: 40px;
  width: 128px;
  border-radius: 5px;
  color: black !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 23px !important;
  text-align: center !important;
}

@media (min-width: 1024px) {
  .guide {
    white-space: nowrap;
    transform: translateY(0%);
    padding-bottom: 0px;
    width: auto;
  }

  .arrows {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }

  .guide.left {
    padding-right: 30px;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
  }

  .guide.left .arrows {
    right: 30px;
    bottom: 0;
    transform: scale(1, -1) rotate(240deg);
  }

  .guide.right {
    padding-left: 30px;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
  }

  .guide.right .arrows {
    left: 30px;
    top: 0;
    transform: translateY(-100%) scale(-1, 1) rotate(240deg);
  }

  .guide.top {
    top: 0;
    padding-bottom: 30px;
    transform: translateY(-100%)
  }

  .guide.top .arrows {
    right: 0;
    bottom: 0;
    transform: rotate(-45deg);
  }

  .guide.bottom {
    bottom: 0;
    transform: translateY(100%);
    padding-top: 30px;
  }

  .guide.bottom .arrows {
    left: 0;
    top: 0;
    transform: translateX(-100%) scale(-1, -1);
  }

  .guide.bottom-right {
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    padding-top: 30px;
  }

  .guide.bottom-right .arrows {
    left: 0;
    top: 0;
    transform: translateX(-100%) scale(-1, -1);
  }
}
