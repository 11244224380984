.phoneNumber {
  white-space: nowrap;
}

.phoneNumber::before {
  content: " ";
}

.resendVerificationCodeLink {
  font-weight: bold;
  text-decoration: underline;
}

fieldset {
  border: none;
  margin: 20px 0;
  padding: 0;
  outline: none;
}
