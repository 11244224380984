.container {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 22px;
  align-items: center;
  height: 60px;
  border: 1px solid var(--alto);
  background: #fff;
}

.userInfo {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  padding-right: 10px;
}

.avatar {
  margin-right: 15px;
  flex-shrink: 0;
}

.commonInfo {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: column;
}

.stickyName {
  color: var(--tundora);
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  white-space: nowrap;
  flex-shrink: 0;
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  max-width: 100%;
}

.stickyWork {
  color: var(--mine-shaft);
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  max-width: 100%;
}

.scroll {
  background-color: var(--iron);
  height: 60px;
  width: 60px;
  border-radius: 0;
}

.scrollContent {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.text {
  display: none;
}

.arrow {
  width: 20px;
  height: 21px;
}

@media (min-width: 1024px) {
  .container {
    padding: 0 22px;
  }

  .commonInfo {
    align-items: center;
    flex-direction: row;
  }

  .stickyName {
    font-size: 18px;
    line-height: 24px;
  }

  .stickyWork {
    color: var(--tundora);
    margin-left: 30px;
  }

  .scroll {
    color: var(--oslo-gray);
    font-size: 14px;
    line-height: 18px;
    text-indent: initial;
    width: auto;
    height: auto;
    background-color: transparent;
    display: inline-block;
  }

  .scrollContent {
    display: inline-block;
  }

  .text {
    display: inline;
  }

  .arrow svg {
    width: 7px;
    height: 7px;
  }
}
