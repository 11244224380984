.tabs .close {
  min-width: 24px;
  max-width: 24px;
  margin: auto 10px;
}

.tabs .close {
  min-width: 24px;
  max-width: 24px;
  padding-bottom: 0px;
}

.tabs div[role="tablist"] {
  margin-top: 12px;
}

.wrapper {
  padding: 15px 15px 30px;
  width: 100%;
  overflow: visible;
}

@media (min-width: 1024px) {
  .close {
    display: none !important;
  }

  .wrapper {
    padding: 0px 30px 0px 0px;
    width: 100%;
    overflow: visible;
  }

  .overlay {
    display: block;
    position: relative;
    z-index: 0;
    overflow-y: visible;
    height: 100%;
  }

  .overlay div[role=tablist] {
    display: flex;
    flex-direction: column;
  }

  .overlay div[role=tablist], div[role=tabpanel] {
    height: 100%;
  }
}
