.headline {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--mercury);
  padding-bottom: 20px;
  margin-top: 8px;
  align-items: baseline;
}

.headlineTitle {
  font-size: 37px;
  color: #212529;
  letter-spacing: -0.5px;
  line-height: 44px;
}

.breadcrumbs {
  margin-top: 25px;
}
