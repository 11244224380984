.horizontalField {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.horizontalForm {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  margin: 10px 0 40px 0;
  align-items: flex-start;
}

.controls {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.horizontalForm .button {
  margin-right: 12px;
}

.label {
  color: var(--shark);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 36px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.select {
  height: 40px;
}

@media (min-width: 1024px) {
  .horizontalForm {
    flex-direction: row;
    align-items: flex-end;
  }

  .horizontalField {
    max-width: 230px;
    margin: 0 10px 0 0;
  }

  .horizontalForm .button {
    margin-top: 0;
    margin-left: 32px;
  }

  .controls {
    margin-top: 0;
  }
}
