.requestFields {
  background-color: var(--wild-sand);
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
}

.requestFields:not(:last-child) {
  margin-bottom: 80px;
}

.requestFields > *:not(:last-child) {
  margin-bottom: 30px;
}

.field,
.emailsField {
  min-height: 60px;
}

.removeButton {
  text-decoration: underline;
  margin-right: 20px;
  justify-content: flex-start;
  text-align: right;
}

.removeIconButton {
  display: none;
}


@media (min-width: 1024px) {
  .requestFields {
    flex-direction: row;
    padding: 0 10px 0 25px;
  }

  .requestFields:first-child {
    padding-top: 45px;
  }

  .requestFields:last-child {
    padding-bottom: 45px;
  }

  .requestFields:not(:last-child) {
    margin-bottom: 0;
  }

  .requestFields:last-child > *:not(:last-child) {
    margin-bottom: 0;
  }

  .field,
  .emailsField {
    max-width: 20%;
  }

  .removeButton {
    display: none;
  }

  .removeIconButton {
    display: block;
    padding: 12px;
    height: 60px;
  }
}
