.checkBoxes {
  display: flex;
  flex-wrap: wrap;
}

.checkBoxesColumn {
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  gap: 10px;
}

.standaloneOptions {
  display: flex;
  align-items: space-between;
  margin-bottom: 14px;
}

.option {
  display: flex;
  width: 50%;
}

.subTitle {
  color: #444444;
  font-size: 14px;
  line-height: 36px;
}

.selectBox {
  max-width: 500px;
  display: flex;
  flex-direction: column;
}
