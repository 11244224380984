.mailIcon {
  width: 12px;
  height: 12px;
  margin-left: 8px;
  margin-top: 2px;
  stroke: #838588;
}

.mailIcon > path {
  stroke-width: 3px;
}

.select {
  padding: 0px 8px;
  margin-top: 0px;
}
