.dropzone {
  width: 320px;
  height: 320px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone img {
  pointer-events: none;
}

.dropWrapper {
  border: 1px dashed var(--dusty-gray);
}


.upload {
  border: 2px solid var(--mine-shaft);
  padding: 6px 38px !important;
}

.text {
  user-select: none;
  pointer-events: none;
  font-size: 19px;
}

.cropArea {
  width: 320px;
  height: 320px;
  overflow: hidden;
  border: 1px dashed var(--dusty-gray);
}

.errors {
  color: var(--salmon);
}
