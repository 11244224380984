.content {
  display: none;
  padding: 20px 16px;
}

.content p:first-child {
  margin-top: 0;
}

.rootOpened .content {
  display: block;
}

.header {
  display: none;
}

.mobileHeader {
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
}

.rootOpened .mobileHeader {
  background-color: var(--hint-of-red);
}

.headerText {
  flex-wrap: wrap;
}

.headerIcon {
  flex-shrink: 0;
  padding-left: 38px;
  width: 24px;
  height: 24px;
  flex-basis: 62px;
}

@media (min-width: 1024px) {
  .content {
    display: block;
    padding: 0;
  }

  .header {
    display: block;
  }

  .mobileHeader {
    display: none;
  }
}
