.button {
  width: 18px;
  height: 18px;
  color: #FFFFFF !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 16px !important;
  background-color: var(--iron) !important;
  border-radius: 50%;
  text-align: center !important;
}

.button:hover {
  border: 1px solid var(--gray) !important;
}
