.sampleProjectMissing {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DEE0E3;
  background-color: #F4F5F7;
  color: #666666;
  font-size: 18px;
  min-width: 460px;
  height: 335px;
  margin-right: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.project {
  height: 335px;
  min-width: 460px;
  margin: 0px 24px 24px 0px;
}
