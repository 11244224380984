.root {
  margin-bottom: 30px;
  padding: 12px;
}

.general {
  display: flex;
}

.main {
  flex: 1;
  padding-right: 45px;
  padding-left: 8px;
}

.side {
  flex-basis: 200px;
  flex-shrink: 0;
  padding-top: 12px;
  overflow: hidden;
}

.actions {
  display: flex;
  position: relative;
}

.icon {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  border: none;
}

.icon svg {
  stroke: var(--primary-color);
  width: 24px;
  height: 24px;
}

.menu {
  z-index: 1;
}

@media (min-width: 1024px) {
  .side {
    flex-basis: 350px;
  }

  .root {
    padding: inherit;
  }
}
