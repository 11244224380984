
.controls {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  margin-bottom: 19px;
}

.search {
  width: 100%;
  border: 1px solid var(--mercury);
  display: flex;
  align-items: center;
  padding-right: 18px;
  margin-bottom: 25px;
}

.search input,
.search input:focus {
  border: none;
  padding: 13px 18px;
  width: 100%;
  outline: none;
  box-shadow: none;
  font-weight: 300;
}

.search input::placeholder {
  color: var(--boulder);
  font-size: 14px;
}

.root :global(tbody tr:nth-child(odd)){
  background-color: transparent;
}

.root :global(tbody tr:nth-child(even)){
  background-color: var(--athens-gray);
}

.actions {
  display: flex;
}

.actions > *:not(:first-child) {
  margin-left: 12px;
}

.table {
  overflow: auto;
}

.select {
  padding-right: 18px;
  margin-bottom: 25px;
}

.importUsersAction {
  display: flex;
  align-items: center;
}

:global(#root .grommet) .importUsersAction:hover,
.importUsersAction:hover {
  text-decoration: none;
}

:global(#root .grommet) .importUsersAction,
.importUsersAction {
  font-size: 14px;
  color: var(--primary-color);
}

.importUsersAction > svg {
  stroke: var(--primary-color);
  height: 12px;
  width: 12px;
  margin-left: 6px;
}

@media (min-width: 1024px) {
  .controls {
    flex-direction: row;
  }

  .search {
    max-width: 384px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .select {
    max-width: 250px;
    margin-bottom: 0;
    margin-right: 20px;
  }
}
