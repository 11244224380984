.root {
  display: flex;
  padding-bottom: 21px;
  border-bottom: 1px solid var(--mercury);
  overflow: hidden;
  padding-left: 11px;
  margin-bottom: 16px;
  padding-top: 6px;
}

.header {
  display: flex;
  margin-bottom: 4px;
}

.name {
  font-size: 18px;
  font-weight: 300 !important;
  color: var(--pacific-blue);
  letter-spacing: 0;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
}

.company {
  font-size: 12px;
  color: var(--shark);
  letter-spacing: 0;
  line-height: 19px;
}

.actions {
  flex-shrink: 0;
}

.info {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
}

.general {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.general:hover {
  text-decoration: none;
}

.general:hover .name, .general:hover .company {
  text-decoration: underline;
  text-decoration-color: black;
}

.download {
  padding: 0;
  min-width: auto;
  border: none;
  top: 1px;
  position: relative;
}

.download svg {
  width: 17px;
  height: 17px;
}

.delete {
  padding: 0;
  min-width: auto;
  border: none;
}

.delete svg {
  width: 15px;
  height: 15px;
}

.action:last-child {
  margin-right: 0;
}

.profileLabels > *:not(:first-child) {
  margin-left: 8px;
}
