.details {
  display: flex;
  margin-bottom: 20px;
}

.details > *:not(:last-child) {
  margin-right: 32px;
}

.baseError {
  border: 1px solid var(--orange);
  margin-bottom: 20px;
  padding: 10px 24px;
}

.number {
  color: var(--primary-color);
}

.tableContainer {
  max-height: 550px;
  position: relative;
  overflow-y: auto;
  overflow-x: visible;
  padding: 1px;
}
