.root {
  font-size: 16px !important;
  font-weight: normal !important;
  color: var(--oslo-gray) !important;
  letter-spacing: 0;
  line-height: 20px !important;
  margin: 0px !important;
  padding: 15px !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid var(--gallery) !important;
  border-radius: 0px;
  width: 100%;
}

.root:hover {
  background-color: #fff;
  border-color: var(--pacific-blue);
  border: 1px solid var(--pacific-blue);
}
