.priority {
  display: flex;
  align-items: center;
}

.priorityColor {
  display: inline;
  background-color: green;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}
