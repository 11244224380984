.chart {
  height: 80px;
  display: flex;
  width: 100%;
}

.item {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  position: relative;
}

.text {
  position: absolute;
  overflow: hidden;
  top: 11px;
  left: 15px;
  bottom: 11px;
  right: 15px;
  text-overflow: ellipsis;
}

.text a {
  color: #444444;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
}
