.checkbox {
  margin-bottom: 10px;
}

.checkbox:last-child {
  margin-bottom: 0px;
}

.partition {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.partition :global(.react-select__value-container) {
  min-height: 33px;
}

.partition :global(.react-select__control) {
  font-size: 16px;
}

.title {
  color: var(--shark);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 36px;
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .partition :global(.react-select__control) {
    font-size: 14px;
  }

  .title {
    font-size: 12px;
  }
}
