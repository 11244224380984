.chart {
  margin-bottom: 102px;
}

.counters {
  display: flex;
  margin-bottom: 40px;
}

.counter {
  margin-right: 60px;
}

.counter:last-child {
  margin-right: 0;
}

.number {
  color: #333333;
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
}

.description {
  color: #444444;
  font-size: 24px;
  line-height: 30px;
}
