.modal {
  min-width: 354px;
  max-width: 354px;
  background-color: #F5F5F5;
}

.header {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: var(--tundora);
  padding: 24px 24px 11px 24px;
  margin-bottom: 22px;
}

.selectLabel {
  color: var(--shark);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 10px;
}

.select {
  background-color: #FFF;
}

.select :global(.select__control) {
  min-height: 40px;
}

.content {
  padding: 0px 25px;
  margin-bottom: 23px;
}

.content:last-child {
  margin-bottom: 0px;
}

.actions {
  margin: 32px 0px;
  display: flex;
  justify-content: center;
}

.save {
  padding: 7px 45px 10px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
}

.loader {
  margin: 32px 0px;
}
