.filters {
  display: flex;
  margin-bottom: 20px;
}

.filter {
  flex-grow: 1;
  margin-right: 20px;
}

.filter:first-child {
  width: 160px;
}

.title {
  color: #333;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}

.compare {
  align-self: flex-end;
}

.description {
  color: #333333;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox {
  flex-shrink: 0;
  margin-left: 23px;
}

.label {
  cursor: pointer;
}

.select > div {
  min-height: 40px;
}

.select :global(.filter__single-value) {
  padding-bottom: 1px;
  width: auto;
  max-width: 85%;
}
