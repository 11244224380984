.item {
  margin-right: 15px;
  color: #fff !important;
  text-decoration: underline;
}

.item span {
  white-space: nowrap;
}

.item:visited {
  color: #fff !important;
}

.item:hover {
  color: #fff !important;
}

@media (min-width: 1024px) {
  .item {
    text-decoration: none;
  }
}
