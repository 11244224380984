.root {
  margin-bottom: 40px;
  max-width: 100%;
}

.header {
  color: #444444;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 30px;
  margin-top: 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
