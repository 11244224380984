.item {
  height: 237px;
  width: 225px;
  border: 1px solid #E7E7E7;
  margin: 0px 29px;
}

.input {
  display: none;
}

.input:checked + div {
  border: 2px solid var(--primary-color);
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
}

.icon {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 27px;
  height: 46px;
}

.title {
  height: 30px;
  color: #444444;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 17px;
}

.description {
  font-size: 14px;
  color: #444444;
  line-height: 1.357142;
}
