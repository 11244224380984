.headline {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  max-width: 100%;
  align-items: flex-start;
}

.headlineTitle {
  font-size: 37px;
  color: #212529;
  letter-spacing: -0.5px;
  line-height: 44px;
  overflow: hidden;
}

.breadcrumbs {
  margin-top: 25px;
}

.owner {
  border-bottom: 1px solid var(--mercury);
  color: var(--boulder);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-weight: 600;
}

.owner > span {
  white-space: nowrap;
}

.notOwner {
  color: var(--pacific-blue);
}
