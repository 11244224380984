.filters {
  width: 50px;
  border: 1px solid var(--alto);
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  padding-bottom: 22px;
  border-bottom: 1px solid var(--gallery);
  display: block;
}

.fields {
  display: flex;
}

.sortFields {
  display: none;
}

.searchField {
  flex-grow: 1;
  flex-basis: 570px;
  flex-shrink: 1;
}

.searchField  :global(.react-select__option) {
  border: 1px solid var(--alto);
  border-bottom: 1px solid transparent;
  background-color: var(--wild-sand);
}

.searchField  :global(.react-select__option):first-child {
  border-top: 1px solid transparent;
}

.searchField  :global(.react-select__option.react-select__option--is-focused) {
  background-color: white;
  border: 1px solid var(--pacific-blue);
}

.sortField {
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 200px;
  display: none;
}

.boardexPowered {
  display: none;
}

@media (min-width: 1024px) {
  .filters {
    display: none !important;
  }

  .search {
    display: flex;
    justify-content: space-between;
  }

  .fields {
    display: block;
  }

  .sortFields {
    display: flex;
    justify-content: flex-end;
  }

  .searchField {
    max-width: 570px;
  }

  .sortField {
    flex-grow: 0;
    width: 200px;
    display: block;
  }

  .sortField {
    margin-left: 12px;
  }

  .sortField:first-child {
    margin-left: 0px;
  }

  .boardexPowered {
    display: block;
  }
}
