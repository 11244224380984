.infoAlert {
  border: 1px solid var(--salmon);
  color: var(--salmon);
  padding: 16px 19px;
  margin-bottom: 20px;
}

.infoAlert > * {
  color: var(--salmon);
  margin-top: 0;
  margin-bottom: 10px;
}

.infoAlert > *:last-child {
  margin-bottom: 0;
}

.infoAlert h4 {
  display:flex;
  align-items: center;
  justify-content: center;
  color: var(--salmon);
  font-weight: bold;
  margin-top: 0px;
}

.infoAlert h4 svg {
  stroke: var(--salmon);
  margin-right: 6px;
}
