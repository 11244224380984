.transcriptRow {
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  max-width: 600px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.selectedTranscript {
  background-color: #c3c3c33b;
}

.checkedTranscript {
  background-color: #dbeef170;
}

.transcriptRow:last-child {
  margin-bottom: 0;
}

.document {
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
}

.info {
  margin-left: 20px;
  width: 300px;
}

.content {
  font-size: 14px;
}

.content a {
  color: var(--mine-shaft) !important;
  font-weight: 300 !important;
}

.header {
  color: #04A0C5;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  margin-bottom: 5px;
}

.date {
  font-size: 14px;
}

.checkboxWrapper {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox {
  width: 24px;
  height: 24px;
  display: flex;
}

.disabled {
  opacity: 0.7;
}
