.header {
  font-weight: 400;
  margin-top: 16px;
}

.details {
  font-weight: 400;
}

.information {
  display: flex;
  margin: 16px 0 32px;
  justify-content: center;
  align-items: center;
}

.fileColumnsInformation {
  margin-top: 16px;
}

.example {
  padding: 24px;
  background-color: var(--hint-of-red);
}

.exampleTitle {
  margin-bottom: 16px;
  text-align: center;
}
