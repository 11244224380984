.form > *:not(:last-child) {
  margin-bottom: 12px;
}

.formSectionTitle {
  margin-top: 36px;
}

.formActions > * {
  margin-bottom: 12px;
}
