.root {
  margin-bottom: 30px;
}

.pageOverlay {
  display: flex;
  align-items: flex-start;
}

.pageOverlay:before {
  content: "";
  flex: auto;
  display: block;
}

.hidden {
  display: none;
}

.main {
  display: flex;
}

.sidebarLegend {
  flex-shrink: 1;
  padding-right: 22px;
  border-right: 1px solid #d9d9d9;
  margin: 24px 0;
}

.graph {
  flex-grow: 1;
}

.callToAction {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: white;
  text-align: center;
  padding: 3px 10px 5px 10px;
  border-radius: 3px;
  background: var(--orange);
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 24px 0;
}
