.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid var(--mercury);
}

.organization {
  display: flex;
  justify-content: space-between;
}

.organization:hover {
  text-decoration: none !important;
}

.avatar {
  margin: 35px 22px 31px 2px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  color: var(--pacific-blue);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}

.industries {
  color: var(--shark);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.actions {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
}

.removeButton {
  padding: 8px 22px;
  margin-bottom: 16px !important;
}

.undoLink {
  color: var(--primary-color);
  margin-left: 4px;
}

@media (min-width: 1024px) {
  .root {
    flex-direction: row;
  }

  .removeButton {
    margin-bottom: 0px !important;
  }
}
