.validatingStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.validatingStatus > *:not(:last-child) {
  margin-bottom: 12px;
}

.loader {
  max-width: 50px;
  max-height: 50px;
}
