.table {
  margin: 6px 12px 6px 24px;
  font-size: 14px;
}

a.sendResetPasswordEmail,
a.sendResetPasswordEmail:visited {
  font-size: 14px;
  font-weight: 300;
  margin-left: 10px;
  color: var(--primary-color);
  box-shadow: inset 0 -.075em #fff,inset 0 -.1em currentColor,inset 0 -.15em #fff,inset 0 -.175em #fff;
  display: flex;
  align-self: flex-start;
}

a.sendResetPasswordEmail:hover {
  color: var(--primary-color);
  text-decoration: none;
  box-shadow: none;
}

.avatarRow {
  display: flex;
  margin-top: 10px;
}

.avatarRow > a {
  color: var(--primary-color);
  margin-left: 20px;
  font-weight: 600;
}

.passwordValidator {
  margin-bottom: 20px;
}

.passwordStrengthRating {
  margin-bottom: 16px;
}
