.small {
  width: 370px;
}

.medium {
  width: 470px;
}

.large {
  width: 100%;
}

.modal {
  border-radius: 0;
  background-color: #F5F5F5;
}

.close {
  opacity: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0px;
  margin-top: 4px;
  margin-right: 4px;
}

.close svg {
  width: 24px;
  height: 24px;
  fill: var(--dove-gray);
  stroke: var(--dove-gray);
}

.close svg path {
  stroke-width: 1;
}

.close:hover {
  fill: var(--dove-gray);
  stroke: var(--dove-gray);
}

.overflow {
  overflow: initial;
}


.modal a,
.modal a:visited  {
  color: var(--primary-color);
  box-shadow: inset 0 -.075em #fff,inset 0 -.1em currentColor,inset 0 -.15em #fff,inset 0 -.175em #fff;
}

.modal a:hover {
  color: var(--primary-color);
  text-decoration: none;
  box-shadow: none;
}

.collapseContentMargins .container u {
  text-decoration: underline;
}

.collapseContentMargins .container a {
  color: var(--primary-color)
}

.headerBox {
  display: flex;
  align-items: baseline;
}

.inlineSubheader {
  opacity: 0.5;
  color: #333333;
  font-size: 24px;
  line-height: 30px;
  flex-shrink: 0;
  margin-left: 12px;
  margin-right: 24px;
}

.containerPadding {
  padding: 30px 100px;
  background-color: #F5F5F5;
}
