.tabList {
  margin: 0px;
  padding: 0;
  display: flex;
}

.tabList.default {
  border-bottom: 1px solid var(--mercury);
  width: 100%;
}

.tabList.buttonsGroup {
  width: 100%;
}

.tabList.buttonsGroup > *:not(:last-child) {
  margin-right: 8px;
}
