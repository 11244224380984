.userpic {
  display: block;
}

.userpic img {
  object-fit: cover;
  border-radius: 50%;
}

.userpic,
.userpic svg {
  border-radius: 50%;
}
