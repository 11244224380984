:root {
  --primary-color: #00a0ce;
  --mine-shaft: #333;
  --iron: #dee0e3;
  --athens-gray: #f4f5f7;
  --tundora: #444;
  --wild-sand: #f6f6f6;
  --alto: #ddd;
  --persimmon: #ff6b4e;
  --orange: #fa652c;
  --black: #000;
  --dusty-gray: #979797;
  --dove-gray: #666;
  --mantis: #5dca4e;
  --oslo-gray: #939495;
  --malachite: #00ce34;
  --burnt-sienna: #e96e3e;
  --salmon: #ff856b;
  --gallery: #f0f0f0;
  --hint-of-red: #f9f6f6;
  --mercury: #e7e7e7;
  --boulder: #777;
  --pacific-blue: #04a0c5;
  --outer-space: #354045;
  --silver-chalice: #aeaeae;
  --shark: #212529;
  --silver: #c2c2c2;
  --alabaster: #fafafa;
  --gray: #818181;
  --yellow: #ffff00;
  --lemon: #f8e71c;
  --aquamarine-blue: #7ECFE2;
  --biloba-flower: #DBA9EC;
  --deep-cerulean: #0084AB;
  --japanese-laurel: #0E8800;
  --sundance: #C6A957;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-overflow-scrolling: touch;
}
