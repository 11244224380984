.root {
  margin-bottom: 12px;
}

.control {
  display: flex;
  margin-bottom: 12px;
}

.select {
  flex-grow: 1;
  padding-right: 12px;
}

.button {
  min-width: 80px;
}
