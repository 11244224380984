.root {
  border: 1px solid var(--iron);
  position: relative;
  margin-bottom: 12px;
}

.noBottom {
  margin-bottom: 0;
}

.error {
  border: 1px solid var(--salmon);
}

.placeholder {
  font-size: 11px;
  color: var(--oslo-gray);
}

.disabled {
  background-color: var(--athens-gray);
}

.info {
  display: flex;
  padding: 8px 12px 0 12px;
  justify-content: space-between;
  align-items: baseline;
}

.errorText {
  color: var(--salmon);
  line-height: 24px;
  font-size: 16px;
  padding-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
