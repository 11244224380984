.root {
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.root:hover {
  border: solid 1px var(--tundora);
}

.root:hover .panel {
  display: flex;
}

.panel {
  background: var(--primary-color);
  color: white;
  position: absolute;
  top: -1px;
  width: 40px;
  left: 100%;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  display: none;
}

.action {
  margin-bottom: 10px;
}

.action:last-child {
  margin-bottom: 0;
}

.title {
  margin-bottom: 4px;
}

.title a {
  color: var(--mine-shaft) !important;
  font-weight: 300 !important;
}

.text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--tundora);
}

.text p {
  margin: 0 0 10px;
}

.text p:last-child {
  margin: 0;
}

.text ul {
  padding: 13px 0 0;
  margin: 0;
  list-style: none;
}

.text ul li {
  margin: 0 0 10px;
  position: relative;
  padding-left: 30px;
}

.text ul li:before {
  content: '•';
  position: absolute;
  left: 10px;
  top: 0;
}

.text ul li ul li:before {
  content: '‑';
}

.jobTitle {
  font-size: 16px;
  margin-bottom: 9px;
}

.jobFunctions {
  color: var(--dusty-gray);
  font-size: 14px;
  margin-bottom: 7px;
}

.dates {
  font-size: 14px;
}

.labels {
  display: flex;
}

.currentWork {
  font-size: 10px;
  letter-spacing: 1.14px;
  text-transform: uppercase;
  background-color: var(--mantis);
  color: white;
  display: inline-block;
  padding: 0px 4px;
  line-height: 16px;
}

.primaryCurrentWork {
  font-size: 10px;
  letter-spacing: 1.14px;
  text-transform: uppercase;
  background-color: var(--salmon);
  color: white;
  display: inline-block;
  padding: 0px 4px;
  margin-left: 5px;
  line-height: 16px;
}
