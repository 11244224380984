.root {
  display: flex;
  padding: 40px 50px 0 50px;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: row;
}

.title {
  color: #444444;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 8px;
}

.list {
  line-height: 43px;
  font-size: 16px;
  margin-bottom: 20px;
}

.info {
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 30px;
}

.description {
  line-height: 22px;
  margin-bottom: 8px;
}

.dropContainer {
  display: flex;
  margin-top: 100px;
}

.main {
  flex-grow: 1;
}

.side {
  flex-basis: 376px;
}

.example {
  margin-bottom: 96px;
}

.checkIcon {
  margin-left: 20px;
}

.csvTitle {
  display: flex;
}

.correctIcon {
  color: #00CE34;
}

.wrongIcon {
  color: #FA652C;
}

.infoAlert {
  display:flex;
  border: 1px solid var(--salmon);
  color: var(--salmon);
  flex-grow: 1;
  margin-bottom: 24px;
  margin-right: 12px;
  padding: 16px 19px;
}

.infoAlert h4 {
  display:flex;
  align-items: center;
  justify-content: center;
  color: var(--salmon);
  margin: 0px;
}

.infoAlert h4 svg {
  stroke: var(--salmon);
  margin-right: 6px;
}
