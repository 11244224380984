.button {
  margin-right: 20px !important;
}

.button:last-child {
  margin-right: 0px;
}

@media (min-width: 1024px) {
  .modal {
    min-width: 480px;
  }
}
