.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.actions > div {
  width: 376px !important;
}

.stateTags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.clearButton {
  display: flex !important;
  align-items: center;
  font-size: 14px !important;
  color: var(--primary-color) !important;
  font-weight: 600 !important;
  margin-right: 30px !important;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.noFilterText {
  color: #7A7A7A;
  font-size: 12px;
  font-style: italic;
  line-height: 15px;
  margin-bottom: 15px;
}
