.mobileSort {
  display: block;
}

.mobileSort :global(.search-sort__control) {
  font-size: 16px;
}

.partition {
  margin-bottom: 20px;
}

.partition :global(.react-select__value-container) {
  min-height: 33px;
}

.partition :global(.react-select__control) {
  font-size: 16px;
}

.title {
  color: var(--shark);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 36px;
  text-transform: uppercase;
}

.groupHeading {
  color: var(--dusty-gray);
  font-weight: 600;
  padding: 10px 10px 5px;
}

.groupOption {
  display: flex;
  padding: 5px 10px 5px 24px;
}

@media (min-width: 1024px) {
  .mobileSort {
    display: none;
  }

  .partition :global(.react-select__control) {
    font-size: 14px;
  }

  .title {
    font-size: 12px;
  }
}
