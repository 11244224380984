.title {
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 49%;
  background-color: #fafafb;
}

.column:nth-child(1):nth-last-child(1) {
  text-align: center;
  padding: 15px;
  flex-grow: 1;
}

.column:nth-child(2):nth-last-child(1) {
  text-align: right;
}
