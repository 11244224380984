.button {
  border: 1px solid #04A0C5;
  border-radius: 5px;
  min-width: auto;
  font-size: 14px;
  color: #333333;
  height: 30px;
  max-width: 100px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 2px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fff;
}

.disabled {
  opacity: 0.3;
  cursor: default;
}
