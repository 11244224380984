.barChart {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.info {
  display: flex;
  padding-left: 12px;
  padding-bottom: 12px;
  text-align: right;
  color: #444444;
}

.name a {
  color: #444444;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-right: 8px;
}

.counter {
  font-size: 16px;
  line-height: 24px;
}
