.grommet {
  font-family: 'Source Sans Pro', sans-serif;
}

ol {
  margin-left: 0;
  margin-top: 18px;
  list-style-type: none;
  counter-reset: item;
}

ol > li {
  counter-increment: item;
}

ol li:before {
  content: counters(item, ".") ". ";
}

li + ol li:before {
  content: counters(item, ".") " ";
}

li + ol {
  margin-left: 18px;
}

.overflowHidden {
  overflow: hidden;
}
