.root {
  display: flex;
  flex-wrap: wrap;
}

.root > * {
  margin-bottom: 16px;
}

.root > *:not(:last-child) {
  margin-right: 16px;
}

.loader {
  margin: 4px 0;
  max-height: 16px;
  max-width: 16px;
}
