.header {
  font-size: 18px;
  color: #444;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 22px;
}

.list {
  padding-left: 27px;
}

.title {
  font-size: 14px;
  color: #444;
  letter-spacing: -0.22px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  white-space: nowrap;

}

.value {
  font-size: 14px;
  color: #444;
  text-align: right;
  line-height: 24px;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 10px;
  align-items: center;
  margin-bottom: 9px;
}
