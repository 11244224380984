.container {
  display: flex;
}

.item.active a {
  opacity: 1;
  color: #09A0C6;
}

.item {
  margin: 0 1px;
  height: 22px;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.item:hover {
  background-color: #EBEBEB;
}

.item:hover a {
  text-decoration: none;
  color: #777777;
}

.item.active a:hover {
  color: #09A0C6;
}

.item a {
  font-size: 15px;
  font-weight: 600;
  color: #777777;
  display: block;
  width: 100%;
  text-align: center;
  padding: 0 9px;
}

.arrow polyline {
  stroke-width: 1;
  stroke: #777777;
}

.arrow span {
  height: auto;
  padding: 0;
  transform: translateY(8px) scale(0.8);
}

.arrow.arrowLeft span {
  margin-right: 10px;
}

.arrow.arrowRight span {
  margin-left: 10px;
}
