.options {
  display: none;
}

.download {
  color: var(--pacific-blue);
  margin-right: 12px;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.download:hover {
  color: var(--pacific-blue);
}

@media (min-width: 1024px) {
  .options {
    padding-bottom: 11px;
    border-bottom: 1px solid var(--gallery);
    padding-top: 10px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
  }
}
