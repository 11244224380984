.dragItem {
  display: flex;
  border: 1px solid var(--primary-color);
  padding: 9px 16px 8px 10px;
  align-items: center;
  cursor: pointer;
}

.draggableCard {
  background: #fff;
  user-select: none;
}

.list ul {
  margin: 0;
  padding: 0;
}

.text {
  flex-grow: 1;
  padding-right: 12px;
  overflow: hidden;
}

.text a {
  color: var(--mine-shaft) !important;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.icon {
  height: 33px;
  padding-right: 20px;
}

.action {
  height: 24px;
}

.options {
  display: flex;
}

.button {
  min-width: 80px;
}

.field {
  flex-grow: 1;
  padding-right: 12px;
}
