.root {
  color: var(--shark);
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.h1 {
  font-size: 38px;
  font-weight: 300;
}

.h2 {
  font-size: 24px;
  font-weight: 700;
}

.h3 {
  font-size: 18px;
  font-weight: 600;
}

.h4 {
  font-size: 14px;
  font-weight: 600;
}

.body1 {
  font-size: 14px;
  font-weight: 300;
}

.body2 {
  font-size: 12px;
  font-weight: 300;
}

.mercuryColor {
  color: var(--mercury);
}

.orangeColor {
  color: var(--orange);
}

.primaryColor {
  color: var(--primary-color);
}

.silverColor {
  color: var(--silver);
}

.salmonColor {
  color: var(--salmon);
}

.boulderColor {
  color: var(--boulder);
}

.mineShaftColor {
  color: var(--mine-shaft);
}

.mantisColor {
  color: var(--mantis);
}

.tundoraColor {
  color: var(--tundora);
}

.sundanceColor {
  color: var(--sundance);
}
