.header {
  color: var(--boulder);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.17px;
  line-height: 24px;
  text-transform: uppercase;
}

.action {
  flex-shrink: 0;
}

.collapse > h3 {
  display: none;
}

.collapse div[role='presentation'] {
  border-top: 1px solid var(--mercury);
}

.actions button {
  margin-right: 12px;
}

.actions button:last-child {
  margin-right: 0;
}

.changePhoneNumber,
.changePhoneNumber:visited {
  font-size: 12px;
  margin-left: 12px;
  line-height: 15px;
  color: var(--primary-color);
  display: flex;
  align-self: flex-start;
  text-decoration: none;
}

.changePhoneNumber:hover {
  color: var(--primary-color);
  text-decoration: underline;
  box-shadow: none;
}

.passwordValidator {
  margin-bottom: 20px;
}

.passwordStrengthRating {
  margin-bottom: 16px;
}

@media (min-width: 1024px) {
  .form {
    padding-right: 130px;
  }

  .form .header {
    margin-bottom: 15px;
  }

  .form {
    display: flex;
    flex-direction: row;
  }

  .header {
    display: block;
  }

  .collapse {
    flex: 1;
    margin-right: 32px;
  }

  .collapse:last-child .actions {
    display: none;
  }
}
