.position {
  color: var(--shark);
  font-size: 15px;
  letter-spacing: 0.47px;
  line-height: 18px;
  margin-bottom: 4px;
}

.company {
  color: var(--shark);
  font-size: 15px;
  line-height: 18px;
}

.work:last-child {
  margin-bottom: 0px;
}

.work {
  margin-bottom: 20px;
}

.workMasked {
  opacity: .3;
}
