.left {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  flex-shrink: 0;
  border: 1px solid transparent;
}

.avatar {
  max-width: 97px;
  max-height: 97px;
  width: auto;
}

.avatar img {
  width: 97px;
  height: 97px;
}

.avatar svg {
  max-width: 97px;
  max-height: 97px;
  height: auto;
  width: auto;
}

.center {
  text-align: center;
  padding: 0 15px;
}

.center h1 {
  font-size: 24px;
  margin-top: 0;
  font-weight: 300;
}

.right {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 15px;
  margin-bottom: 31px;
}

.rightTop {
  display: flex;
  flex-direction: column;
}

.date {
  font-size: 12px;
  line-height: 19px;
  color: var(--boulder);
}

.editorialDate {
  font-size: 12px;
  line-height: 19px;
  color: var(--orange);
  margin-top: 3px;
}

.controls {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
}

.link {
  color: var(--pacific-blue);
  font-size: 18px;
  margin-bottom: 17px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  line-height: 24px;
}

.link svg {
  margin-left: 10px;
  width: 17px;
  height: 17px;
}

.highlightsToggle {
  display: flex;
}

@media (min-width: 1024px) {
  .common {
    display: flex;
    margin-top: 37px;
    padding-bottom: 24px;
  }

  .left {
    width: 292px;
    margin-right: 24px;
    margin-bottom: 0;
    align-items: flex-start;
    min-height: 244px;
  }

  .avatar {
    max-width: 244px;
    max-height: 244px;
    width: auto;
  }

  .avatar img {
    width: 244px;
    height: 244px;
  }

  .avatar svg {
    max-width: 244px;
    max-height: 244px;
    width: auto;
  }

  .center {
    flex-grow: 1;
    padding: 0;
    padding-right: 20px;
    text-align: left;
  }

  .center h1 {
    font-size: 37px;
  }

  .controls {
    align-items: flex-end;
  }

  .right {
    padding: 0;
    margin-bottom: 0;
    flex-shrink: 0;
  }

  .rightTop {
    align-items: flex-end;
  }
}
