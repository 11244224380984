.root {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px
}

.header {
  margin-bottom: 4px;
}

.description {
  margin-bottom: 8px;
  list-style-type: none;
  margin-left: 0px;
}

.description > li {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  margin-bottom: 2px;
}

.description > li:before {
  content: '•';
  margin-right: 8px;
  font-size: 14px;
}

.link {
  color: var(--pacific-blue);
}

.link:hover {
  color: var(--pacific-blue);
  text-decoration: none;
}
