.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.table th {
  font-size: 12px;
  color: #333333;
  letter-spacing: 1.71px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: left;
  border-bottom: 2px solid #DEE0E3;
}

.table tbody tr:nth-child(odd) {
  background-color: #F4F5F7;
}

.table tbody :global(tr.fieldEditing):nth-child(even) + tr {
  background-color: #fff;
}

.table tbody td {
  min-height: 47px;
  padding: 10px 10px 10px 0;
  font-size: 14px;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  padding: 26px 0 21px 0;
}

