.container {
  flex-grow: 1;
  width: 100%;
}

.tagCloud {
 flex: 1;
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
 height: 100%;
 width: 100%;
 padding: 20px 0;
 align-items: center;
 justify-content: center;
}

.tagCloud > div {
 transition: 1.4s;
}

.tag {
  cursor: pointer;
  padding: 5px 10px;
  line-height: 1em;
  font-family: sans-serif;
}
