.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px 0 0;
}

.step {
  margin-bottom: 7px;
  font-weight: 400;
}

.description {
  font-weight: 400;
}
