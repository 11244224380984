.compareBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 1000px;
}

.currentValueBox, .previousValueBox {
  flex: 1;
  border: 1px solid;
  margin: 5px;
  padding: 5px 20px;
  min-height: 200px;
}

.currentValueBox {
  border-color: var(--malachite);
}

.previousValueBox {
  border-color: var(--iron);
}
