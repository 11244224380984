.root {
  border: 4px solid;
  padding: 24px 15px 24px 24px;
  font-size: 14px;
  overflow: hidden;
  flex-wrap: wrap;
}

.published {
  border-color:  var(--malachite);
}

.unpublished {
  border-color: var(--lemon);
}

.root > .createRequestLink,
.root > .createRequestLink:visited {
  color: var(--primary-color);
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.root > .createRequestLink:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.root > .createRequestLink svg {
  fill: var(--primary-color);
  stroke: var(--primary-color);
}

.text {
  padding-right: 12px;
}

.createRequestLink {
  margin-top: 12px;
}
