.root {
  width: 100%;
  display: flex;
}

.filtersSide {
  width: 30%;
  overflow: hidden;
}

.contentSide {
  width: 70%;
}

.headerSide {
  border-bottom: 1px solid;
  border-color:  var(--silver);
  padding-bottom: 11px;
  height: 36px;
}

.headerAllChoose {
  display: flex;
  justify-content: flex-end;
}

.usersAllSelectorBox {
  display: flex;
  padding-left: 24px;
  width: 100%;
  max-width: 560px;
}

.usersAllSelector {
  position: absolute;
}

.usersAllSelectorLabel {
  margin-left: 30px;
}

.usersBox {
  width: 100%;
}

.users {
  float: right;
  width: 100%;
  max-width: 560px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}
