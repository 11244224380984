.actionsMenuContainer {
  position: relative;
  display: flex;
}

.actionsMenu {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid var(--gallery);
  background-color: var(--wild-sand);
  min-width: 170px;
  transform: translateY(100%);
  z-index: 1;
}
