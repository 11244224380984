.root :global(.recharts-layer.recharts-cartesian-axis-tick) {
  display: none;
}

.root :global(.recharts-layer.recharts-cartesian-axis-tick:first-child),
.root :global(.recharts-layer.recharts-cartesian-axis-tick:last-child) {
  display: block;
}

.root :global(.recharts-cartesian-grid-horizontal line),
.root :global(.recharts-cartesian-grid-vertical line) {
  stroke: #f3f3f3;
}

.root :global(.recharts-cartesian-grid) {
  outline: 1px solid #e0e0e0;
}
