.row td {
  vertical-align: top;
}

.text {
  display: table-cell;
  vertical-align: middle;
  min-height: 32px;
  height: 32px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
