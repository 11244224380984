.header {
  border-bottom: 1px solid;
  border-color:  var(--silver);
  padding-bottom: 11px;
  height: 36px;
}

.filterFields {
  padding-right: 20px;
  border-right: 1px solid;
  border-color: var(--silver);
  height: 100%;
  padding-top: 16px;
}

.filterField:not(:last-child) {
  margin-bottom: 32px;
}
